import React, {useEffect, useState} from "react";
import { formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import DataTable from "../../common/data-table/data-table";
function AddDependant(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsGettingSchedules, setIsGettingSchedules] = useState(true)
    const [patient, setPatient] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [requestID, setRequestID] = useState(formatDateAndTime(Date.now(), 'day') + generate_token(8))
    const header = [
        "S/N",
        "Patient ID",
        "Name",
        "Gender",
        "Phone Number",
        "Email Address",
        "Action",
    ];


    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        search: "",
        walletAmount: props.walletAmount,
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)
    const resetFormData = () => {
        setFormData(Initial)
    }

    const showTable = () => {
        try {
            return patient.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.firstName} {item.middleName} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>
                            <a
                                href="#"
                                className={"btn btn-info btn-sm "}
                                onClick={() =>
                                    showConfirm(
                                        "Warning",
                                        `Are you sure you want to add this patient as dependant?`,
                                        "warning"
                                    ).then(async (confirm) => {
                                        if (confirm) {
                                            await add_dependant(item.patientSerial);
                                        }
                                    })
                                }
                            >
                                Add
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onSearchPatient = async (e) => {
        setIsGettingSchedules(true)
        const val = e.target.value;
        setFormData({
            ...formData,
            search: val,
        })
        await axios.get(`${serverLink}patient/dependant/${val}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatient(result.data.filter(e=>e.patientSerial !== formData.patientSerial));
                }else {
                    setPatient([])
                }
                setIsGettingSchedules(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const add_dependant = async (id) => {
        const requestData = {
            patientSerial: id,
            dependentOn: formData.patientSerial,
            submittedBy: formData.submittedBy,
        }
        toast.info("please wait...");
        await axios.post(`${serverLink}patient/dependant/add`, requestData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Patient Added Successfully");
                props.setRebuildDependant(generate_token(5));
                document.getElementById("closeDependant").click();
                setPatient([])
                resetFormData();
            }else if (result.data.message === "exist") {
                toast.error("The selected patient is already added as dependant");
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding dependant. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Search Patient</label>
                                <input
                                    name="search"
                                    className="form-control"
                                    id="search"
                                    value={formData.search}
                                    onChange={onSearchPatient}
                                    placeholder="Search patient by patientID, name, email or phone number"
                                />
                            </div>

                            {
                                IsGettingSchedules ? <></> :
                                    <div className="mb-3 table-responsive">
                                        <DataTable tableID="patientList" header={header} body={showTable()}
                                                   title="patient List"/>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(AddDependant);