import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {formatAMPM, formatDateAndTime, generate_token, getCurrentTime, serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {Link} from "react-router-dom";
import { setGeneralDetails } from "../../../action/actions";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import { BsPersonCheck} from "react-icons/bs";

function PatientCheckIn(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);

    const header = [
        "S/N",
        "Patient Name",
        "Patient ID",
        "Doctor Name",
        "Doctor ID",
        "Department",
        "Appointment Date",
        "Appointment Time",
        "Checked In"
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        await axios
            .get(`${serverLink}patient/appointment/schedule/today`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const handleCheckIn = async (appointment) => {
        const sendData = {
            appointmentID: appointment.appointmentID,
            checkInTime: getCurrentTime(),
            patientSerial: appointment.patientSerial,
            patientName: appointment.patientName,
            appointmentStatus: "Checked In",
            submittedBy: `${props.loginData[0]?.userID}`,
        }
        toast.info("please wait...");
        await axios.post(`${serverLink}patient/appointment/check-in-patient`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Patient Checked In Successfully");
                getData();
            } else {
                toast.error("Something went wrong. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientName}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.doctorName}</td>
                        <td>{item.doctorID}</td>
                        <td>{item.departmentName}</td>
                        <td>{formatDateAndTime(item.appointmentDate, 'date')}</td>
                        <td>{item.appointmentTime}</td>
                        <td className="d-flex">
                            <a href="#" className={"btn btn-info btn-sm "}
                               onClick={() => {
                                   showConfirm("Warning", `Are you sure you want to check-in this patient?`, "warning")
                                       .then(async (confirm) => {
                                           if (confirm) {
                                               handleCheckIn(item)
                                           }
                                       })
                               }}>
                              <BsPersonCheck size={20} />  Checked In
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return IsLoading ? (
        <Loader/>
    ) : (
        <>
            <PageTitle title={["Home", "Check In Patient"]}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Check In Patient</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">
                                    </div>
                                </div>
                                <DataTable
                                    tableID="Patient"
                                    header={header}
                                    body={showTable()}
                                    title="Check In Patient Report"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientCheckIn);
