import { serverLink } from "../url";
import axios from "axios";
const CryptoJS = require("crypto-js");
export const projectName = "Enyimba Hospital";
export const DASHBOARD = "https://dashboard.bazeuniversity.edu.ng";
export const projectCode = "ENYIMBA_HOSPITAL";
export const projectURL = "https://enyimbahospital.com";
export const projectPatientURL = "https://patient.enyimbahospital.com";
export const projectPhone = "+2348063017470";
export const projectEmail = "info@enyimbahospital.com";
export const projectAddress = "Wuse 2 Abuja Nigeria.";

export const simpleFileUploadAPIKey = "b46bd7bf5e235f7a2d0d7b7602ecce10";
export const simpleFileUploadFolderName = "Enyimba";

export const formatDateAndTime = (date, option) => {
    if (date !== null) {
      const user_date = new Date(date);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthNamesShort = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day =
        user_date.getDate() < 10
          ? "0" + user_date.getDate()
          : user_date.getDate();
      const hour =
        user_date.getHours() < 10
          ? "0" + user_date.getHours()
          : user_date.getHours();
      const min =
        user_date.getMinutes() < 10
          ? "0" + user_date.getMinutes()
          : user_date.getMinutes();
      const sec =
        user_date.getSeconds() < 10
          ? "0" + user_date.getSeconds()
          : user_date.getSeconds();
  
      let date_string = "";
      if (option === "date_and_time")
        date_string = `${day}-${
          monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
      else if (option === "date")
        date_string = `${day}-${
          monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
      else if (option === "day") date_string = day;
      else if (option === "full_month")
        date_string = monthNames[user_date.getMonth()];
      else if (option === "short_month")
        date_string = monthNamesShort[user_date.getMonth()];
      else if (option === "year_only") date_string = user_date.getFullYear();
  
      return date_string;
    } else {
      return "--";
    }
  };
  
  export const  formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
  }
  
  let monthNames = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];
  let dayOfWeekNames = [
    "Sunday", "Monday", "Tuesday",
    "Wednesday", "Thursday", "Friday", "Saturday"
  ];
  export const format_date = (date, formatStr) => {
    if (!formatStr) {
      formatStr = 'dd/mm/yyyy';
    }
    let day = date.getDate(),
        month = date.getMonth(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        miliseconds = date.getMilliseconds(),
        hh = twoDigitPad(hour),
        mm = twoDigitPad(minute),
        ss = twoDigitPad(second),
        EEEE = dayOfWeekNames[date.getDay()],
        EEE = EEEE.substr(0, 3),
        dd = twoDigitPad(day),
        M = month + 1,
        MM = twoDigitPad(M),
        MMMM = monthNames[month],
        MMM = MMMM.substr(0, 3),
        yyyy = year + "",
        yy = yyyy.substr(2, 2)
    ;
    return formatStr
        .replace('hh', hh).replace('h', hour)
        .replace('mm', mm).replace('m', minute)
        .replace('ss', ss).replace('s', second)
        .replace('S', miliseconds)
        .replace('dd', dd).replace('d', day)
        .replace('MMMM', MMMM).replace('MMM', MMM).replace('MM', MM).replace('M', M)
        .replace('EEEE', EEEE).replace('EEE', EEE)
        .replace('yyyy', yyyy)
        .replace('yy', yy)
        ;
  }
  function twoDigitPad(num) {
    return num < 10 ? "0" + num : num;
  }
  // console.log(formatDate(new Date()));
  // console.log(formatDate(new Date(), 'EEEE, MMMM d, yyyy hh:mm:ss:S'));
  // console.log(formatDate(new Date(), 'EEE, MMM d, yyyy hh:mm'));
  // console.log(formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss:S'));
  // console.log(formatDate(new Date(), 'yy-MM-dd hh:mm'));
  
  export const currencyConverter = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN',
    });
    return formatter.format(amount)
  }
  
  export function encryptData(string) {
    let secret_key = projectCode;
    let secret_iv = projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);
  
    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);
  
    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });
  
    let result = decrypted.toString();
    return btoa(result);
  }
  
  export function decryptData(string) {
    let secret_key = projectCode;
    let secret_iv =  projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);
  
    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);
  
    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });
  
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  
  
  export const mailerConfig = {
    transport: {
      host: 'smtp.adammusa89@gmail.com',
      secure: true,
      auth: { user: 'adammusa89@gmail.com', pass: '123456' },
    },
    defaults: {
      from: { name: 'Hospital System', address: 'adammusa89@gmail.com' },
    },
  }
  
  
  export const sendEmail = (email, subject, title, name, body, signature) => {
    const sendEmail = {
    //   logo: projectLogo,
      from: projectEmail,
      to: email,
      subject: subject,
      title: title,
      name: name,
      body: body,
      signature: signature,
    };
    axios.post(`${serverLink}send_email/send`, sendEmail).then((r) => {
      console.log("email sent");
    });
  
    return "sent";
  };


