import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatDateAndTime,
  moneyFormat,
  serverLink,
} from "../../url";
import axios from "axios";
import { connect } from "react-redux";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import DataTable from "../../common/data-table/data-table";

import Loader from "../../common/loader";
import BlockForm from "./block-form";
import RoomTypeForm from "./room-type-form";
import RoomForm from "./room-form";
import BedForm from "./bed-form";

function Building(props) {
  const token = props.loginData[0]?.token;
  const [IsLoading, setIsLoading] = useState(true);
  const [IsFormLoading, setIsFormLoading] = useState(false);
  const [IsFormLoading2, setIsFormLoading2] = useState(false);
  const [blockList, setBlockList] = useState([]);
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [bedList, setBedList] = useState([]);

  const blockHeader = [
    "S/N",
    "Block Name",
    "Extension",
    "Phone Number",
    "Action",
  ];
  const roomHeader = [
    "S/N",
    "Room Name",
    "Room Type",
    "Bed Capacity",
    "Amount (₦)",
    "Status",
    "Beds",
    "Action",
  ];
  const roomTypeHeader = ["S/N", "Tye Name", "Action"];
  const bedHeader = ["S/N", "Bed Name", "Room", "Block", "Status", "Action"];

  const blockInitial = {
    hospitalBlockID: "",
    blockName: "",
    description: "",
    address: "",
    extensionNo: "",
    phoneNumber: "",
    emailAddress: "",
    geoLat: "",
    geoLong: "",
    rfID: "",
    submittedBy: `${props.loginData[0]?.userID}`,
    submittedOn: "",
    updatedBy: `${props.loginData[0]?.userID}`,
    updatedOn: "",
  };
  const roomTypeInitial = {
    hospitalRoomTypeID: "",
    typeName: "",
    submittedBy: `${props.loginData[0]?.userID}`,
    submittedOn: "",
    updatedBy: `${props.loginData[0]?.userID}`,
    updatedOn: "",
  };
  const roomInitial = {
    roomID: "",
    roomName: "",
    typeID: "",
    blockID: "",
    description: "",
    emailAddress: "",
    phoneNumber: "",
    rfID: "",
    address: "",
    bedCapacity: "",
    bedOccupied: "",
    roomAmount: "",
    roomManager: "",
    status: "",
    occupiedBy: "",
    submittedBy: `${props.loginData[0]?.userID}`,
    submittedOn: "",
    updatedBy: `${props.loginData[0]?.userID}`,
    updatedOn: "",
  };

  const bedInitial = {
    bedID: "",
    bedName: "",
    roomID: "",
    status: "",
    submittedBy: `${props.loginData[0]?.userID}`,
    submittedOn: "",
    updatedBy: `${props.loginData[0]?.userID}`,
    updatedOn: "",
  };

  const [blockFormData, setBlockFormData] = useState(blockInitial);
  const [roomTypeFormData, setRoomTypeFormData] = useState(roomTypeInitial);
  const [roomFormData, setRoomFormData] = useState(roomInitial);
  const [bedFormData, setBedFormData] = useState(bedInitial);

  const resetBlockFormData = () => setBlockFormData(blockInitial);
  const resetRoomTypeFormData = () => setRoomTypeFormData(roomTypeInitial);
  const resetRoomFormData = () => setRoomFormData(roomInitial);
  const resetBedFormData = () => setBedFormData(bedInitial);

  useEffect(() => {
    getData().then((r) => {});
  }, [""]);

  const getData = async () => {
    await axios
      .get(`${serverLink}staff/settings/building/data`, token)
      .then((result) => {
        setBlockList(result.data.block);
        setRoomTypeList(result.data.type);
        setRoomList(result.data.room);
        setBedList(result.data.bed);
        setStaffList(result.data.staff);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showAlert(
          "NETWORK ERROR",
          "Please check your connection and try again!",
          "error"
        );
      });
  };

  const getStatus = (string) => {
    const stringInput = string.trim();

    if (stringInput === "Normal") {
      return <div className="badge badge-success text-center">Available</div>;
    } else {
      return (
        <div className="badge badge-danger text-center">Under Maintenance</div>
      );
    }
  };

  const getBedStatus = (query) => {
    const stringInput = query.trim();

    if (stringInput === "Available") {
      return <div className="badge badge-success text-center">Available</div>;
    } else {
      return (
        <div className="badge badge-danger text-center">Not Available</div>
      );
    }
  };

  const showBlockTable = () => {
    try {
      return (
        blockList.length > 0 &&
        blockList.map((item, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.blockName}</td>
              <td>{item.extensionNo}</td>
              <td>{item.phoneNumber}</td>
              <td>
                <a
                  href="#"
                  className={"btn btn-primary btn-sm "}
                  data-toggle="modal"
                  data-target="#block-modal"
                  onClick={() => {
                    setBlockFormData({
                      ...blockFormData,
                      hospitalBlockID: item.hospitalBlockID,
                      blockName: item.blockName,
                      extensionNo: item.extensionNo,
                      phoneNumber: item.phoneNumber,
                      emailAddress: item.emailAddress,
                      address: item.address,
                      description: item.description,
                      geoLat: item.geoLat,
                      geoLong: item.geoLong,
                    });
                  }}
                >
                  Edit
                </a>
              </td>
            </tr>
          );
        })
      );
    } catch (e) {
      alert(e.message);
    }
  };
  const showRoomTable = () => {
    try {
      return (
        roomList.length > 0 &&
        roomList.map((item, index) => {
          let rooms = roomTypeList.filter(
            (e) => e.hospitalRoomTypeID === item.typeID
          );
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.roomName}</td>
              <td>{rooms[0].typeName}</td>
              <td>{item.bedCapacity}</td>
              <td>{moneyFormat(parseFloat(item.roomAmount))}</td>
              <td>{getStatus(item.status)}</td>
              <td>
                <a
                  href="#"
                  className={"btn btn-primary btn-sm "}
                  data-toggle="modal"
                  data-target="#room-modal"
                  onClick={() => {
                    setRoomFormData({
                      ...roomFormData,
                      roomID: item.roomID,
                      roomName: item.roomName,
                      typeID: item.typeID,
                      blockID: item.blockID,
                      description: item.description,
                      emailAddress: item.emailAddress,
                      phoneNumber: item.phoneNumber,
                      address: item.address,
                      bedCapacity: item.bedCapacity,
                      bedOccupied: item.bedOccupied,
                      roomAmount: item.roomAmount,
                      roomManager: item.roomManager,
                      status: item.status,
                    });
                  }}
                >
                  View
                </a>
              </td>
              <td>
                <a
                  href="#"
                  className={"btn btn-primary btn-sm "}
                  data-toggle="modal"
                  data-target="#room-modal"
                  onClick={() => {
                    setRoomFormData({
                      ...roomFormData,
                      roomID: item.roomID,
                      roomName: item.roomName,
                      typeID: item.typeID,
                      blockID: item.blockID,
                      description: item.description,
                      emailAddress: item.emailAddress,
                      phoneNumber: item.phoneNumber,
                      address: item.address,
                      bedCapacity: item.bedCapacity,
                      bedOccupied: item.bedOccupied,
                      roomAmount: item.roomAmount,
                      roomManager: item.roomManager,
                      status: item.status,
                    });
                  }}
                >
                  Edit
                </a>
              </td>
            </tr>
          );
        })
      );
    } catch (e) {
      alert(e.message);
    }
  };
  const showBedTable = () => {
    try {
      return (
        bedList.length > 0 &&
        bedList.map((item, index) => {
          let rooms = roomList.filter((e) => e.roomID === item.roomID);
          let block = blockList.filter(
            (e) => e.hospitalBlockID === rooms[0].blockID
          );

          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.bedName}</td>
              <td>{rooms[0].roomName}</td>
              <td>{block[0].blockName}</td>
              <td>{getBedStatus(item.status)}</td>
              <td>
                <a
                  href="#"
                  className={"btn btn-primary btn-sm "}
                  data-toggle="modal"
                  data-target="#bed-modal"
                  onClick={() => {
                    setBedFormData({
                      ...bedFormData,
                      bedID: item.bedID,
                      bedName: item.bedName,
                      roomID: item.roomID,
                      status: item.status,
                    });
                  }}
                >
                  Edit
                </a>
              </td>
            </tr>
          );
        })
      );
    } catch (e) {
      alert(e.message);
    }
  };
  const showRoomTypeTable = () => {
    try {
      return (
        roomTypeList.length > 0 &&
        roomTypeList.map((item, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.typeName}</td>
              <td>
                <a
                  href="#"
                  className={"btn btn-primary btn-sm "}
                  data-toggle="modal"
                  data-target="#room-type-modal"
                  onClick={() => {
                    setRoomTypeFormData({
                      ...roomTypeFormData,
                      hospitalRoomTypeID: item.hospitalRoomTypeID,
                      typeName: item.typeName,
                    });
                  }}
                >
                  Edit
                </a>
              </td>
            </tr>
          );
        })
      );
    } catch (e) {
      alert(e.message);
    }
  };

  const onEditBed = (e) => {
    setBedFormData({
      ...bedFormData,
      [e.target.id]: e.target.value,
    });
  };
  const onEditRoom = (e) => {
    setRoomFormData({
      ...roomFormData,
      [e.target.id]: e.target.value,
    });
  };
  const onEditRoomType = (e) => {
    setRoomTypeFormData({
      ...roomTypeFormData,
      [e.target.id]: e.target.value,
    });
  };
  const onEditBlock = (e) => {
    setBlockFormData({
      ...blockFormData,
      [e.target.id]: e.target.value,
    });
  };

  const onSubmitBed = async () => {
    setIsFormLoading(true);
    if (bedFormData.bedID === "") {
      let sendData = {
        ...bedFormData,
      };
      await axios
        .post(`${serverLink}staff/settings/building/bed/add`, sendData, token)
        .then((result) => {
          setErrorMessage(result.data.errors);
          if (result.data.message === "success") {
            setIsFormLoading(false);
            toast.success("Bed Added Successfully");
            getData();
            document.getElementById("closeModal5").click();
            resetBedFormData();
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert(
              "Error",
              `Bed with given details already exist!`,
              "error"
            );
          }
          if (result.data.message === "full") {
            setIsFormLoading(false);
            showAlert(
              "Error",
              `The selected room is at full capacity, so the bed cannot be added!`,
              "error"
            );
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert("Error", `Process failed, please try again!`, "error");
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
    if (bedFormData.bedID !== "") {
      let sendData = {
        ...bedFormData,
      };
      setIsFormLoading(true);
      await axios
        .post(
          `${serverLink}staff/settings/building/bed/update`,
          sendData,
          token
        )
        .then((result) => {
          if (result.data.message === "success") {
            toast.success("Bed Updated Successfully");
            setIsFormLoading(false);
            getData();
            document.getElementById("closeModal5").click();
            resetBedFormData();
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Bed with given details already exist!",
              "error"
            );
          } else {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
  };
  const onSubmitRoom = async () => {
    setIsFormLoading(true);
    if (roomFormData.roomID === "") {
      let sendData = {
        ...roomFormData,
      };
      await axios
        .post(`${serverLink}staff/settings/building/room/add`, sendData, token)
        .then((result) => {
          setErrorMessage(result.data.errors);
          if (result.data.message === "success") {
            setIsFormLoading(false);
            toast.success("Room Added Successfully");
            getData();
            document.getElementById("closeModal5").click();
            resetRoomFormData();
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert(
              "Error",
              `Room with given details already exist!`,
              "error"
            );
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert("Error", `Process failed, please try again!`, "error");
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
    if (roomFormData.roomID !== "") {
      let sendData = {
        ...roomFormData,
      };
      setIsFormLoading(true);
      await axios
        .post(
          `${serverLink}staff/settings/building/room/update`,
          sendData,
          token
        )
        .then((result) => {
          if (result.data.message === "success") {
            toast.success("Room Updated Successfully");
            setIsFormLoading(false);
            getData();
            document.getElementById("closeModal5").click();
            resetRoomFormData();
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Room with given details already exist!",
              "error"
            );
          } else {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
  };
  const onSubmitRoomType = async () => {
    setIsFormLoading(true);
    if (roomTypeFormData.hospitalRoomTypeID === "") {
      let sendData = {
        ...roomTypeFormData,
      };
      await axios
        .post(
          `${serverLink}staff/settings/building/room-type/add`,
          sendData,
          token
        )
        .then((result) => {
          setErrorMessage(result.data.errors);
          if (result.data.message === "success") {
            setIsFormLoading(false);
            toast.success("Room Type Added Successfully");
            getData();
            document.getElementById("closeModal5").click();
            resetRoomTypeFormData();
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert(
              "Error",
              `Room Type with given details already exist!`,
              "error"
            );
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert("Error", `Process failed, please try again!`, "error");
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
    if (roomTypeFormData.hospitalRoomTypeID !== "") {
      let sendData = {
        ...roomTypeFormData,
      };
      setIsFormLoading(true);
      await axios
        .post(
          `${serverLink}staff/settings/building/room-type/update`,
          sendData,
          token
        )
        .then((result) => {
          if (result.data.message === "success") {
            toast.success("Room Type Updated Successfully");
            setIsFormLoading(false);
            getData();
            document.getElementById("closeModal5").click();
            resetRoomTypeFormData();
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert("ERROR", "Room Type name already exists!", "error");
          } else {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
  };
  const onSubmitBlock = async () => {
    setIsFormLoading(true);
    if (blockFormData.hospitalBlockID === "") {
      let sendData = {
        ...blockFormData,
      };
      await axios
        .post(`${serverLink}staff/settings/building/block/add`, sendData, token)
        .then((result) => {
          setErrorMessage(result.data.errors);
          if (result.data.message === "success") {
            setIsFormLoading(false);
            toast.success("Block Added Successfully");
            getData();
            document.getElementById("closeModal5").click();
            resetBlockFormData();
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert(
              "Error",
              `Block with given details already exist!`,
              "error"
            );
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert("Error", `Process failed, please try again!`, "error");
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
    if (blockFormData.hospitalBlockID !== "") {
      let sendData = {
        ...blockFormData,
      };
      setIsFormLoading(true);
      await axios
        .post(
          `${serverLink}staff/settings/building/block/update`,
          sendData,
          token
        )
        .then((result) => {
          if (result.data.message === "success") {
            toast.success("Block Updated Successfully");
            setIsFormLoading(false);
            getData();
            document.getElementById("closeModal5").click();
            resetBlockFormData();
          }
          if (result.data.message === "failed") {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
          if (result.data.message === "exist") {
            setIsFormLoading(false);
            showAlert("ERROR", "Block name already exists!", "error");
          } else {
            setIsFormLoading(false);
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
          }
        })
        .catch((error) => {
          setIsFormLoading(false);
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.errors
          ) {
            error.response.data.errors.forEach((error) => {
              showAlert(
                `${error.msg}`,
                `Enter correct input for ${error.path}`,
                "error"
              );
            });
          }
        });
    }
  };

  return IsLoading ? (
    <Loader />
  ) : (
    <>
      <PageTitle title={["Home", "Hospital Block"]} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs customtab" role="tablist">
                  <li className="nav-item">
                    {" "}
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#block"
                      role="tab"
                    >
                      <span className="hidden-sm-up">
                        <i className="fa fa-home text-secondary"></i>
                      </span>{" "}
                      <span className="hidden-xs-down text-secondary">
                        Block
                      </span>
                    </a>{" "}
                  </li>
                  <li className="nav-item">
                    {" "}
                    <a
                      className="nav-link "
                      data-toggle="tab"
                      href="#room-type"
                      role="tab"
                    >
                      <span className="hidden-sm-up">
                        <i className="fa fa-home text-secondary"></i>
                      </span>{" "}
                      <span className="hidden-xs-down text-secondary">
                        Room Type
                      </span>
                    </a>{" "}
                  </li>
                  <li className="nav-item">
                    {" "}
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#room"
                      role="tab"
                    >
                      <span className="hidden-sm-up">
                        <i className="fa fa-home text-secondary"></i>
                      </span>{" "}
                      <span className="hidden-xs-down text-secondary">
                        Room
                      </span>
                    </a>{" "}
                  </li>
                  <li className="nav-item">
                    {" "}
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#bed"
                      role="tab"
                    >
                      <span className="hidden-sm-up">
                        <i className="fa fa-bed text-secondary"></i>
                      </span>{" "}
                      <span className="hidden-xs-down text-secondary">Bed</span>
                    </a>{" "}
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane active" id="block" role="tabpanel">
                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                      <h4 className="card-title">Block</h4>
                      <div className="ml-auto">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-info"
                            data-toggle="modal"
                            data-target="#block-modal"
                            onClick={() => resetBlockFormData()}
                          >
                            <i className="fa fa-plus"></i> Add Block
                          </button>
                        </div>
                      </div>
                    </div>
                    <DataTable
                      tableID="block-table"
                      header={blockHeader}
                      body={showBlockTable()}
                      title="Blocks List"
                    />
                  </div>
                  <div
                    className="tab-pane  p-10"
                    id="room-type"
                    role="tabpanel"
                  >
                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                      <h4 className="card-title">Room Type</h4>
                      <div className="ml-auto">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-info"
                            data-toggle="modal"
                            data-target="#room-type-modal"
                            onClick={() => resetRoomTypeFormData()}
                          >
                            <i className="fa fa-plus"></i> Add Room Type
                          </button>
                        </div>
                      </div>
                    </div>
                    <DataTable
                      tableID="room-type-table"
                      header={roomTypeHeader}
                      body={showRoomTypeTable()}
                      title="Room Type List"
                    />
                  </div>
                  <div className="tab-pane  p-10" id="room" role="tabpanel">
                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                      <h4 className="card-title">Room</h4>
                      <div className="ml-auto">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-info"
                            data-toggle="modal"
                            data-target="#room-modal"
                            onClick={() => resetRoomFormData()}
                          >
                            <i className="fa fa-plus"></i> Add Room
                          </button>
                        </div>
                      </div>
                    </div>
                    <DataTable
                      tableID="room-table"
                      header={roomHeader}
                      body={showRoomTable()}
                      title="Room List"
                    />
                  </div>
                  <div className="tab-pane  p-10" id="bed" role="tabpanel">
                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                      <h4 className="card-title">Bed</h4>
                      <div className="ml-auto">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-info"
                            data-toggle="modal"
                            data-target="#bed-modal"
                            onClick={() => resetBedFormData()}
                          >
                            <i className="fa fa-plus"></i> Add Bed
                          </button>
                        </div>
                      </div>
                    </div>
                    <DataTable
                      tableID="bed-table"
                      header={bedHeader}
                      body={showBedTable()}
                      title="Bed List"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BlockForm
        title="Block Form"
        blockFormData={blockFormData}
        onEditBlock={onEditBlock}
        onSubmitBlock={onSubmitBlock}
        IsFormLoading={IsFormLoading}
        setBlockFormData={blockFormData}
        id="block-modal"
      />

      <RoomTypeForm
        title="Room Type Form"
        roomTypeFormData={roomTypeFormData}
        onEditRoomType={onEditRoomType}
        onSubmitRoomType={onSubmitRoomType}
        IsFormLoading={IsFormLoading}
        setRoomTypeFormData={roomTypeFormData}
        id="room-type-modal"
      />

      <RoomForm
        title="Room Form"
        roomFormData={roomFormData}
        onEditRoom={onEditRoom}
        roomTypeList={roomTypeList}
        blockList={blockList}
        staffList={staffList}
        onSubmitRoom={onSubmitRoom}
        IsFormLoading={IsFormLoading}
        setRoomFormData={roomFormData}
        id="room-modal"
      />

      <BedForm
        title="Bed Form"
        bedFormData={bedFormData}
        onEditBed={onEditBed}
        roomTypeList={roomList}
        roomList={roomList}
        staffList={staffList}
        onSubmitBed={onSubmitBed}
        IsFormLoading={IsFormLoading}
        setBedFormData={bedFormData}
        id="bed-modal"
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};
export default connect(mapStateToProps, null)(Building);
