import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
function PreviousHospitalRecord(props) {
    const { patientSerial, patientName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const recordInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        title: "",
        description: "",
        file: "",
        ImagePath: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(recordInitial)

    const resetFormData = () => {
        setFormData(recordInitial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const addPreviousMedicalRecord = async () => {
        if (formData.title.toString().trim() === ''){
            toast.error('Please enter title')
            return false
        }
        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.post(`${serverLink}patient/previous-hospital-record/add`, formData, token).then(async (result) => {
            if (result.data.message === "success") {
                if (formData.ImagePath !== "") {
                    const formData2 = new FormData();
                    formData2.append('photo', formData.ImagePath);
                    formData2.append('entry_id', result.data.entry_id)
                    axios.patch(`${serverLink}patient/previous-hospital-record-photo`, formData2)
                        .then(async (res) => {
                            toast.success("Record Added Successfully");
                            setIsFormLoading(false);
                            props.setRebuildPreviousRecord(generate_token(5));
                            document.getElementById("closeRecord").click();
                            resetFormData()
                        })
                        .catch(err => {
                            setIsFormLoading(false);
                            showAlert(
                                "ERROR",
                                "Something went wrong uploading the image. Please try again!",
                                "error"
                            );
                        });
                }else{
                    toast.success("Record Added Successfully");
                    setIsFormLoading(false);
                    props.setRebuildPreviousRecord(generate_token(5));
                    document.getElementById("closeRecord").click();
                    resetFormData()
                }
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding Record. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label"> Title</label>
                                <input
                                    id="title"
                                    name="title"
                                    className="form-control"
                                    value={formData.title}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">File</label>
                                <input
                                    type="file"
                                    className="form-control form-control-solid"
                                    name="ImagePath"
                                    id="ImagePath"
                                    onChange={onImageChange}
                                    accept="image, pdf"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="description"
                                    className="form-control"
                                    id="description"
                                    value={formData.description}
                                    onChange={onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addPreviousMedicalRecord}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PreviousHospitalRecord);