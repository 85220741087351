import React from "react";

export default function ImagingLabTypeForm(props) {
  return (
    <>
      <div
        id={`${
          typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`}
        className="modal fade bs-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                {props.title ?? ""}
              </h4>
              <button
                type="button"
                className={`${
                  typeof props.close !== "undefined"
                    ? props.close
                    : "closeModal"
                }`}
                id={`${
                  typeof props.close !== "undefined" ? props.close : "close"
                }`}
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Lab Type Name</label>
                  <input
                    type="text"
                    name="imageName"
                    className="form-control"
                    id="imageName"
                    value={props.labTypeFormData.imageName}
                    onChange={props.onEditLabType}
                    placeholder="Lab Type Name"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Price (₦)</label>
                  <input
                    type="number"
                    name="price"
                    className="form-control"
                    id="price"
                    value={props.labTypeFormData.price}
                    onChange={props.onEditLabType}
                    placeholder="Price"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Image Path</label>
                  <input
                    type="text"
                    name="imagePath"
                    className="form-control"
                    id="imagePath"
                    value={props.labTypeFormData.imagePath}
                    onChange={props.onEditLabType}
                    placeholder="Image Path"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Lab Name</label>
                  <select
                    className="form-control"
                    name="labID"
                    id="labID"
                    value={props.labTypeFormData.labID}
                    onChange={props.onEditLabType}
                  >
                    <option value="">Select Lab Name</option>
                    {props.labList &&
                      props.labList.map((item, index) => {
                        return (
                          <option key={index} value={item.imagingLabID}>
                            {item.labName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Description</label>
                  <textarea
                    cols={3}
                    rows={3}
                    name="description"
                    className="form-control"
                    id="description"
                    value={props.labTypeFormData.description}
                    onChange={props.onEditLabType}
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {props.IsFormLoading ? (
                <button type="button" className="btn btn-primary ms-auto">
                  <span>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    />{" "}
                    Please wait...
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={props.onSubmitLabType}
                  className="btn btn-danger waves-effect waves-light"
                >
                  {props.btn ?? "submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
