import {projectCode} from "../components/url";

export const LoginDetailsReducer = (state = [], action) => {
    switch (action.type) {
      case `SET_${projectCode}_LOGIN_DETAILS`:
        return action.payload;
      default:
        return state;
    }
  };


export const DashboardDataReducer = (state = [], action) => {
    switch (action.type) {
      case `SET_${projectCode}_DASHBOARD_DATA`:
        return action.payload;
      default:
        return state;
    }
  };

export const GeneralDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case `SET_${projectCode}_GENERAL_DETAILS`:
        return action.payload;
      default:
        return state;
    }
  };

export const AdmissionDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case `SET_${projectCode}_ADMISSION_DETAILS`:
        return action.payload;
      default:
        return state;
    }
  };

export const RoomDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case `SET_${projectCode}_ROOM_DETAILS`:
        return action.payload;
      default:
        return state;
    }
  };


export const PermissionDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${projectCode}_PERMISSION_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};
