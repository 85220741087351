import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {generate_token, serverLink} from "../../url";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {Link, useParams} from "react-router-dom";
import SpecimenComponent from "./utils/speciment-component";
import {toast} from "react-toastify";
import {showAlert} from "../../common/sweetalert/sweetalert";

function LabTestPage(props) {
    const token = props.loginData[0]?.token;
    const {slug} = useParams();
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [labList, setLabList] = useState([])
    const [specimenList, setSpecimenList] = useState([])
    const [specimenBottleList, setSpecimenBottleList] = useState([])
    const [specimenVolumeList, setSpecimenVolumeList] = useState([])
    const [numberOfSpecimens, setNumberOfSpecimens] = useState([{id: 1, name: 'Specimen'}])
    const [testFormData, setTestFormData] = useState({
        laboratoryID: "",
        testName: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    })
    const [formData, setFormData] = useState([])
    const [itemFormData, setItemFormData] = useState([])
    const [rebuildOnAddSpecimen, setRebuildOnAddSpecimen] = useState(null);

    const getData = async () => {
        await axios.get(`${serverLink}laboratory/test/data`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setLabList(result.data.lab);
                    setSpecimenList(result.data.specimen);
                    setSpecimenBottleList(result.data.bottle);
                    setSpecimenVolumeList(result.data.volume);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onTestEdit = async (e) => {
        setTestFormData({...testFormData, [e.target.id]: e.target.value})
    }


    const onEdit = (e, index) => {
        setFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index] = {
                ...updatedFormData[index],
                [e.target.id]: e.target.value,
            };
            return updatedFormData;
        });
    };

    const onAddSpecimen = () => {
      setNumberOfSpecimens(prevState => [...prevState, {id: numberOfSpecimens.length+1, name: `Specimen`}])
        const initialValue = {
            id: numberOfSpecimens.length+1,
            barcode: generate_token(5),
            labTestID: "",
            laboratoryID: "",
            testName: "",
            labSpecimenID: "",
            testAmount: "",
            specimenBottle: "",
            specimenBottleVolume: "",
            specimenTurnAroundTime: "",
            specimenPatientPreparation: "",
            testSpecimenID: "",
            submittedBy: `${props.loginData[0]?.userID}`,
        }
        setFormData(prevState => [...prevState, initialValue])
        setRebuildOnAddSpecimen(generate_token(5))
    }

    useEffect(() => {
        const initializeFormData = () => {
            const newFormData = numberOfSpecimens.map((item) => ({
                id: item.id,
                barcode: generate_token(5),
                labTestID: "",
                laboratoryID: "",
                testName: "",
                labSpecimenID: "",
                testAmount: "",
                specimenBottle: "",
                specimenBottleVolume: "",
                specimenTurnAroundTime: "",
                specimenPatientPreparation: "",
                testSpecimenID: "",
                submittedBy: `${props.loginData[0]?.userID}`,
            }));
            setFormData(newFormData);
        };

        getData();
        initializeFormData();
    }, []);

    const onSubmitTest = async () => {
        if (formData.length < 1 ){
            toast.error('Please add at least one specimen')
            return false;
        }
        if (itemFormData.length < 1 ){
            toast.error('Please add at least one item')
            return false;
        }

        setIsFormLoading(true)
        const requestData = {
            ...testFormData,
            specimenData: formData,
            itemsData: itemFormData,
        }
        toast.info("please wait...");
        await axios.post(`${serverLink}laboratory/test/add`, requestData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Lab Test Added Successfully");
                setIsFormLoading(false);
                setTimeout(()=>{
                    window.history.go(-1);
                }, 2000)
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding lab test. Please try again!");
            }
        }) .catch((error) => {
            console.log(error)
            setIsFormLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });

    }


    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Add Test Page"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-3 col-md-6 form-group">
                                            <label className="form-label">Laboratory Name </label>
                                            <select className="form-control" name="laboratoryID" id="laboratoryID"
                                                     onChange={(e)=>onTestEdit(e, 1)}>
                                                <option value="">Select Laboratory Name</option>
                                                {
                                                    labList.length > 0 && labList.map((item, index) => {
                                                        return (
                                                            <option key={index}
                                                                    value={item.laboratoryID}>{item.laboratoryName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-3 form-group col-md-6">
                                            <label className="form-label">Test Name</label>
                                            <input
                                                name="testName"
                                                className="form-control"
                                                id="testName"
                                                onChange={(e)=>onTestEdit(e, 1)}
                                                placeholder="Test Name"
                                            />

                                        </div>
                                    </div>
                                    <SpecimenComponent numberOfSpecimens={numberOfSpecimens} setNumberOfSpecimens={setNumberOfSpecimens} specimenList={specimenList} formData={formData} setFormData={setFormData} setItemFormData={setItemFormData} onEdit={onEdit} specimenVolumeList={specimenVolumeList} specimenBottleList={specimenBottleList} rebuildOnAddSpecimen={rebuildOnAddSpecimen}/>
                                    {/*<hr style={{marginTop: '30px'}}/>*/}
                                    {/*<button onClick={onAddSpecimen} className="btn btn-primary">Add Specimen</button>*/}
                                    <hr style={{marginTop: '30px'}}/>
                                    <button onClick={onSubmitTest} className="btn btn-cyan text-white w-100">SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(LabTestPage);

