import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import Accordion from  '../../../components/common/accordion/accordion';
import {toast} from "react-toastify";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {projectEmail, projectName, projectPatientURL} from "../../resources/constants";

const PatientLabRequest = ({patientSerial, serviceID, serviceName, handleRemount, loginData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [labRequestList, setLabRequestList] = useState([])
    const [testList, setTestList] = useState([])
    const [specimenList, setSpecimenList] = useState([])
    const [itemList, setItemList] = useState([])
    const [result, setResult] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        const url = !serviceID ? `${serverLink}laboratory/test/request/patient/${patientSerial}` : `${serverLink}laboratory/test/request/${patientSerial}/${serviceName}/${serviceID}`
        await axios.get(url, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setLabRequestList(result.data.request);
                    setTestList(result.data.test);
                    setSpecimenList(result.data.specimen);
                    setItemList(result.data.items);
                    setResult(result.data.result);
                }else {
                    setLabRequestList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const cancelRequest = async (item) => {
        toast.info("please wait...");
        const sendData = {
            labTestRequestID: item.labTestRequestID,
            patientName: item.patientName,
            patientEmail: item.patientEmail,
            doctorName: item.doctorName,
            testName: item.testName,
            hospitalName: projectName,
            hospitalPatientURL: projectPatientURL,
            hospitalEmail: projectEmail,
            testStatus: 'Cancelled',
            submittedBy: loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}laboratory/test/cancel`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Lab request has been cancelled");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const showStatus = (status) => {
        switch (status) {
            case 'Pending':
                return <span className="badge badge-pill badge-info">{status}</span>;
            case 'Result Entered':
                return <span className="badge badge-pill badge-primary">{status}</span>;
            case 'Cancelled':
                return <span className="badge badge-pill badge-danger">{status}</span>;
            default:
                return <span className="badge badge-pill badge-secondary">{status}</span>;
        }
    };



    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h3 className="card-title">Lab Test Request</h3>
                    {
                        labRequestList.length > 0 ?  labRequestList.map((item, index) => {
                            return (
                                <Accordion
                                    index={index}
                                    header={<span>{`Test: ${item.testName} => ${formatDateAndTime(item.submittedOn, 'date_and_time')} =>  Status: `}{showStatus(item.testStatus)}</span>}
                                    content={
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Test Name</th>
                                                    <th>Test Status</th>
                                                    <th>Payment Status</th>
                                                    <th>Requested By</th>
                                                    <th>Requested Date</th>
                                                    <th>Test Result</th>
                                                    <th>Result Added By</th>
                                                    <th>Result Added Date</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{item.testName}</td>
                                                    <td>{showStatus(item.testStatus)}</td>
                                                    <td>{item.paymentStatus}</td>
                                                    <td>{item.doctorName}</td>
                                                    <td>{formatDateAndTime(item.submittedOn, 'date')}</td>
                                                    <td>{!item.testAttachment ? '--' :  <a href={`${serverLink}public/uploads/lab_uploads/${item.testAttachment}`} className="btn btn-sm btn-info">View Result</a>}</td>
                                                    <td>{item.resultEnteredByName === 'No Name' ? '--' : item.resultEnteredByName}</td>
                                                    <td>{formatDateAndTime(item.resultEnteredDate, 'date')}</td>
                                                    <td>
                                                        { item.paymentStatus !== 'Paid' && (item.testStatus !== 'Cancelled' && item.testStatus !== 'Result Entered') ?
                                                            <a href="#" className={"btn btn-danger btn-sm "}
                                                               onClick={() => {
                                                                   showConfirm("Warning", `Are you sure you want to cancel this lab request?`, "warning")
                                                                       .then( async (confirm) => {
                                                                           if (confirm) {
                                                                               cancelRequest(item)
                                                                           }
                                                                       })
                                                               }}>
                                                                Cancel Request
                                                            </a> : <>--</>
                                                        }

                                                    </td>
                                                </tr>
                                                {
                                                    result.filter(e=>e.requestID === item.labTestRequestID).length > 0 ?
                                                        <tr>
                                                            <td colSpan={10}>
                                                                {
                                                                    result.filter(e=>e.requestID === item.labTestRequestID).length < 1 ?
                                                                        <div className="col-md-12">
                                                                            <div className="card">
                                                                                <div className="card-header"><h4><b>Lab Test Result</b></h4></div>
                                                                                <div className="card-body text-center text-danger">
                                                                                    <h4><b>Test Result Not Entered Yet.</b></h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="col-md-12">
                                                                            <div className="card">
                                                                                <div className="card-body">
                                                                                    <h4><b>Lab Test Result { item.labTestRequestID}</b></h4>
                                                                                    <div className="table-responsive">
                                                                                        <table className="table">
                                                                                            <thead>
                                                                                            <tr style={{backgroundColor: '#000000', color: '#FFFFFF'}}>
                                                                                                <th>S/N</th>
                                                                                                <th>Item</th>
                                                                                                <th>SI Unit</th>
                                                                                                <th>Ref. Interval</th>
                                                                                                <th>Result</th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {
                                                                                                result.filter(e=>e.requestID === item.labTestRequestID).length > 0 &&  result.filter(e=>e.requestID === item.labTestRequestID).map((item, index) => {
                                                                                                    return (
                                                                                                        <tr key={index}>
                                                                                                            <td>{index +1}</td>
                                                                                                            <td>{item.item_name}</td>
                                                                                                            <td>{item.si_unit}</td>
                                                                                                            <td>{item.minimum_interval} - {item.maximum_interval}</td>
                                                                                                            <td>{item.result}</td>
                                                                                                        </tr>
                                                                                                    );
                                                                                                })
                                                                                            }

                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-footer">


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        : <></>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                />
                            )
                        }) :  <div className="alert alert-info text-center p-3"><h3>No Record Found!</h3></div>
                    }

                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientLabRequest);

