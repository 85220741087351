import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";

const PatientPayments = ({patientSerial, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [paymentList, setPaymentList] = useState([])
    const header = ["S/N", "Payment Purpose", "Payment Method", "Amount Due",  "Amount Paid", "Payment Type", "Status", "Payment Date"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}payment/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPaymentList(result.data);
                }else {
                    setPaymentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return paymentList.length > 0 &&  paymentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.itemName}</td>
                        <td>{item.paymentMethod}</td>
                        <td>{currencyConverter(item.amountDue)}</td>
                        <td>{currencyConverter(item.amountPaid)}</td>
                        <td>{item.paymentType}</td>
                        <td>{item.paymentStatus}</td>
                        <td>{formatDateAndTime(item.paymentDate, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Payments</h5>
                    <DataTable tableID="patient-payments" header={header} body={showTable()} title="Patient Payments Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientPayments);

