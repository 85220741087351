import {formatAMPM, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

const PatientOnlineConsultation = ({patientSerial, appointmentID, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [itemList, setItemList] = useState([])
    const header = ["S/N", "doctor Name", "Consultation Date", "Consultation Time",  "Consultation Link", "Status", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/appointment/consultation/online/${patientSerial}/${appointmentID}`, token)
            .then((result) => {
                if (result.data.data.length > 0) {
                    setItemList(result.data.data);
                }else {
                    setItemList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const mark_completed = async (id) => {
        toast.info("please wait...");
        const sendData = {
            appointmentOnlineLinkID: id,
            submittedBy: loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}patient/appointment/consultation/online/update`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Consultation marked as completed");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }
    const  showTable = () => {
        try {
            return itemList.length > 0 &&  itemList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.doctorName}</td>
                        <td>{formatDateAndTime(item.consultationDate, 'date')}</td>
                        <td>{formatAMPM(item.consultationTime)}</td>
                        <td>{item.consultationLink}</td>
                        <td>{item.consultationStatus === 'Completed' ? <span className="badge badge-pill badge-success font-weight-bold">{item.consultationStatus}</span> : <span className="badge badge-pill badge-danger font-weight-bold">{item.consultationStatus}</span>}</td>
                        <td>
                            {item.consultationStatus === 'Completed' ? <>--</> :
                                <a href="#" className={"btn btn-info btn-sm "}
                                   onClick={() => {
                                       showConfirm("Warning", `Are you sure you want to mark this consultation completed?`, "warning")
                                           .then( async (confirm) => {
                                               if (confirm) {
                                                   mark_completed(item.appointmentOnlineLinkID)
                                               }
                                           })
                                   }}>
                                    Mark Completed
                                </a>}
                            </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Online Consultation Link</h5>
                    <DataTable tableID="patient-consultation-link" header={header} body={showTable()} title="Online Consultation Link Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientOnlineConsultation);

