import React from "react";
import {Link} from "react-router-dom";

export default function MenuRoute(props) {
    return (
        <Link to={props.url} className={props.size} >
            <div className={`card border-bottom border-${props.color}`} onClick={typeof props.onClick === 'undefined' ? ()=>{} : props.onClick}>
                <div className="card-body">
                    <div className="d-flex no-block align-items-center">
                        <div>
                            <h4 className={`text-${props.color}`}>{props.title}</h4>
                        </div>
                        <div className="ml-auto">
                            <span className={`text-${props.color} display-6`}><i className={`fa ${props.icon}`}></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}