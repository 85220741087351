import React, {useState, useRef, useEffect} from 'react';
import Webcam from 'react-webcam';
import {toast} from "react-toastify";

const FileUploader = (props) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isWebcamOpen, setIsWebcamOpen] = useState(false);
    const webcamRef = useRef(null);
    const [fileType, setFileType] = useState(null);

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const image = event.target.files[0];
            if (event.target.files[0].size > 1000000) {
                toast.error("max file size is 1mb")
                return false;
            } else {
                props.setFormData({
                    ...props.formData,
                    ImagePath: event.target.files[0],
                    ImageData: event.target.files[0],
                })
                setFileType(event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpg' || event.target.files[0].type === 'image/jpeg' ? event.target.files[0] : null)
            }
            setSelectedImage(URL.createObjectURL(image));
        }
    };

    useEffect(() => {
        setIsWebcamOpen(false)
        setSelectedImage(null)
    }, []);

    return (
        <div style={{ textAlign: 'center', marginTop: '0px' }}>

            {
                !isWebcamOpen ?
                    <div className="text-left" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        <label htmlFor="file" className="col-md-12 col-lg-12 col-form-label">{props.title ?? ""} </label>
                        (<small className="text-danger">Max size is 1mb</small>)
                        <input type="file"  accept="image/*" className="form-control" onChange={handleImageChange} />
                    </div>
                    : <></>
            }
            {
                fileType && selectedImage && (
                    <div>
                        <h3>Preview:</h3>
                        <img
                            src={selectedImage}
                            alt="Selected"
                            style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                        />
                    </div>
                )

            }
        </div>
    );
};

export default FileUploader;
