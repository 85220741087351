import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import { serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import SpecialisationForm from "./specialisation-form";

function Specialisation(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [specialisationList, setSpecialisationList] = useState([])
    const header = ["S/N", "Specialisation Name", "Consultation Fee", "Specialisation Description", "submitted By", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        specialisationID: "",
        specialisationName: "",
        specialisationDescription: "",
        consultationFee: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/settings/specialisation/list/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setSpecialisationList(result.data);
                }else {
                    setSpecialisationList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}staff/settings/specialisation/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return specialisationList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.specialisationName}</td>
                        <td>{item.consultationFee}</td>
                        <td dangerouslySetInnerHTML={{__html: item.specialisationDescription}}></td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       specialisationID: item.specialisationID,
                                       specialisationName: item.specialisationName,
                                       consultationFee: item.consultationFee,
                                       specialisationDescription: item.specialisationDescription,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td>
                            <a href="#" className={"btn btn-danger btn-sm "}
                               onClick={()=>showConfirm("Warning", `Are you sure you want to delete this specialisation?`, "warning")
                                   .then( async (confirm) => {
                                       if (confirm) {
                                           delete_item(item.specialisationID)
                                       }
                                   })}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            specialisationDescription: event,
        });
    }

    const onSubmit = async () => {
        if (formData.specialisationName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter specialisation name", "error");
            return false;
        }

        if (formData.consultationFee.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter consultation fee", "error");
            return false;
        }


        if (formData.specialisationID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/settings/specialisation/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Specialisation Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            specialisationID: "",
                            specialisationName: "",
                            specialisationDescription: "",
                            consultationFee: ""
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/settings/specialisation/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Specialisation Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            specialisationID: "",
                            specialisationName: "",
                            specialisationDescription: "",
                            consultationFee: ""
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Specialisation"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage Specialisation</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        specialisationID: "",
                                                        specialisationName: "",
                                                        specialisationDescription: "",
                                                        consultationFee: ""
                                                    });
                                                }}>
                                                    <i className="fa fa-plus"></i> Add Specialisation
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="Specialisation" header={header} body={showTable()} title="Specialisation Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SpecialisationForm
                    title="Manage Specialisation"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    descriptionHandler={descriptionHandler}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(Specialisation);

