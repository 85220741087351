import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {currencyConverter, serverLink} from "../../url";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {Link, useParams} from "react-router-dom";

function AdmissionPage(props) {
    const token = props.loginData[0]?.token;
    const {slug} = useParams();
    const [IsLoading, setIsLoading] = useState(true)
    const [blockList, setBlockList] = useState([])
    const [patientDetails, setPatientDetails] = useState({})

    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}admission/hospital/block/${atob(slug)}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setBlockList(result.data.data);
                    setPatientDetails(result.data.patient[0]);
                }else {
                    setBlockList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Patient Admission"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                            <br/>
                                            <p className="text-muted">{patientDetails?.patientName}</p>
                                        </div>
                                        <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                            <br/>
                                            <p className="text-muted">{patientDetails?.patientSerial}</p>
                                        </div>
                                        <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                            <br/>
                                            <p className="text-muted">{currencyConverter(patientDetails?.walletAmount)}</p>
                                        </div>
                                        <div className="col-md-12"
                                             style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                                    </div>
                                    <h2><strong> <i className="fa fa-hospital"></i> Hospital Blocks</strong></h2>
                                    <hr/>
                                    <div className="row">
                                        {
                                            blockList.length > 0 ? blockList.map((item, index)=> {
                                                return (
                                                    <div key={index} className="col-md-3 border border-4 mr-5 ml-4 mb-3">
                                                        <Link to={`/admit-patient/${slug}/${btoa(item.hospitalBlockID)}`}>
                                                            <img src={require('../../../images/block.jpeg')} className="img-fluid"
                                                                 alt="Block"/>
                                                            <hr/>
                                                            <div className="col-md-12 text-secondary">
                                                                <h4><strong>{item.blockName}</strong></h4>
                                                                <span><b>Extension: </b> {item.extensionNo}</span><br/>
                                                                <span><b>Address: </b> {item.address}</span><br/><br/>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                )
                                            }) : <div className="alert alert-danger col-md-12 text-center"><h3>No Record Found</h3></div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(AdmissionPage);

