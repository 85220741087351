import {currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import {toast} from "react-toastify";

const PatientAmountIncurred = ({patientSerial, loginData, handleRemount, serviceID, serviceName}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [prescriptionList, setPrescriptionList] = useState([])
    const [imagingList, setImagingList] = useState([])
    const [labList, setLabList] = useState([])
    const [roomList, setRoomList] = useState([])
    const [roomTotal, setRoomTotal] = useState(0)
    const [prescriptionTotal, setPrescriptionTotal] = useState(0)
    const [imagingTotal, setImagingTotal] = useState(0)
    const [labTotal, setLabTotal] = useState(0)

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}patient/appointment/amount-incurred/${serviceName.toLowerCase()}/list/${patientSerial}/${serviceID}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    console.log(result.data)
                    setPrescriptionList(result.data.prescription);
                    setImagingList(result.data.imaging);
                    setLabList(result.data.lab);
                    setRoomList(result.data.room);
                    //Set Total Due
                    setPrescriptionTotal(result.data.prescription.filter(drug=>drug.status !== "Cancelled" && drug.status !== "Paid").reduce((acc, service) => acc + service.drugTotalAmount, 0));
                    setImagingTotal(result.data.imaging.filter(e => e.status !== "Cancelled" && e.paymentStatus !== 'Paid').reduce((acc, service) => acc + service.price, 0));
                    setLabTotal(result.data.lab.filter(lab=> lab.paymentStatus !== 'Paid' && (lab.testStatus !== "Result Rejected" || lab.testStatus !== "Cancelled")).reduce((acc, service) => acc + service.testAmount, 0));
                    setRoomTotal(result.data.room.reduce((acc, x) => acc + x.incurredAmount, 0));
                }else {
                    console.log("error")
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    return (IsLoading ? <Loader/> :
            <div className="">
                {
                    serviceName === 'Admission' ?
                        <div className="table-responsive">
                            <h3>Room Fees</h3>
                            <table className="table table-striped mb-3">
                                <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Room Name</th>
                                    <th>Room Amount / Day</th>
                                    <th>Number of Days</th>
                                    <th>Amount Incurred</th>
                                    <th>Amount Paid</th>
                                    <th>Amount Due</th>
                                </tr>
                                </thead>
                                {roomList.length === 0 ?
                                    (
                                        <tbody>
                                        <tr>
                                            <td colSpan={11} className="text-center">No Record Found</td>
                                        </tr>
                                        </tbody>
                                    )
                                    :
                                    (
                                        <tbody>
                                        {roomList.map((r, count) => {
                                            return (
                                                <tr key={count}>
                                                    <td>{count+1}</td>
                                                    <td>{r.bedName}</td>
                                                    <td>{currencyConverter(r.roomAmount)}</td>
                                                    <td>{r.incurredAmount/r.roomAmount} {r.incurredAmount/r.roomAmount > 1 ? 'Days' : 'Day'}</td>
                                                    <td>{currencyConverter(r.incurredAmount)}</td>
                                                    <td>{currencyConverter(r.admissionStatus === 'Active' ? r.roomAmount : r.incurredAmount)}</td>
                                                    <td>{currencyConverter( roomTotal )}</td>
                                                </tr>
                                            )
                                        })}
                                        {
                                            <tr>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td><h3><b>Amount Due</b></h3></td>
                                                <td><h4><b>{currencyConverter(roomTotal)}</b></h4></td>
                                            </tr>
                                        }
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>
                        : <></>
                }


                <div className="table-responsive">
                    <h3>Lab Fees</h3>
                    <table className="table table-striped mb-3">
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Item Name</th>
                                <th>Request Date</th>
                                <th>Test Status</th>
                                <th>Requested By</th>
                                <th>Payment Status</th>
                                <th>Test Amount</th>
                            </tr>
                        </thead>
                        {labList.length === 0 ?
                            (
                                <tbody>
                                <tr>
                                    <td colSpan={11} className="text-center">No Record Found</td>
                                </tr>
                                </tbody>
                            )
                            :
                            (
                                <tbody>
                                {labList.map((r, count) => {
                                    return (
                                        <tr key={count}>
                                            <td>{count+1}</td>
                                            <td>{r.testName}</td>
                                            <td>{formatDateAndTime(r.requestDate, "date")}</td>
                                            <td>{r.testStatus}</td>
                                            <td>{r.doctorName}</td>
                                            <td>{r.paymentStatus}</td>
                                            <td>{currencyConverter(r.testStatus !== 'Cancelled' ? r.testAmount : 0)}</td>
                                        </tr>
                                    )
                                })}
                                {
                                    <tr>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td><h3><b>Amount Due</b></h3></td>
                                        <td><h4><b>{currencyConverter(labTotal)}</b></h4></td>
                                    </tr>
                                }
                                </tbody>
                            )
                        }
                    </table>
                </div>

                <div className="table-responsive m-t-5">
                    <h3>Prescription Fees</h3>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th>Dose</th>
                            <th>Other Instruction</th>
                            <th>Payment Status</th>
                            <th>Drug Amount</th>
                        </tr>
                        </thead>
                        {prescriptionList.length === 0 ?
                            (
                                <tbody>
                                <tr>
                                    <td colSpan={9} className="text-center">No Record Found</td>
                                </tr>
                                </tbody>
                            )
                            :
                            (
                                <tbody>
                                {prescriptionList.map((r, count) => {
                                    return (
                                        <tr key={count}>
                                            <td>{count+1}</td>
                                            <td>{r.drugName}</td>
                                            <td>{r.drugQuantity}</td>
                                            <td>{r.drugDose} / {r.doseFrequency}</td>
                                            <td>{r.prescription}</td>
                                            <td>{r.status}</td>
                                            <td>{currencyConverter(r.status !== 'Cancelled' ? r.drugTotalAmount : 0)}</td>
                                        </tr>
                                    )
                                })}
                                {
                                    <tr>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td colSpan={2}><h3><b>Amount Due</b></h3></td>
                                        <td><h4><b>{currencyConverter(prescriptionTotal)}</b></h4></td>
                                    </tr>
                                }
                                </tbody>
                            )
                        }
                    </table>
                </div>

                <div className="table-responsive m-t-5">
                    <h3>Imaging Fees</h3>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Imaging Name</th>
                            <th>Requested By</th>
                            <th>Requested Date</th>
                            <th>Status</th>
                            <th>Payment Status</th>
                            <th>Request Amount</th>
                        </tr>
                        </thead>
                        {imagingList.length === 0 ?
                            (
                                <tbody>
                                <tr>
                                    <td colSpan={9} className="text-center">No Record Found</td>
                                </tr>
                                </tbody>
                            )
                            :
                            (
                                <tbody>
                                {imagingList.map((r, count) => {
                                    return (
                                        <tr key={count}>
                                            <td>{count + 1}</td>
                                            <td>{r.labName}</td>
                                            <td>{r.doctorName}</td>
                                            <td>{formatDateAndTime(r.submittedOn, "date")}</td>
                                            <td>{r.status}</td>
                                            <td>{r.paymentStatus}</td>
                                            <td>{currencyConverter(r.status !== 'Cancelled' ? r.price : 0)}</td>
                                        </tr>
                                    )
                                })}
                                {
                                    <tr>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td>--</td>
                                        <td><h3><b>Amount Due</b></h3></td>
                                        <td><h4>
                                            <b>{currencyConverter(imagingTotal)}</b>
                                        </h4></td>
                                    </tr>
                                }
                                </tbody>
                            )
                        }
                    </table>

                    <div className="col-md-12">
                        <div className="card card-body printableArea">
                            <div className="row">
                                <div className="col-md-12" style={{marginTop: '40px'}}>
                                    <div className="pull-right m-t-30 text-right">
                                        <hr/>
                                        <h2 className="text-center"><b>TOTAL AMOUNT DUE
                                            :</b> {currencyConverter(labTotal + prescriptionTotal + imagingTotal + roomTotal)}
                                        </h2>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientAmountIncurred);

