import React from "react";
import {formatDate} from "../../url";
import Select from "react-select";
import ImageUploader from "../../patient/components/image-uploader";

export default function SystemUserForm(props) {

    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="hr-text hr-text-left col-md-12 text-uppercase"><h4><b>=> Personal Information</b></h4><hr/></div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Title</label>
                                <select className="form-control" name="title" id="title"
                                        value={props.formData.title} onChange={props.onEdit}>
                                    <option value="">Select Title</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Mss">Mss</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Dr">Dr</option>
                                    <option value="Capt">Capt</option>
                                    <option value="Prof">Prof</option>
                                    <option value="HRH">HRH</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">First Name</label>
                                <input
                                    name="firstName"
                                    className="form-control"
                                    id="firstName"
                                    value={props.formData.firstName}
                                    onChange={props.onEdit}
                                    placeholder="First Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Middle Name</label>
                                <input
                                    name="middleName"
                                    className="form-control"
                                    id="middleName"
                                    value={props.formData.middleName}
                                    onChange={props.onEdit}
                                    placeholder="Middle Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Surname</label>
                                <input
                                    name="surname"
                                    className="form-control"
                                    id="surname"
                                    value={props.formData.surname}
                                    onChange={props.onEdit}
                                    placeholder="Surname"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Gender</label>
                                <select className="form-control" name="gender" id="gender"
                                        value={props.formData.gender} onChange={props.onEdit}>
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    className="form-control"
                                    id="phoneNumber"
                                    value={props.formData.phoneNumber}
                                    onChange={props.onEdit}
                                    placeholder="Phone Number"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Email Address</label>
                                <input
                                    name="emailAddress"
                                    className="form-control"
                                    id="emailAddress"
                                    value={props.formData.emailAddress}
                                    onChange={props.onEdit}
                                    placeholder="Email Address"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Password</label>
                                <div className="input-group input-group-flat">
                                    <input
                                        type={props.showPassword ? "text" : "password"}
                                        className="form-control"
                                        name="password"
                                        id="password"
                                        value={props.formData.Password}
                                        onChange={props.onEdit}
                                        placeholder="Password"
                                        autoComplete="off"/>
                                    <span className="input-group-text">
                                          <a href="#" onClick={() => props.setShowPassword(!props.showPassword)}
                                             className="input-group-link">
                                              {
                                                  props.showPassword ?
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                                                           width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                           stroke="currentColor" fill="none" strokeLinecap="round"
                                                           strokeLinejoin="round">
                                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                          <line x1="3" y1="3" x2="21" y2="21"/>
                                                          <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"/>
                                                          <path
                                                              d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"/>
                                                      </svg>
                                                      :
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                                                           width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                           stroke="currentColor" fill="none" strokeLinecap="round"
                                                           strokeLinejoin="round">
                                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                          <circle cx="12" cy="12" r="2"/>
                                                          <path
                                                              d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"/>
                                                      </svg>

                                              }
                                          </a>
                                        </span>
                                </div>
                            </div>

                            <div className="hr-text hr-text-left col-md-12 text-uppercase"><hr/><h4><b>=> Social Network Links (optional section)</b></h4><hr/></div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Facebook</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="facebook"
                                    id="facebook"
                                    value={props.formData.facebook}
                                    onChange={props.onEdit}
                                    placeholder="Facebook"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Twitter</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="twitter"
                                    id="twitter"
                                    value={props.formData.twitter}
                                    onChange={props.onEdit}
                                    placeholder="Twitter"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">LinkedIn</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="linkedin"
                                    id="linkedin"
                                    value={props.formData.linkedin}
                                    onChange={props.onEdit}
                                    placeholder="LinkedIn"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Google Scholar</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="googleScholar"
                                    id="googleScholar"
                                    value={props.formData.googleScholar}
                                    onChange={props.onEdit}
                                    placeholder="Google Scholar"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Researchgate</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="researchGate"
                                    id="researchGate"
                                    value={props.formData.researchGate}
                                    onChange={props.onEdit}
                                    placeholder="Researchgate"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Academia</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="academia"
                                    id="academia"
                                    value={props.formData.academia}
                                    onChange={props.onEdit}
                                    placeholder="Academia"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Biography">
                                        Biography
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        id="aboutDoctor"
                                        placeholder="About Doctor"
                                        required
                                        value={props.formData.aboutDoctor}
                                        onChange={props.onEdit}
                                    />
                                </div>
                            </div>
                            <div className="hr-text hr-text-left col-md-12"><hr/><h4><b>=> ADMINISTRATIVE DETAILS</b></h4><hr/></div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Date Of First Appointment</label>
                                <input
                                    type="date"
                                    name="dateOfFirstAppointment"
                                    className="form-control"
                                    id="dateOfFirstAppointment"
                                    value={formatDate(props.formData.dateOfFirstAppointment)}
                                    onChange={props.onEdit}
                                    placeholder="Date Of First Appointment"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Date Of Renewed Appointment</label>
                                <input
                                    type="date"
                                    name="dateOfRenewedContract"
                                    className="form-control"
                                    id="dateOfRenewedContract"
                                    value={formatDate(props.formData.dateOfRenewedContract)}
                                    onChange={props.onEdit}
                                    placeholder="Date Of Renewed Contract"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Employment Status</label>
                                <select className="form-control" name="employmentStatus" id="employmentStatus"
                                        value={props.formData.employmentStatus} onChange={props.onEdit}>
                                    <option value="">Select Employment Status</option>
                                    <option>Full-time</option>
                                    <option>Part-time</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Staff Role</label>
                                <select className="form-control" name="role" id="role"
                                        value={props.formData.role} onChange={props.onEdit}>
                                    <option value="">Select Role</option>
                                    <option value="Accident and Emergency">Accident and Emergency</option>
                                    <option value="Admin Staff">Admin Staff</option>
                                    <option value="Administrator">Administrator</option>
                                    <option value="Anesthesia and critical care">Anesthesia and critical care</option>
                                    <option value="Biomedical Engineering">Biomedical Engineering</option>
                                    <option value="Business Manager">Business Manager</option>
                                    <option value="CMAC">CMAC</option>
                                    <option value="Dental and Surgery">Dental and Surgery</option>
                                    <option value="Doctor">Doctor</option>
                                    <option value="Ear, Nose, and Throat (ENT)">Ear, Nose, and Throat (ENT)</option>
                                    <option value="G.O.P.D">G.O.P.D</option>
                                    <option value="General Practitioner">General Practitioner</option>
                                    <option value="Haematology">Haematology</option>
                                    <option value="Health Information and Records">Health Information and Records</option>
                                    <option value="Histopathology">Histopathology</option>
                                    <option value="Immunization Unit">Immunization Unit</option>
                                    <option value="Internal Medicine">Internal Medicine</option>
                                    <option value="Laboratory">Laboratory</option>
                                    <option value="Matron">Matron</option>
                                    <option value="Microbiology">Microbiology</option>
                                    <option value="Nurse">Nurse</option>
                                    <option value="Obstetrics and Gynaecology">Obstetrics and Gynaecology</option>
                                    <option value="Ophthalmology">Ophthalmology</option>
                                    <option value="Paediatrics">Paediatrics</option>
                                    <option value="Pathology">Pathology</option>
                                    <option value="Pharmacy">Pharmacy</option>
                                    <option value="Physiotherapy">Physiotherapy</option>
                                    <option value="Procedure">Procedure</option>
                                    <option value="Radiology">Radiology</option>
                                    <option value="Specialist">Specialist</option>
                                    <option value="Surgery and Orthopaedics">Surgery and Orthopaedics</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Specialisation</label>
                                <Select
                                    isMulti
                                    isSearchable
                                    options={props.specialisationList}
                                    // className="form-control"
                                    name="specialisation"
                                    id="specialisation"
                                    value={props.formData.specialisationData}
                                    onChange={props.onSpecialisationChange}
                                />

                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Status</label>
                                <select className="form-control" name="status" id="status"
                                        value={props.formData.status} onChange={props.onEdit}>
                                    <option value="">Select Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <ImageUploader formData={props.formData} setFormData={props.setFormData} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}