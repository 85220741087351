import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

const PatientDiagnosis = ({patientSerial, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [codeList, setCodeList] = useState([])
    const header = ["S/N", "Code", "Title", "Added By",  "Date Added", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}icd/patient/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setCodeList(result.data);
                }else {
                    setCodeList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return codeList.length > 0 &&  codeList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.icdCode}</td>
                        <td>{item.icdTitle}</td>
                        <td>{item.doctorName}</td>
                        <td>{formatDateAndTime(item.submittedOn, 'date')}</td>
                        <td>
                            {
                                item.doctorID === loginData[0]?.userID ?
                                    <a href="#" className={"btn btn-danger btn-sm "}
                                       onClick={() => {
                                           showConfirm("Warning", `Are you sure you want to delete this record?`, "warning")
                                               .then( async (confirm) => {
                                                   if (confirm) {
                                                       delete_item(item.codeID)
                                                   }
                                               })
                                       }}>
                                        Delete
                                    </a>
                                    :
                                    '--'
                            }
                            </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}icd/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Diagnosis</h5>
                    <DataTable tableID="patient-diagnosis" header={header} body={showTable()} title="Patient Diagnosis Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientDiagnosis);

