import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {calculateAge, currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";

import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import CaptureVitals from "../../patient/components/capture-vitals";
import Allergy from "../../patient/components/allergy";
import PatientImagingRequest from "../../doctor/utils/patient-imaging-request";
import {PatientInfoCardLarge} from "../../patient/utils/patient-info-card-large";
import {toast} from "react-toastify";
import MakePayment from "../../finance/choose-payment/choose-payment";
import BirthCertificate from "../../patient/components/birth-certificate";
import ImagingResultUpload from "./imaging-result-upload";

function ImagingDashboard(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState(props.patientDetails)
    const [vitalFormData, setVitalFormData] = useState({})
    const [rebuildVitals, setRebuildVitals] = useState(null);
    const [rebuildCheckIn, setRebuildCheckIn] = useState(null);
    const [rebuildAllergy, setRebuildAllergy] = useState(null);
    const [rebuildImagingRequest, setRebuildImagingRequest] = useState(null);
    const [rebuildDashboard, setRebuildDashboard] = useState(null);

    useEffect( () => {
        getData();
    }, [rebuildDashboard]);

    const getData = async () => {
        setIsLoading(true)
        let record = {};
        await axios.get(`${serverLink}patient/consultation/${patientDetails.patientSerial}`, token)
            .then(async (result) => {
                const patient = result.data;
                await axios
                    .get(`${serverLink}imaging/request/${patientDetails.imagingRequestID}`, token)
                    .then((res) => {
                        if (res.data.length > 0) {
                            const item = {
                                ...patientDetails,
                                walletAmount:patient[0].walletAmount,
                                passport:patient[0].passport,
                                firstName:patient[0].firstName,
                                middleName:patient[0].middleName,
                                surname:patient[0].surname,
                                title:patient[0].title,
                                emailAddress:patient[0].emailAddress,
                                phoneNumber:patient[0].phoneNumber,
                                altPhoneNumber:patient[0].altPhoneNumber,
                                dateOfBirth:patient[0].dateOfBirth,
                                bloodGroup:patient[0].bloodGroup,
                                gender:patient[0].gender,
                                status:patient[0].status,
                                maritalStatus:patient[0].maritalStatus,
                                nextOfKinName:patient[0].nextOfKinName,
                                nextOfKinPhone:patient[0].nextOfKinPhone,
                                nextOfKinRelationship:patient[0].nextOfKinRelationship,
                                residentialAddress:patient[0].residentialAddress,
                                lastVisit:patient[0].lastVisit,
                                ...res.data[0]
                            }
                            setPatientDetails(item)
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.log("NETWORK ERROR");
                    });
            })
            .catch((err) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    }

    const initiatePayment = async (paymentData) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...paymentData,
            itemID: patientDetails.imagingRequestID,
        }
        await axios.post(`${serverLink}payment/post-payment`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Payment Successfully");
                setIsFormLoading(false);
                setRebuildDashboard(generate_token(5))
                document.getElementById("closePayment").click();

            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong posting payment. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    const clearPayment = () => {
        if (parseFloat(patientDetails.price) === 0){
            toast.error('Amount cannot be 0')
            return false
        }
        document.getElementById('pay-now').click();
    }

    const showStatus = (status) => {
        switch (status) {
            case 'Pending':
                return <span className="badge badge-pill badge-info">{status}</span>;
            case 'Result Entered':
                return <span className="badge badge-pill badge-primary">{status}</span>;
            case 'Cancelled':
                return <span className="badge badge-pill badge-danger">{status}</span>;
            default:
                return <span className="badge badge-pill badge-secondary">{status}</span>;
        }
    };

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Imaging Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Imaging Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-imaging-tab" data-toggle="pill"
                                           href="#imaging" role="tab" aria-controls="pills-imaging"
                                           aria-selected="false">Imaging History</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCardLarge patientDetails={patientDetails}/>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h4><b>Imaging Request Details</b></h4>
                                                            <div className="table-responsive">
                                                               <table className="table">
                                                                   <tbody>
                                                                        <tr>
                                                                            <th>Lab: </th>
                                                                            <td>{patientDetails.labName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Imaging: </th>
                                                                            <td>{patientDetails.imagingName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Requested By: </th>
                                                                            <td>{patientDetails.doctorName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Requested Date: </th>
                                                                            <td>{formatDateAndTime(patientDetails.submittedOn, 'date_and_time')}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Status: </th>
                                                                            <td>{showStatus(patientDetails.requestStatus)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Payment Status: </th>
                                                                            <td>{patientDetails.paymentStatus === 'Paid' ? <span className="badge badge-pill badge-success">{patientDetails.paymentStatus}</span> : <span className="badge badge-pill badge-danger">{patientDetails.paymentStatus}</span>}</td>
                                                                        </tr>
                                                                        {
                                                                            !patientDetails.result ?
                                                                                <>
                                                                                    <tr>
                                                                                        <th  style={{padding: '35px'}}>&nbsp; &nbsp;</th>
                                                                                        <td>&nbsp; &nbsp;</td>
                                                                                    </tr>
                                                                                </>
                                                                                :
                                                                                <tr style={{borderTop: '1px solid #cccccc'}}>
                                                                                    <td colSpan={2}>
                                                                                        <div className="col-md-12 pt-3">
                                                                                            <div className="row">
                                                                                                <div className="col-md-4">
                                                                                                    <span className="font-bold p-t-30 db">Result</span><br/>
                                                                                                    <a href={`${serverLink}public/uploads/imaging_uploads/${patientDetails.result}`} className="btn btn-sm btn-info">View Result</a>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <span className="font-bold p-t-30 db">Result Entered By</span>
                                                                                                    <h6>{patientDetails.resultEnteredByName}</h6>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <span className="font-bold p-t-30 db">Entered Date</span>
                                                                                                    <h6>{formatDateAndTime(patientDetails.resultEnteredDate, 'date_and_time')}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                        }
                                                                   </tbody>
                                                               </table>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <hr style={{margin: '0px', padding: '0px'}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    patientDetails.requestStatus === 'Cancelled' ?
                                                        <div className="col-md-12 mt-5">
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <h2>
                                                                        <b>
                                                                        <span className="badge badge-pill badge-danger">Request Cancelled</span>
                                                                        </b>
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <MenuCard title="Make Payment" icon="fa-dollar-sign"
                                                                          size="col-md-6" onClick={() => {
                                                                    if (patientDetails.paymentStatus === 'Paid') {
                                                                        toast.error("Payment was made already")
                                                                        return false
                                                                    } else {
                                                                        clearPayment();
                                                                    }
                                                                }}
                                                                          color="orange" modal="birth-modal"/>
                                                                {
                                                                    patientDetails.paymentStatus === 'Pending' ?
                                                                        <MenuCard title="Upload Result"
                                                                                  icon="mdi mdi-cloud-upload"
                                                                                  size="col-md-6" onClick={() => {
                                                                            toast.error("Clear payment first!")
                                                                            return false
                                                                        }}
                                                                                  color="success"/>
                                                                        :
                                                                        <MenuCard title="Upload Result"
                                                                                  icon="mdi mdi-cloud-upload"
                                                                                  size="col-md-6" color="success"
                                                                                  modal="result-modal"/>
                                                                }

                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="imaging" role="tabpanel"
                                         aria-labelledby="pills-imaging-tab">
                                        <div className="card-body">
                                            <PatientImagingRequest patientSerial={patientDetails.patientSerial}
                                                                   handleRemount={rebuildImagingRequest}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button data-toggle="modal" style={{display: 'none'}} id="pay-now" data-target={`#payment-modal`}>Open
                </button>
                <MakePayment
                    id="payment-modal"
                    title="Make Payment"
                    close="closePayment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    paymentFor="Imaging"
                    itemName={patientDetails.imagingName}
                    itemAmount={parseFloat(patientDetails.price)}
                    paymentType="Debit"
                    IsFormLoading={IsFormLoading}
                    onPostPayment={initiatePayment}
                    onError={() => {
                        console.log("Error")
                    }}
                />
                <CaptureVitals
                    id="vital-modal"
                    title="Capture Vital"
                    close="closeVital"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setVitalFormData={setVitalFormData}
                    vitalFormData={vitalFormData}
                    handleRemount={rebuildCheckIn}
                    setRebuildVitals={setRebuildVitals}
                />
                <Allergy
                    id="allergy-modal"
                    title="Add Allergy"
                    close="closeAllergy"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildAllergy={setRebuildAllergy}
                />
                <ImagingResultUpload
                    id="result-modal"
                    title="Upload Imaging Result"
                    close="closeResult"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    imagingRequestID={patientDetails.imagingRequestID}
                    setRebuildDashboard={setRebuildDashboard}
                    refreshDashboard={getData}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(ImagingDashboard);

