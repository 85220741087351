import React, {useEffect, useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import JoditEditor from "jodit-react";
function NurseNote(props) {
    const { patientSerial, patientName, walletAmount, serviceID, serviceName, nurseNoteFormData} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const FormInitialValue = {
        patientSerial: patientSerial,
        patientName: patientName,
        nurseNoteID: "",
        nurseID: `${props.loginData[0]?.userID}`,
        note1: "",
        note2: "",
        note3: "",
        note4: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(FormInitialValue)

    const resetFormData = () => {
        setFormData(FormInitialValue)
    }

    const getItemID = (service_name, service_id) => {
        if (service_name === 'Consultation'){
            return {appointmentID: service_id, admissionID: null}
        }else if (service_name === 'Admission'){
            return {admissionID: service_id, appointmentID: null}
        }else{
            return {}
        }
    }

    const addNote = async () => {
        if (serviceID.toString().trim() === ''){
            toast.error(`${serviceName} ID cannot be empty`)
            return false
        }
        if (formData.note1.toString().trim() === '' && formData.note2.toString().trim() === ''
            && formData.note3.toString().trim() === '' && formData.note4.toString().trim() === ''
        ){
            toast.error('You have to add at least one note before submitting!')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            ...getItemID(serviceName, serviceID)
        }

        if (formData.nurseNoteID === ''){
            await axios.post(`${serverLink}patient/consultation/nurse-note/add`, sendData, token).then(async (result) => {
                if (result.data.message === "success") {
                    toast.success("Note Added Successfully");
                    setIsFormLoading(false);
                    props.setRebuildNurseNote(generate_token(5));
                    document.getElementById("closeNurseNote").click();
                    resetFormData()
                } else {
                    setIsFormLoading(false);
                    toast.error("Something went wrong adding note. Please try again!");
                }
            }) .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
        }else {
            await axios.patch(`${serverLink}patient/consultation/nurse-note/update`, sendData, token).then(async (result) => {
                if (result.data.message === "success") {
                    toast.success("Note Updated Successfully");
                    setIsFormLoading(false);
                    props.setRebuildNurseNote(generate_token(5));
                    document.getElementById("closeNurseNote").click();
                    resetFormData()
                } else {
                    setIsFormLoading(false);
                    toast.error("Something went wrong adding note. Please try again!");
                }
            }) .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
        }
    }
    useEffect(() => {
        if (Object.keys(nurseNoteFormData).length !== 0) {
            setFormData({
                ...formData,
                ...nurseNoteFormData
            });
        }else{
            setFormData(FormInitialValue)
        }
    }, [nurseNoteFormData]);

    const editorConfig = {
        readonly: false,
        height: 200
    };

    const onObservationChange = (e) => {setFormData({...formData, note1: e})}
    const onMorningChange = (e) => {setFormData({...formData, note2: e})}
    const onAfternoonChange = (e) => {setFormData({...formData, note3: e})}
    const onNightChange = (e) => {setFormData({...formData, note4: e})}

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label fw-bold text-uppercase"><h4><b>Observation Note</b></h4></label>
                                <JoditEditor
                                    value={formData.note1}
                                    config={editorConfig}
                                    onBlur={onObservationChange}
                                    onChange={(newContent) => {}}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label text-uppercase"><h4><b>Morning Shift Report</b></h4></label>
                                <JoditEditor
                                    value={formData.note2}
                                    config={editorConfig}
                                    onBlur={onMorningChange}
                                    onChange={(newContent) => {}}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label text-uppercase"><h4><b>Afternoon Shift Report</b></h4></label>
                                <JoditEditor
                                    value={formData.note3}
                                    config={editorConfig}
                                    onBlur={onAfternoonChange}
                                    onChange={(newContent) => {}}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label text-uppercase"><h4><b>Night Shift Report</b></h4></label>
                                <JoditEditor
                                    value={formData.note4}
                                    config={editorConfig}
                                    onBlur={onNightChange}
                                    onChange={(newContent) => {}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addNote}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(NurseNote);