import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import { connect } from "react-redux";
import Loader from "../../common/loader";
import { setGeneralDetails } from "../../../action/actions";
import PaymentDataTable from "../data-table/payment-data-table";

function MonthlyPaymentReport(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [showReport, setShowReport] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [formData, setFormData] = useState({ month_id: "" })

    const header = [
        "S/N",
        "Patient Name",
        "Patient ID",
        "payment Ref.",
        "Payment Purpose",
        "Payment Item",
        "Payment Method",
        "Amount Paid (₦)",
        "Payment Type"
    ];

    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getData(val)
    }

    useEffect(() => {
        setTimeout(()=>{
            setIsLoading(false)
        }, 2000)
    }, []);

    const getData = async (month) => {
        if (month !== ""){
            setShowReport(false)
            setIsLoading(true)
            await axios
                .get(`${serverLink}report/payment/monthly-payment?payment_date=${month}`, token)
                .then((result) => {
                    if (result.data.length > 0) {
                        setReportList(result.data);
                    } else {
                        setReportList([]);
                    }
                    setIsLoading(false);
                    setShowReport(true);
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }

    };

    const  getTotal = () => {
        let total_amount = 0;
        reportList.map((item, index) => {
            total_amount += item.amountPaid;
        });
        return total_amount;
    }

    const showTable = () => {
        try {
            return reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientName}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.paymentReference}</td>
                        <td>{item.paymentFor}</td>
                        <td>{item.itemName}</td>
                        <td>{item.paymentMethod}</td>
                        <td>{item.amountPaid}</td>
                        <td>{item.paymentType}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Monthly Payment Report"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="form-group">
                                        <label htmlFor="month_id">Select Year and Month </label>
                                        <input type="month" id="month_id"
                                               className="form-control"
                                               value={formData.month_id}
                                               onChange={onChange}
                                               max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                               required />
                                    </div>
                                    {
                                        showReport ? <div className="text-right"><h4><b>{`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}</b></h4></div> : <></>
                                    }
                                    {
                                        showReport ?
                                            <PaymentDataTable
                                                tableID="monthly-payment"
                                                header={header}
                                                date={"("+formatDateAndTime(formData.month_id, 'month_and_year')+")"}
                                                caption={`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`}
                                                body={showTable()}
                                                title={`Monthly Payment Report For ${formatDateAndTime(formData.month_id, 'month_and_year')}`} />
                                            : <></>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyPaymentReport);
