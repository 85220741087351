import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {Link} from "react-router-dom";
import { setGeneralDetails } from "../../../action/actions";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import AntenatalCareRegistrationForm from "./antenatal-care-registration-form";

function AntenatalList(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);

    const header = [
        "S/N",
        "Patient ID",
        "Name",
        "Phone Number",
        "Email Address",
        "Action",
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        await axios
            .get(`${serverLink}antenatal/list/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.patientName} </td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td className="d-flex">
                            <Link to={`/${props.access?.toLowerCase()}/antenatal-care/dashboard/${btoa(item.antenatalID)}/${btoa(item.patientSerial)}`}
                                  className={"btn btn-info btn-sm  mr-3"}
                            >
                                Dashboard
                            </Link>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const closeRegForm = () => {
        document.getElementById("closeRegistration").click();
        getData();
    }


    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Antenatal"]}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <h4 className="card-title">Antenatal List</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-info"
                                                    data-toggle="modal"
                                                    data-target="#responsive-modal"
                                                >
                                                    <i className="fa fa-plus"></i> Antenatal Registration
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        tableID="Patient"
                                        header={header}
                                        body={showTable()}
                                        title="Antenatal Report"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AntenatalCareRegistrationForm
                close="closeRegistration"
                title="Antenatal Registration Form"
                closeRegForm={closeRegForm}
            />

        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AntenatalList);
