import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {
    currencyConverter,
    formatDateAndTime,
    generate_token, getUserType,
    serverLink
} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";

import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import CaptureVitals from "../../patient/components/capture-vitals";
import Allergy from "../../patient/components/allergy";
import RequestService from "../../patient/components/service-request";
import PatientServices from "../../patient/utils/patient-services";
import PatientPayments from "../../patient/utils/patient-payments";
import PatientVitals from "../../patient/utils/patient-vitals";
import PatientAllergies from "../../patient/utils/patient-allergies";
import PatientAppointments from "../../patient/utils/patient-appointments";
import {PatientInfoCard} from "../../patient/utils/patient-info-card";
import DoctorNote from "../components/doctor-note";
import PatientDoctorNote from "../utils/patient-doctor-note";
import NurseNote from "../components/nurse-note";
import PatientNurseNote from "../utils/patient-nurse-note";
import PrescriptionRequest from "../components/prescription-request";
import PatientPrescriptionRequest from "../utils/patient-prescription-request";
import PatientClinicalTask from "../utils/patient-clinical-task";
import ClinicalTask from "../components/clinical-task";
import ImagingRequest from "../components/imaging-request";
import PatientImagingRequest from "../utils/patient-imaging-request";
import {toast} from "react-toastify";
import PainAssessment from "../components/pain-assessment";
import PatientPrescriptionAssessment from "../utils/patient-prescription-assessment";
import PrescriptionAssessment from "../components/prescription-assessment";
import PatientPainAssessment from "../utils/patient-pain-assessment";
import PatientAmountIncurred from "../utils/patient-amount-incurred";
import {useNavigate} from "react-router";
import MakePayment from "../../finance/choose-payment/choose-payment";
import PatientDiagnosis from "../utils/patient-diagnosis";
import ECTReactComponent from "../components/icd-diagnosis";
import {Link, useParams} from "react-router-dom";
import {FaBedPulse} from "react-icons/fa6";
import {setDashboardDetails, setLoginDetails, setPermissionDetails, setRoomDetails} from "../../../action/actions";
import LabTestRequest from "../components/lab-test-request";
import PatientLabRequest from "../utils/patient-lab-request";
import PatientVitalsChart from "../../patient/utils/patient-vitals-chart";
import Consultation from "../components/consultation";
import PatientDoctorConsultation from "../utils/patient-doctor-consultation";

function InpatientDashboard(props) {
    const token = props.loginData[0]?.token;
    const navigate = useNavigate()
    const {slug} = useParams();
    const admissionID = atob(slug);
    const userType = getUserType(window.location.href, 4);
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState({})
    const [vitalFormData, setVitalFormData] = useState({})
    const [doctorFormData, setDoctorFormData] = useState({})
    const [nurseNoteFormData, setNurseNoteFormData] = useState({})
    const [rebuildAppointment, setRebuildAppointment] = useState(null);
    const [rebuildVitals, setRebuildVitals] = useState(null);
    const [rebuildCheckIn, setRebuildCheckIn] = useState(null);
    const [rebuildAllergy, setRebuildAllergy] = useState(null);
    const [rebuildPayment, setRebuildPayment] = useState(null);
    const [rebuildTask, setRebuildTask] = useState(null);
    const [rebuildServiceRequest, setRebuildServiceRequest] = useState(null);
    const [rebuildDoctorNote, setRebuildDoctorNote] = useState(null);
    const [rebuildDoctorConsultation, setRebuildDoctorConsultation] = useState(null);
    const [rebuildNurseNote, setRebuildNurseNote] = useState(null);
    const [rebuildPrescription, setRebuildPrescription] = useState(null);
    const [rebuildLab, setRebuildLab] = useState(null);
    const [rebuildImagingRequest, setRebuildImagingRequest] = useState(null);
    const [rebuildPrescriptionAssessment, setRebuildPrescriptionAssessment] = useState(null);
    const [rebuildPainAssessment, setRebuildPainAssessment] = useState(null);
    const [totalAmountIncurred, setTotalAmountIncurred] = useState(0);
    const [rebuildDiagnosis, setRebuildDiagnosis] = useState(null);



    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
                await axios
                    .get(`${serverLink}admission/detail/${atob(slug)}`, token)
                    .then(async (res) => {
                        if (res.data.length > 0) {
                            const patient_id = res.data[0].patientSerial;
                            await axios.get(`${serverLink}patient/consultation/${patient_id}`, token)
                                .then(async (result) => {
                                    const patient = result.data;
                                    let amountIncurred = res.data[0].incurredAmount;
                                    if (res.data[0].admissionStatus !== 'Discharged'){
                                      const update_response = await axios.patch(`${serverLink}admission/update_incurred_amount`, {admissionID: res.data[0].admissionID}, token);
                                      amountIncurred = update_response.data.amountIncurred;
                                    }
                                    const item = {
                                        patientSerial: patient_id,
                                        walletAmount:patient[0].walletAmount,
                                        passport:patient[0].passport,
                                        firstName:patient[0].firstName,
                                        middleName:patient[0].middleName,
                                        surname:patient[0].surname,
                                        title:patient[0].title,
                                        emailAddress:patient[0].emailAddress,
                                        phoneNumber:patient[0].phoneNumber,
                                        altPhoneNumber:patient[0].altPhoneNumber,
                                        dateOfBirth:patient[0].dateOfBirth,
                                        bloodGroup:patient[0].bloodGroup,
                                        gender:patient[0].gender,
                                        status:patient[0].status,
                                        maritalStatus:patient[0].maritalStatus,
                                        nextOfKinName:patient[0].nextOfKinName,
                                        nextOfKinPhone:patient[0].nextOfKinPhone,
                                        nextOfKinRelationship:patient[0].nextOfKinRelationship,
                                        residentialAddress:patient[0].residentialAddress,
                                        lastVisit:patient[0].lastVisit,
                                        ...{...res.data[0], incurredBalance: amountIncurred}
                                    }
                                     setPatientDetails(item)
                                    setIsLoading(false);
                          }).catch((err) => {
                                    console.log("NETWORK ERROR");
                                });
                        }else{
                            navigate(`/${userType}/inpatient`)
                        }
                    })
                    .catch((err) => {
                        console.log("NETWORK ERROR");
                    });
    }

    const showStatus = (status) => {
        switch (status) {
            case 'Active':
                return <span className="badge badge-pill badge-info"><h3>{status}</h3></span>;
            case 'Discharged':
                return <span className="badge badge-pill badge-danger"><h3> {status}</h3></span>;
            default:
            return <span className="badge badge-pill badge-secondary"><h3>{status}</h3></span>;
        }
    };


    const discharge_patient = async (paymentData) => {
        toast.info("please wait...");
        const sendData = {
            admissionID: patientDetails.admissionID,
            itemID: patientDetails.admissionID,
            bedID: patientDetails.bedID,
            ...paymentData,
        }

        await axios.post(`${serverLink}payment/post-payment`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Patient Discharged Successfully");
                getData();
                setIsFormLoading(false);
                setRebuildPayment(generate_token(5));
                setRebuildPrescription(generate_token(5));
                setRebuildServiceRequest(generate_token(5));
                setRebuildImagingRequest(generate_token(5));
                document.getElementById("closePayment").click();
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong posting payment. Please try again!");
            }
            setIsLoading(false)
        })
    }

    const handleDischarge = async () => {
        await axios.get(`${serverLink}patient/appointment/amount-incurred/admission/list/${patientDetails.patientSerial}/${patientDetails.admissionID}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    const prescription = result.data.prescription.filter(drug=>drug.status !== "Cancelled" && drug.status !== "Paid").reduce((acc, service) => acc + service.drugTotalAmount, 0);
                    const imaging = result.data.imaging.filter(e => e.status !== "Cancelled" && e.paymentStatus !== 'Paid').reduce((acc, service) => acc + service.price, 0);
                    const lab = result.data.lab.filter(lab=> lab.paymentStatus !== 'Paid' && (lab.testStatus !== "Result Rejected" || lab.testStatus !== "Cancelled")).reduce((acc, service) => acc + service.testAmount, 0);
                    const room = result.data.room.reduce((acc, x) => acc + x.incurredAmount, 0);
                    //Set Total Due
                    setTotalAmountIncurred(prescription+imaging+lab+room)
                    document.getElementById('activate-payment').click();
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const showMenu = (access) => {
        switch (access) {
            case 'Doctor':
                return <>
                    <MenuCard title="Doctor Note" icon="ti-pencil"
                              size="col-md-3" color="info"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "doctor-note-modal"}
                              onClick={() => { setDoctorFormData({});  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');}}/>
                    <MenuCard title="Doctor Consultation" icon="ti-pencil"
                              size="col-md-3" color="info"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "doctor-consultation-modal"}
                              onClick={() => { setDoctorFormData({});  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');}}/>
                    <MenuCard title="Diagnosis (ICD-11)" icon="ti-search"
                              size="col-md-3" color="success"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "diagnosis-modal"}
                              onClick={() => { if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');}}/>
                    <MenuCard title="Prescription" icon="fa-prescription-bottle-alt"
                              size="col-md-3" color="cyan"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "prescription-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}/>
                    <MenuCard title="Imaging Request" icon="mdi mdi-microscope"
                              size="col-md-3"
                              color="cyan"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "imaging-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}/>
                    <MenuCard title="Lab Request" icon="fa fa-flask"
                              size="col-md-3"
                              color="primary"
                              modal={patientDetails.admissionStatus === 'Completed' ? 'blocked' : "lab-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Completed') toast.error('This admission is closed already');
                              }}/>
                    <MenuCard title="Clinical Task" icon="fa-notes-medical"
                              size="col-md-3"
                              color="orange"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "task-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}/>
                    <MenuCard title="Add Allergy" icon="fa-briefcase-medical"
                              size="col-md-3" color="orange"
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "allergy-modal"}/>

                    <MenuCard title="Service Request" icon="fa-cogs" size="col-md-3"
                              color="danger"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "service-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}/>
                    <MenuCard title="Pain Assessment" icon="fa-user"
                              size="col-md-3" color="orange"
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "pain-modal"}/>
                    <MenuCard title="Discharg Patient" icon="fa-check-circle"
                              size="col-md-3"
                              color="success"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "close-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') {
                                      toast.error('This admission is closed already')
                                  } else {
                                      showConfirm("Warning", `Are you sure you want to discharge this patient?`, "warning")
                                          .then(async (confirm) => {
                                              if (confirm) {
                                                  handleDischarge();
                                              }
                                          })
                                  }
                              }}/>
                </>;
            case 'Nurse':
                return <>
                    <MenuCard title="Nurse Note" icon="ti-pencil"
                              size="col-md-3" color="info"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "nurse-note-modal"}
                              onClick={() => setNurseNoteFormData({})}/>
                    <MenuCard title="Clinical Task" icon="fa-notes-medical"
                              size="col-md-3"
                              color="orange"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "task-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}/>

                    <MenuCard title="Capture Vitals" icon="fa-vials" size="col-md-3"
                              color="success"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "vital-modal"}
                              onClick={() => setVitalFormData({})}/>
                    <MenuCard title="Add Allergy" icon="fa-briefcase-medical"
                              size="col-md-3" color="orange"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "allergy-modal"}/>
                    <MenuCard title="Service Request" icon="fa-cogs" size="col-md-3"
                              color="danger"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "service-modal"}
                              onClick={() => {
                                  if (patientDetails.admissionStatus === 'Discharged') toast.error('This admission is closed already');
                              }}/>
                    <MenuCard title="Pain Assessment" icon="fa-user"
                              size="col-md-3" color="orange"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "pain-modal"}/>
                    <MenuCard title="Prescription Assessment" icon="fa-list"
                              size="col-md-3" color="cyan"
                              modal={patientDetails.admissionStatus === 'Discharged' ? 'blocked' : "prescription-assessment-modal"}/>
                </>;
            case 'Service-Desk':
                return <>

                </>;
            default:
                return <>

                </>;
        }
    };

    return (IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Admission Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Inpatient Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-diagnosis-tab" data-toggle="pill"
                                           href="#diagnosis"
                                           role="tab" aria-controls="pills-diagnosis"
                                           aria-selected="false">Diagnosis</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-doctor-note-tab" data-toggle="pill"
                                           href="#doctor-note"
                                           role="tab" aria-controls="pills-doctor-note"
                                           aria-selected="false">Doctor Note</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-doctor-consultation-tab" data-toggle="pill"
                                           href="#doctor-consultation"
                                           role="tab" aria-controls="pills-consultation-note"
                                           aria-selected="false">Doctor Consultation</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-nurse-note-tab" data-toggle="pill"
                                           href="#nurse-note"
                                           role="tab" aria-controls="pills-nurse-note"
                                           aria-selected="false">Nurse Note</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-allergies-tab" data-toggle="pill"
                                           href="#allergies" role="tab" aria-controls="pills-allergies"
                                           aria-selected="false">Allergies</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-vital-tab" data-toggle="pill" href="#vital-chart"
                                           role="tab" aria-controls="pills-vital-chart" aria-selected="false">Vitals Chart</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-vital-tab" data-toggle="pill" href="#vital"
                                           role="tab" aria-controls="pills-vital" aria-selected="false">Vitals
                                            Report</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-laboratory-tab" data-toggle="pill"
                                           href="#laboratory" role="tab" aria-controls="pills-laboratory"
                                           aria-selected="false">Laboratory</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-prescription-tab" data-toggle="pill"
                                           href="#prescription" role="tab" aria-controls="pills-prescription"
                                           aria-selected="false">Prescription</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-nurse-assessment-tab" data-toggle="pill"
                                           href="#nurse-assessment" role="tab" aria-controls="pills-nurse-assessment"
                                           aria-selected="false">Nurse Assessment</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-pain-assessment-tab" data-toggle="pill"
                                           href="#pain-assessment" role="tab" aria-controls="pills-pain-assessment"
                                           aria-selected="false">Pain Assessment</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-imaging-tab" data-toggle="pill"
                                           href="#imaging" role="tab" aria-controls="pills-imaging"
                                           aria-selected="false">Imaging</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-task-tab" data-toggle="pill"
                                           href="#task" role="tab" aria-controls="pills-task"
                                           aria-selected="false">Clinical Task</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-amount-incurred-tab" data-toggle="pill"
                                           href="#amount-incurred" role="tab" aria-controls="pills-amount-incurred"
                                           aria-selected="false">Amount Incurred</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-payments-tab" data-toggle="pill"
                                           href="#payments"
                                           role="tab" aria-controls="pills-payments" aria-selected="false">Payments</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-services-tab" data-toggle="pill"
                                           href="#services"
                                           role="tab" aria-controls="pills-services" aria-selected="false">Services</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCard patientDetails={patientDetails}/>
                                                <div className="col-md-8">
                                                    {/*<h4 className="card-title m-t-5">Menu</h4>*/}
                                                    <div className="row">
                                                        <div className="col-md-12 bg-white mb-3">
                                                            <hr style={{margin: '0px', padding: '0px'}}/>
                                                            <div className="card-body">
                                                                <h4><b>Admission Details</b></h4>
                                                                <hr/>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <span className="text-muted p-t-30 db">Block Name</span>
                                                                        <h5>{patientDetails?.blockName}</h5>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <span
                                                                            className="text-muted p-t-30 db">Room Name</span>
                                                                        <h5>{patientDetails?.roomName}</h5>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <span
                                                                            className="text-muted p-t-30 db">Bed Name</span>
                                                                        <h5>{patientDetails?.bedName}</h5>
                                                                    </div>
                                                                    <div className="col-md-4 mt-3">
                                                                        <span className="text-muted p-t-30 db">Admitted On</span>
                                                                        <h5>{formatDateAndTime(patientDetails?.admittedOn, 'date')}</h5>
                                                                    </div>
                                                                    <div className="col-md-4 mt-3">
                                                                        <span className="text-muted p-t-30 db">Days Admitted</span>
                                                                        <h5>{patientDetails?.incurredAmount / patientDetails?.roomAmount} {patientDetails?.incurredAmount / patientDetails?.roomAmount > 1 ? 'Days' : 'Day'}</h5>
                                                                    </div>
                                                                    <div className="col-md-4 mt-3">
                                                                        <span className="text-muted p-t-30 db">Admission Status</span>
                                                                        <h5 className={patientDetails.admissionStatus === 'Active' ? 'text-success font-weight-bold' : 'text-danger font-weight-bold'}>{patientDetails.admissionStatus}</h5>
                                                                    </div>
                                                                    <div className="col-md-4 mt-3">
                                                                        <span
                                                                            className="text-muted p-t-30 db">Amount</span>
                                                                        <h4 className={'text-danger font-weight-bold'}>{currencyConverter(patientDetails.incurredBalance)}</h4>
                                                                    </div>
                                                                    <div className="col-md-4 mt-3">
                                                                        <span className="text-muted p-t-30 db">Change Patient Room</span>
                                                                        <Link
                                                                            to={`/admission/change-block/${btoa(patientDetails.patientSerial)}`}
                                                                            className={"btn btn-primary btn-sm"}
                                                                            onClick={() => {
                                                                                props.setOnRoomDetails(
                                                                                    {
                                                                                        ...patientDetails,
                                                                                        redirect: window.location.href
                                                                                    }
                                                                                )
                                                                            }}
                                                                        >
                                                                            Change Room/Bed
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {showMenu(props.access)}
                                                        <div className="col-md-12 mt-5">
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <h3><b>Admission
                                                                        Status:</b> {showStatus(patientDetails.admissionStatus)}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="last-month" role="tabpanel"
                                         aria-labelledby="pills-profile-tab">
                                        <div className="card-body">
                                            <PatientAppointments patientSerial={patientDetails.patientSerial}
                                                                 handleRemount={rebuildAppointment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="doctor-note" role="tabpanel"
                                         aria-labelledby="pills-doctor-note-tab">
                                        <div className="card-body">
                                            <PatientDoctorNote appointmentID={admissionID}
                                                               handleRemount={rebuildDoctorNote}
                                                               serviceName={"Admission"}
                                                               setDoctorFormData={setDoctorFormData}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="doctor-consultation" role="tabpanel"
                                         aria-labelledby="pills-doctor-consultation-tab">
                                        <div className="card-body">
                                            <PatientDoctorConsultation appointmentID={admissionID}
                                                               handleRemount={rebuildDoctorConsultation}
                                                               serviceName={"Admission"}
                                                               setDoctorFormData={setDoctorFormData}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="diagnosis" role="tabpanel"
                                         aria-labelledby="pills-diagnosis-tab">
                                        <div className="card-body">
                                            <PatientDiagnosis patientSerial={patientDetails.patientSerial}
                                                              serviceID={admissionID} serviceName={"Admission"}
                                                              handleRemount={rebuildDiagnosis}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nurse-note" role="tabpanel"
                                         aria-labelledby="pills-nurse-note-tab">
                                        <div className="card-body">
                                            <PatientNurseNote serviceID={admissionID} serviceName={"Admission"}
                                                              handleRemount={rebuildNurseNote}
                                                              setNurseNoteFormData={setNurseNoteFormData}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="prescription" role="tabpanel"
                                         aria-labelledby="pills-prescription-tab">
                                        <div className="card-body">
                                            <PatientPrescriptionRequest patientSerial={patientDetails.patientSerial}
                                                                        serviceID={admissionID}
                                                                        serviceName={"Admission"}
                                                                        handleRemount={rebuildPrescription}
                                                                        access={props.access}
                                                                        setNurseNoteFormData={setNurseNoteFormData}/>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="laboratory" role="tabpanel"
                                         aria-labelledby="pills-laboratory-tab">
                                        <div className="card-body">
                                            <PatientLabRequest patientSerial={patientDetails.patientSerial}
                                                               serviceID={admissionID} serviceName={"Admission"}
                                                               handleRemount={rebuildLab}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="imaging" role="tabpanel"
                                         aria-labelledby="pills-imaging-tab">
                                        <div className="card-body">
                                            <PatientImagingRequest patientSerial={patientDetails.patientSerial}
                                                                   serviceID={admissionID} serviceName={"Admission"}
                                                                   handleRemount={rebuildImagingRequest}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="task" role="tabpanel"
                                         aria-labelledby="pills-task-tab">
                                        <div className="card-body">
                                            <PatientClinicalTask patientSerial={patientDetails.patientSerial}
                                                                 serviceID={admissionID} serviceName={"Admission"}
                                                                 handleRemount={rebuildTask}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="allergies" role="tabpanel"
                                         aria-labelledby="pills-allergies-tab">
                                        <div className="card-body">
                                            <PatientAllergies patientSerial={patientDetails.patientSerial}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="vital-chart" role="tabpanel"
                                         aria-labelledby="pills-vital-chart-tab">
                                        <div className="card-body">
                                            <PatientVitalsChart patientSerial={patientDetails.patientSerial}
                                                                setVitalFormData={setVitalFormData}
                                                                serviceID={admissionID}
                                                                serviceName={"Admission"}
                                                                handleRemount={rebuildVitals}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="vital" role="tabpanel"
                                         aria-labelledby="pills-vital-tab">
                                        <div className="card-body">
                                            <PatientVitals patientSerial={patientDetails.patientSerial}
                                                           serviceID={admissionID} serviceName={"Admission"}
                                                           setVitalFormData={setVitalFormData}
                                                           handleRemount={rebuildVitals}/>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="payments" role="tabpanel"
                                         aria-labelledby="pills-payments-tab">
                                        <div className="card-body">
                                            <PatientPayments patientSerial={patientDetails.patientSerial}
                                                             handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="services" role="tabpanel"
                                         aria-labelledby="pills-services-tab">
                                        <div className="card-body">
                                            <PatientServices patientSerial={patientDetails.patientSerial}
                                                             handleRemount={rebuildServiceRequest}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nurse-assessment" role="tabpanel"
                                         aria-labelledby="pills-nurse-assessment-tab">
                                        <div className="card-body">
                                            <PatientPrescriptionAssessment patientSerial={patientDetails.patientSerial}
                                                                           serviceID={admissionID}
                                                                           serviceName={"Admission"}
                                                                           handleRemount={rebuildPrescriptionAssessment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pain-assessment" role="tabpanel"
                                         aria-labelledby="pills-pain-assessment-tab">
                                        <div className="card-body">
                                            <PatientPainAssessment patientSerial={patientDetails.patientSerial}
                                                                   serviceID={admissionID} serviceName={"Admission"}
                                                                   handleRemount={rebuildPainAssessment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="amount-incurred" role="tabpanel"
                                         aria-labelledby="pills-amount-incurred-tab">
                                        <div className="card-body">
                                            <PatientAmountIncurred patientSerial={patientDetails.patientSerial}
                                                                   serviceID={admissionID} serviceName={"Admission"}
                                                                   handleRemount={rebuildPainAssessment}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DoctorNote
                    id="doctor-note-modal"
                    title="Doctor Note"
                    close="closeDoctorNote"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildDoctorNote={setRebuildDoctorNote}
                    doctorFormData={doctorFormData}
                />

                <Consultation
                    id="doctor-consultation-modal"
                    title="Doctor Consultation"
                    close="closeDoctorConsultation"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildDoctorNote={setRebuildDoctorConsultation}
                    doctorFormData={doctorFormData}
                />
                <NurseNote
                    id="nurse-note-modal"
                    title="Nurse Note"
                    close="closeNurseNote"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildNurseNote={setRebuildNurseNote}
                    nurseNoteFormData={nurseNoteFormData}
                />
                <PrescriptionRequest
                    id="prescription-modal"
                    title="Prescription"
                    close="closePrescription"
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildPrescription={setRebuildPrescription}
                />

                <ClinicalTask
                    id="task-modal"
                    title="Add Clinical Task"
                    close="closeTask"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    setRebuildTask={setRebuildTask}
                />

                <LabTestRequest
                    id="lab-modal"
                    title="Lab Test Rquest"
                    close="closeLab"
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    patientEmail={patientDetails.emailAddress}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildLab={setRebuildLab}
                />
                <ImagingRequest
                    id="imaging-modal"
                    title="Imaging Request"
                    close="closeImagingRequest"
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildImagingRequest={setRebuildImagingRequest}
                />
                <CaptureVitals
                    id="vital-modal"
                    title="Capture Vital"
                    close="closeVital"
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setVitalFormData={setVitalFormData}
                    vitalFormData={vitalFormData}
                    handleRemount={rebuildCheckIn}
                    setRebuildVitals={setRebuildVitals}
                />
                <Allergy
                    id="allergy-modal"
                    title="Add Allergy"
                    close="closeAllergy"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildAllergy={setRebuildAllergy}
                />
                <RequestService
                    id="service-modal"
                    title="Request Service"
                    close="closeServiceRequest"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildPayment={setRebuildPayment}
                    setRebuildServiceRequest={setRebuildServiceRequest}
                />
                <PainAssessment
                    id="pain-modal"
                    title="Pain Assessment"
                    close="closePain"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    setRebuildPainAssessment={setRebuildPainAssessment}
                />
                <PrescriptionAssessment
                    id="prescription-assessment-modal"
                    title="Prescription Assessment"
                    close="closePrescriptionAssessment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    setRebuildPrescriptionAssessment={setRebuildPrescriptionAssessment}
                />
                <button data-toggle="modal" style={{display: 'none'}} id="activate-payment" data-target={`#clear-payment-modal`}>Open</button>
                <MakePayment
                    id="clear-payment-modal"
                    title="Make Payment"
                    close="closePayment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    paymentFor="Admission Discharge"
                    itemName={'Discharge'}
                    itemAmount={totalAmountIncurred}
                    paymentType="Debit"
                    IsFormLoading={IsFormLoading}
                    onPostPayment={discharge_patient}
                    onError={()=>{console.log("Error")}}
                />
                <ECTReactComponent
                    id="diagnosis-modal"
                    title="Diagnosis (ICD-11)"
                    close="closeDiagnosis"
                    serviceID={admissionID}
                    serviceName={"Admission"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    patientEmail={patientDetails.emailAddress}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildDiagnosis={setRebuildDiagnosis}
                />
            </>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        setOnRoomDetails: (p) => {
            dispatch(setRoomDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.AdmissionDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InpatientDashboard);

