import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";

const PatientOtherInfo = ({patientSerial, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [patientInfo, setPatientInfo] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        await axios.get(`${serverLink}patient/other-info/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientInfo(result.data);
                }else {
                    setPatientInfo([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    return (IsLoading ? <Loader/> :
            patientInfo.length > 0 ?
        <div className="">
            <div className="table-responsive">
                <h4 className="font-medium font-weight-bold text-cyan m-t-30">Family History</h4>
                <hr/>
                <div className="row">
                <div className="col-md-4 col-xs-6 b-r"><strong>Family Type</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].familyType}</p>
                    </div>
                    <div className="col-md-4 col-xs-6 b-r"><strong>No. of Children Family Have</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].parentChildren}</p>
                    </div>
                    <div className="col-md-4 col-xs-6 b-r"><strong>	Position In The Family</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].positionInFamily}</p>
                    </div>
                </div>
                <h4 className="font-medium font-weight-bold text-cyan m-t-30">Social History</h4>
                <hr/>
                <div className="row">
                    <div className="col-md-3 col-xs-6 b-r"><strong>Activities</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].activities}</p>
                    </div>
                    <div className="col-md-3 col-xs-6 b-r"><strong>Exercise</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].exercise}</p>
                    </div>
                    <div className="col-md-3 col-xs-6 b-r"><strong>Life Style</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].lifeStyle}</p>
                    </div>
                    <div className="col-md-3 col-xs-6"><strong>Leisure Time</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].leisureTime}</p>
                    </div>

                    <div className="col-md-3 col-xs-6 b-r"><strong>Smoking</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].smoking}</p>
                    </div>
                    <div className="col-md-3 col-xs-6 b-r"><strong>Alcohol</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].alcohol}</p>
                    </div>
                    <div className="col-md-3 col-xs-6 b-r"><strong>Diet</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].diet}</p>
                    </div>
                    <div className="col-md-3 col-xs-6"><strong>Hobbies</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].hobbies}</p>
                    </div>
                    <div className="col-md-3 col-xs-6"><strong>Education Level
                    </strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].educationLevel}</p>
                    </div>
                </div>
                <h4 className="font-medium font-weight-bold text-cyan m-t-30">Previous Medical History</h4>
                <hr/>
                <div className="row">
                    <div className="col-md-6 col-xs-6 b-r"><strong>Have you had any medical condition before?</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].medicalCondition}</p>
                    </div>
                    <div className="col-md-6 col-xs-6 b-r"><strong>Have you ever been admitted in the hospital?</strong>
                        <br/>
                        <p className="text-muted">{patientInfo[0].previousAdmission}</p>
                    </div>

                </div>
            </div>
</div> :
                <div className="alert alert-danger text-center p-3"><h3>No Record Found!</h3></div>
)
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientOtherInfo);

