import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useState} from "react";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {FaNairaSign} from "react-icons/fa6";
import {Link} from "react-router-dom";
import MenuRoute from "../../common/card/menu-route";
import {toast} from "react-toastify";
import MenuIcon from "../utils/menu-icon";

function PaymentReportDashboard(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)


    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Payment Report Dashboard"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <MenuIcon title="Daily Income" icon={<FaNairaSign size={30}/>} size="col-md-6"
                                                   color="#424f57"
                                                   url={`/report/daily-income`} />
                                        <MenuIcon title="Monthly Income" icon={<FaNairaSign size={30}/>} size="col-md-6"
                                                   color="#424f57"
                                                   url={`/report/monthly-income`} />
                                        <MenuIcon title="Receipts Voucher" icon={<FaNairaSign size={30}/>} size="col-md-6"
                                                   color="#424f57"
                                                   url={`/report/receipts-voucher`} />
                                        <MenuIcon title="Shifts Summary" icon={<FaNairaSign size={30}/>} size="col-md-6"
                                                   color="#424f57"
                                                   url={`/report/shift-summary`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PaymentReportDashboard);

