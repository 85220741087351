import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {encryptData, formatAMPM, formatDate, formatDateAndTime, serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {Link} from "react-router-dom";
import { setGeneralDetails } from "../../../action/actions";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import CountryData from "../../resources/country.json";
import ManagePatientForm from "../../patient/manage-patient/manage-patient-form";
import WorkingServiceForm from "./working-service-form";

function WorkingServiceList(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [IsFormLoading, setIsFormLoading] = useState(false);
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);
    const [patientList, setPatientList] = useState([]);
    const initialState = {
        patientID: "",
        title: "",
        firstName: "",
        middleName: "",
        surname: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    };
    const [formData, setFormData] = useState(initialState);

    const resetItem = () => {
        setFormData(initialState);
    };

    const header = [
        "S/N",
        "Patient ID",
        "Name",
        "Gender",
        "Phone Number",
        "Email Address",
        "Action",
        "Delete",
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, []);

    const getData = async () => {
        await axios
            .get(`${serverLink}working-service/list/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };


    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios
            .delete(`${serverLink}working-service/delete/${id}`, token)
            .then((res) => {
                if (res.data.message === "success") {
                    toast.success("Deleted Successfully");
                    getData();
                } else {
                    toast.error("NETWORK ERROR. Please try again!");
                }
            })
            .catch((err) => {
                console.log(err);
                toast.error("NETWORK ERROR. Please try again!");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.firstName} {item.middleName} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td className="d-flex">
                            <Link to={`/${props.access?.toLowerCase()}/working-service/dashboard/${btoa(item.patientSerial)}`}
                                  className={"btn btn-info btn-sm  mr-3"}
                            >
                                Dashboard
                            </Link>
                            <a href="#"
                               className={"btn btn-primary btn-sm"}
                               data-toggle="modal"
                               data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       patientID: item.patientID,
                                       title: item.title,
                                       firstName: item.firstName,
                                       middleName: item.middleName,
                                       surname: item.surname,
                                       emailAddress: item.emailAddress,
                                       phoneNumber: item.phoneNumber,
                                       gender: item.gender,
                                   });
                               }}
                            >
                                Edit
                            </a>
                        </td>
                        <td>
                            <a
                                href="#"
                                className={"btn btn-danger btn-sm "}
                                onClick={() =>
                                    showConfirm(
                                        "Warning",
                                        `Are you sure you want to delete this patient?`,
                                        "warning"
                                    ).then(async (confirm) => {
                                        if (confirm) {
                                            await delete_item(item.patientID);
                                        }
                                    })
                                }
                            >
                                Delete
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const onSubmit = async () => {
        if(formData.title === "") {
            showAlert("Empty Field", "Please Select the Patient Title", "error");
            return false;
        }

        if(formData.firstName === "") {
            showAlert("Empty Field", "Please Select the Patient First Name", "error");
            return false;
        }

        if(formData.surname === "") {
            showAlert("Empty Field", "Please Enter the Patient Surname", "error");
            return false;
        }

        if(formData.phoneNumber === "") {
            showAlert("Empty Field", "Please Enter the Patient Phone Number", "error");
            return false;
        }

        if(formData.gender === "") {
            showAlert("Empty Field", "Please Select the Patient Gender", "error");
            return false;
        }

        const sendData = {
            ...formData,
        }

        if (formData.patientID === "") {
            axios
                .post(`${serverLink}working-service/add`, sendData, token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        toast.success("Patient Added Successfully");
                        setIsFormLoading(false);
                        await getData();
                        document.getElementById("close").click();
                        resetItem();
                    }
                    if (res.data.message === "exist") {
                        await showAlert(
                            "Error",
                            `Patient with given details already exist!`,
                            "error"
                        );
                        setIsSubmittingForm(false);
                    }
                })
                .catch((error) => {
                    console.log("Error", error);
                });
        }else {
            await axios
                .patch(`${serverLink}working-service/update`, sendData, token)
                .then(async (result) => {
                    if (result.data.message === "success") {
                        toast.success("Patient Updated Successfully");
                        setIsFormLoading(false);
                        await getData();
                        setIsSubmittingForm(false);
                        document.getElementById("close").click();
                        resetItem();
                    } else {
                        await showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                        setIsSubmittingForm(false);
                    }
                })
                .catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                    setIsSubmittingForm(false);
                });
        }
    };

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Working Service"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <h4 className="card-title">Working Service List</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-info"
                                                    data-toggle="modal"
                                                    data-target="#responsive-modal"
                                                    onClick={resetItem}
                                                >
                                                    <i className="fa fa-plus"></i> Add User
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        tableID="Patient"
                                        header={header}
                                        body={showTable()}
                                        title="Working Service Report"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WorkingServiceForm
                title="Working Service Form"
                formData={formData}
                setFormData={setFormData}
                onEdit={onEdit}
                onSubmit={onSubmit}
                IsFormLoading={IsFormLoading}
            />
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkingServiceList);
