import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

const PatientCertificates = ({patientSerial, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [birthCert, setBirthCert] = useState([])
    const [deathCert, setDeathCert] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        await axios.get(`${serverLink}patient/certificate/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setBirthCert(result.data.birth);
                    setDeathCert(result.data.death);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const delete_birth = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}patient/birth-certificate/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const delete_death = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}patient/death-certificate/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }


    return (IsLoading ? <Loader/> :
            <div className="">
                        <div className="table-responsive">
                            <h3>Birth Certificate</h3>
                            <table className="table table-striped mb-3">
                                <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Baby Name</th>
                                    <th>Mother Name</th>
                                    <th>Father Name</th>
                                    <th>Date Of Birth</th>
                                    <th>Time Of Birth</th>
                                    <th>Place Of Birth</th>
                                    <th>Weight At Birth</th>
                                    <th>Delivered By</th>
                                    <th>File</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                {birthCert.length === 0 ?
                                    (
                                        <tbody>
                                        <tr>
                                            <td colSpan={11} className="text-center">No Record Found</td>
                                        </tr>
                                        </tbody>
                                    )
                                    :
                                    (
                                        <tbody>
                                        {birthCert.map((r, count) => {
                                            return (
                                                <tr key={count}>
                                                    <td>{count+1}</td>
                                                    <td>{r.babyName}</td>
                                                    <td>{r.mother}</td>
                                                    <td>{r.father}</td>
                                                    <td>{formatDateAndTime(r.dateOfBirth, "date")}</td>
                                                    <td>{r.timeOfBirth}</td>
                                                    <td>{r.placeOfBirth}</td>
                                                    <td>{r.weightAtBirth} kg</td>
                                                    <td>{r.deliveredBy}</td>
                                                    <td className="align-middle">
                                                        {r.certificateFile === "" ? (
                                                            <div className="userbg">
                                                                <i className="fa fa-file-excel fa-2x"/>
                                                            </div>
                                                        ) : (r.certificateFile ?
                                                                <a className="btn btn-primary btn-sm"
                                                                   href={serverLink + 'public/uploads/birth_certificate_uploads/' + r.certificateFile}>
                                                                    View Certificate
                                                                </a>
                                                                : "--"
                                                        )}
                                                    </td>
                                                    <td><i
                                                        onClick={() => {
                                                            showConfirm("Warning", `Are you sure you want to delete this record?`, "warning")
                                                                .then( async (confirm) => {
                                                                    if (confirm) {
                                                                        delete_birth(r.birthCertificateID)
                                                                    }
                                                                })
                                                        }}
                                                   className="fa fa-trash text-danger"/></td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    )
                                }
                            </table>
                        </div>

                <div className="table-responsive m-t-5">
                    <h3>Death Certificate</h3>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Deceased Name</th>
                            <th>Date Of Death</th>
                            <th>Cause Of Death</th>
                            <th>Place Of Deceased</th>
                            <th>Pronounced By</th>
                            <th>Certificate File</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        {deathCert.length === 0 ?
                            (
                                <tbody>
                                <tr>
                                    <td colSpan={9} className="text-center">No Record Found</td>
                                </tr>
                                </tbody>
                            )
                            :
                            (
                                <tbody>
                                {deathCert.map((r, count) => {
                                    return (
                                        <tr key={count}>
                                            <td>{count+1}</td>
                                            <td>{r.deceasedName}</td>
                                            <td>{formatDateAndTime(r.dateOfDeath, "date")}</td>
                                            <td>{r.causeOfDeath}</td>
                                            <td>{r.placeOfDeceased}</td>
                                            <td>{r.pronouncedBy}</td>
                                            <td className="align-middle">
                                                {r.certificateFile === "" ? (
                                                    <div className="userbg">
                                                        <i className="fa fa-file-excel fa-2x"/>
                                                    </div>
                                                ) : (r.certificateFile ?
                                                        <a className="btn btn-primary btn-sm"
                                                           href={serverLink + 'public/uploads/death_certificate_uploads/' + r.certificateFile}>
                                                            View Certificate
                                                        </a>
                                                        : "--"
                                                )}
                                            </td>
                                            <td><i onClick={() => {
                                                showConfirm("Warning", `Are you sure you want to delete this record?`, "warning")
                                                    .then( async (confirm) => {
                                                        if (confirm) {
                                                            delete_death(r.deathCertificateID)
                                                        }
                                                    })
                                            }}
                                                   className="fa fa-trash text-danger"/></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            )
                        }
                    </table>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientCertificates);

