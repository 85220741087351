import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

const PatientReferral = ({patientSerial, serviceID, serviceName, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const doctor = loginData[0]?.userID;
    const [IsLoading, setIsLoading] = useState(true)
    const [referralList, setReferralList] = useState([])
    const header = ["S/N", "Doctor", "Note", "Status", "Referred By", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/appointment/referral/${serviceID}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setReferralList(result.data);
                }else {
                    setReferralList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const showTable = () => {
        try {
            if (referralList.length > 0) {
                const rows = [];
                if (referralList[0].referralDoctor1 !== null) {
                    rows.push(
                        <tr key={1}>
                            <td>{1}</td>
                            <td>{referralList[0].referral1}</td>
                            <td>{!referralList[0].referralNote1 ? "N/A" : referralList[0].referralNote1}</td>
                            <td>{referralList[0].referralStatus1 === 'Approved' ? <span className="badge badge-pill badge-success font-weight-bold">{referralList[0].referralStatus1}</span> : referralList[0].referralStatus1 === 'Rejected' ? <span className="badge badge-pill badge-danger font-weight-bold">{referralList[0].referralStatus1}</span> : <span className="badge badge-pill badge-info font-weight-bold">{referralList[0].referralStatus1}</span>}</td>
                            <td>{referralList[0].referralByName1}</td>
                            <td>
                                {
                                    referralList[0].referralDoctor1 === doctor  ?
                                        referralList[0].referralStatus1 !== 'Pending' ? <>--</> :
                                            <div className="d-flex">
                                                <a href="#" className={"btn btn-info btn-sm mr-2"}
                                                   onClick={() => {
                                                       showConfirm("Warning", `Are you sure you want to accept this referral?`, "warning")
                                                           .then(async (confirm) => {
                                                               if (confirm) {
                                                                   onAction('Approved', serviceID, '1')
                                                               }
                                                           })
                                                   }}>
                                                    Approve
                                                </a>
                                                <a href="#" className={"btn btn-danger btn-sm "}
                                                   onClick={() => {
                                                       showConfirm("Warning", `Are you sure you want to reject this referral?`, "warning")
                                                           .then(async (confirm) => {
                                                               if (confirm) {
                                                                   onAction('Rejected', serviceID, '1')
                                                               }
                                                           })
                                                   }}>
                                                    Reject
                                                </a>
                                            </div>
                                        :
                                        <>--</>
                                }
                            </td>
                        </tr>
                    );
                }
                if (referralList[0].referralDoctor2 !== null) {
                    rows.push(
                        <tr key={2}>
                            <td>{2}</td>
                            <td>{referralList[0].referral2}</td>
                            <td>{!referralList[0].referralNote2 ? "N/A" : referralList[0].referralNote2}</td>
                            <td>{referralList[0].referralStatus2 === 'Approved' ? <span className="badge badge-pill badge-success font-weight-bold">{referralList[0].referralStatus2}</span> : referralList[0].referralStatus2 === 'Rejected' ? <span className="badge badge-pill badge-danger font-weight-bold">{referralList[0].referralStatus2}</span> : <span className="badge badge-pill badge-info font-weight-bold">{referralList[0].referralStatus2}</span>}</td>
                            <td>{referralList[0].referralByName2}</td>
                            <td>
                                {
                                    referralList[0].referralDoctor2 === doctor ?
                                        referralList[0].referralStatus2 !== 'Pending' ? <>--</> :
                                            <div className="d-flex">
                                                <a href="#" className={"btn btn-info btn-sm mr-2"}
                                                   onClick={() => {
                                                       showConfirm("Warning", `Are you sure you want to accept this referral?`, "warning")
                                                           .then(async (confirm) => {
                                                               if (confirm) {
                                                                   onAction('Approved', serviceID, '2')
                                                               }
                                                           })
                                                   }}>
                                                    Approve
                                                </a>
                                                <a href="#" className={"btn btn-danger btn-sm "}
                                                   onClick={() => {
                                                       showConfirm("Warning", `Are you sure you want to reject this referral?`, "warning")
                                                           .then(async (confirm) => {
                                                               if (confirm) {
                                                                   onAction('Rejected', serviceID, '2')
                                                               }
                                                           })
                                                   }}>
                                                    Reject
                                                </a>
                                            </div> : <>--</>
                                }
                            </td>
                        </tr>
                    );
                }
                return rows;
            }
        } catch (e) {
            console.error(e);
            alert(e.message);
        }
    };


    const onAction = async (status, appointment, referralType) => {
        toast.info("please wait...");
        const sendData = {
            appointmentID: appointment,
            referralStatus: status,
            referralType: referralType,
            submittedBy: loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}patient/appointment/referral/action`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success(`Referral ${status} Successfully`);
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Referrals {doctor}</h5>
                    <DataTable tableID="patient-referral" header={header} body={showTable()} title="Patient Referrals Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientReferral);

