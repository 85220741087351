import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

const PatientPreviousHospitalRecord = ({patientSerial, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [reportList, setReportList] = useState([])
    const header = ["S/N", "Title", "Description", "Date Added",  "File", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/previous-hospital-record/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setReportList(result.data);
                }else {
                    setReportList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return reportList.length > 0 &&  reportList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{formatDateAndTime(item.submittedOn, 'date')}</td>
                        <td><img src={`${serverLink}public/uploads/previous_hospital_record_uploads/${item.file}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td>
                            <a href="#" className={"btn btn-danger btn-sm "} data-toggle="modal"
                               data-target="#delete-modal"
                               onClick={() => {
                                   showConfirm("Warning", `Are you sure you want to delete this record?`, "warning")
                                       .then( async (confirm) => {
                                           if (confirm) {
                                               delete_item(item.medicalRecordID)
                                           }
                                       })
                               }}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}patient/previous-hospital-record/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }


    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Previous Hospital Record</h5>
                    <DataTable tableID="patient-previous-record" header={header} body={showTable()} title="Patient Previous Hospital Record"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientPreviousHospitalRecord);

