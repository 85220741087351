import React from "react";

export default function SubMenuForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Main Menu</label>
                                <select className="form-control" name="MainMenuID" id="MainMenuID"
                                        value={props.formData.MainMenuID} onChange={props.onEdit}>
                                    <option value="">Select Main Menu</option>
                                    {
                                        props.menuList.length > 0 && props.menuList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.EntryID}>{item.MenuName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Sub Menu Name</label>
                                <input
                                    type="text"
                                    name="SubMenuName"
                                    className="form-control"
                                    id="SubMenuName"
                                    value={props.formData.SubMenuName}
                                    onChange={props.onEdit}
                                    placeholder="Sub Menu Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Sub Menu Link</label>
                                <input
                                    type="text"
                                    name="SubMenuLink"
                                    className="form-control"
                                    id="SubMenuLink"
                                    value={props.formData.SubMenuLink}
                                    onChange={props.onEdit}
                                    placeholder="Sub Menu Link"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Visibility</label>
                                <select className="form-control" name="Visibility" id="Visibility"
                                        value={props.formData.Visibility} onChange={props.onEdit}>
                                    <option value="">Select Visibility</option>
                                    <option value="1">Show</option>
                                    <option value="2">Hide</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}