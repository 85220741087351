import React, {useEffect, useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import Select from "react-select";
function Referral(props) {
    const { patientSerial, patientName, serviceID, serviceName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [doctor, setDoctor] = useState([])

    const Initial = {
        appointmentID: serviceID,
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        doctorID: '',
        note: '',
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}doctor/schedule/doctor/active`, token)
            .then((result) => {
                let doctors = [];
                result.data.map((row) => {
                    doctors.push({value: row.userID, doctor_name: row.doctorName , label: row.doctorName +' => ('+ row.userID+')', phone_number: row.phoneNumber, email_address: row.emailAddress})
                });

                setDoctor(doctors);
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }


    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const onReferPatient = async () => {
        if (formData.doctorID.toString().trim() === ''){
            toast.error('Please select doctor')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
        }

        await axios.patch(`${serverLink}patient/appointment/referral/add`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Referral Successful");
                setIsFormLoading(false);
                props.setRebuildReferral(generate_token(5));
                document.getElementById("closeReferral").click();
                resetFormData()
            } else if (result.data.message === "finished") {
                setIsFormLoading(false);
                toast.error("Patient have exceeded his referral limit");
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding referral. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const onChangeStaff = (e) => {
        const val = e.value;
        setFormData({
            ...formData,
            doctorID: val,
            doctorName: e.doctor_name,
            doctorEmail: e.email_address,
            doctorID2: e,
        })
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Doctor</label>
                                <Select
                                    id="doctorID"
                                    name="doctorID"
                                    value={formData.doctorID2}
                                    onChange={onChangeStaff}
                                    options={doctor}
                                    placeholder="Select Doctor"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Referral Note</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="note"
                                    className="form-control"
                                    id="note"
                                    value={formData.note}
                                    onChange={onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={onReferPatient}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(Referral);