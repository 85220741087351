import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {projectAddress, projectEmail, projectName, projectPatientURL} from "../../resources/constants";
import UpdateAppointment from "../components/update-appointment";

    const PatientAppointments = ({patientSerial, loginData,  handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [patientDetails, setPatientDetails] = useState({})
    const [appointmentList, setAppointmentList] = useState([])
    const [rebuildAppointment, setRebuildAppointment] = useState(null);
    const [rebuildCheckIn, setRebuildCheckIn] = useState(null);
    const [rebuildPayment, setRebuildPayment] = useState(null);
    const header = ["S/N", "Department","Type", "Doctor",  "Date", "Time", "Status", "Details"];

        const appointmentInitial = {
            appointmentID: "",
            doctorID: "",
            doctorName: "",
            doctorEmail: "",
            doctorID2: "",
            serviceID: "",
            serviceID2: "",
            consultationTypeID: "",
            consultationTypeID2: "",
            consultationTypeName: "",
            itemName: "",
            appointmentDepartment: "",
            appointmentDepartmentName: "",
            appointmentDepartment2: "",
            appointmentDetail: "",
            appointmentDate: "",
            appointmentTime: "",
            slotID: "",
            paymentOption: "",
            appointmentAmount: 0,
        }
        const [formData, setFormData] = useState(appointmentInitial)
        const resetFormData = () => {
            setFormData(appointmentInitial)
        }

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/consultation/${patientSerial}`, token)
            .then(async (result) => {
                const patient = result.data[0];
                setPatientDetails(patient)
            }).catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
        await axios.get(`${serverLink}patient/appointment/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setAppointmentList(result.data);
                }else {
                    setAppointmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return appointmentList.length > 0 &&  appointmentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.departmentName}</td>
                        <td>{item.consultationType}</td>
                        <td>{item.doctorName}</td>
                        <td>{formatDateAndTime(item.appointmentDate, 'date')}</td>
                        <td>{item.appointmentTime}</td>
                        <td>{item.appointmentStatus}</td>
                        <td>{item.appointmentDetail ==='' ? 'N/A' : item.appointmentDetail}</td>
                        {/*<td>*/}
                        {/*    <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#update-appointment-modal"*/}
                        {/*       onClick={() => {*/}
                        {/*            setFormData({*/}
                        {/*                ...formData,*/}
                        {/*                appointmentID: item.appointmentID,*/}
                        {/*                doctorID: item.doctorID,*/}
                        {/*                doctorName: item.doctorName,*/}
                        {/*                doctorEmail: item.doctorEmail,*/}
                        {/*                doctorID2: item.doctorID2,*/}
                        {/*                serviceID: item.serviceID,*/}
                        {/*                serviceID2: item.serviceID2,*/}
                        {/*                consultationTypeID: item.consultationTypeID,*/}
                        {/*                consultationTypeID2: item.consultationTypeID2,*/}
                        {/*                consultationTypeName: item.consultationType,*/}
                        {/*                itemName: item.consultation,*/}
                        {/*                appointmentDepartment: item.appointmentDepartment,*/}
                        {/*                appointmentDepartmentName: item.departmentName,*/}
                        {/*                appointmentDepartment2: item.appointmentDepartment2,*/}
                        {/*                appointmentDetail: item.appointmentDetail,*/}
                        {/*                appointmentDate: item.appointmentDate,*/}
                        {/*                appointmentTime: item.appointmentTime,*/}
                        {/*                slotID: item.slotID,*/}
                        {/*                paymentOption: item.paymentOption,*/}
                        {/*                appointmentAmount: item.appointmentAmount,*/}
                        {/*            })*/}
                        {/*       }}>*/}
                        {/*        Edit*/}
                        {/*    </a></td>*/}
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <>
                <div className="">
                    <div className="table-responsive">
                        <h5 className="card-title">Patient Appointment Report</h5>
                        <DataTable tableID="patient-appointments" header={header} body={showTable()} title="Patient Appointment Report"/>
                    </div>
                </div>
                <UpdateAppointment
                    id="update-appointment-modal"
                    title="Update Appointment"
                    close="closeUpdateAppointment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    patientEmail={patientDetails.emailAddress}
                    walletAmount={patientDetails.walletAmount}
                    formData={formData}
                    setRebuildAppointment={setRebuildAppointment}
                    setRebuildPayment={setRebuildPayment}
                    setRebuildCheckIn={setRebuildCheckIn}
                />
            </>

    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientAppointments);

