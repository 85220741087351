import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import Accordion from  '../../../components/common/accordion/accordion';
import {toast} from "react-toastify";
import {showConfirm} from "../../common/sweetalert/sweetalert";

const PatientPrescriptionRequest = ({patientSerial, serviceID, serviceName, access, handleRemount, loginData, setDoctorFormData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [prescription, setPrescription] = useState([])
    const [prescriptionList, setPrescriptionList] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        let serviceUrl = serviceName === "Consultation" ? `${serverLink}pharmacy/prescription/list/${patientSerial}/${serviceID}` : `${serverLink}pharmacy/prescription/admission/list/${patientSerial}/${serviceID}`;
        const url = !serviceID ? `${serverLink}pharmacy/prescription/request/${patientSerial}` : serviceUrl
        await axios.get(url, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setPrescription(result.data.prescription);
                    setPrescriptionList(result.data.items);
                }else {
                    setPrescription([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const cancelRequest = async (item) => {
        toast.info("please wait...");
        const sendData = {
            prescriptionID: item.prescriptionID,
            drugPrescriptionID: item.drugPrescriptionID,
            drugQuantity: parseInt(item.drugQuantity),
            drugAmount: parseFloat(item.drugTotalAmount),
            drugID: item.drugID,
            status: 'Cancelled',
            submittedBy: loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}pharmacy/prescription/cancel`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Prescription request has been cancelled");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }



    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h3 className="card-title">Prescription(s)</h3>
                    {
                        prescription.length > 0 ?  prescription.map((element, index) => {
                            let total = 0;
                            const status = element.paymentStatus === 'Pending' ?
                                <span className="badge-pill badge-xs badge-info">Pending</span> : element.paymentStatus === 'Cancelled' ?
                                    <span className="badge-pill badge-xs badge-danger">Cancelled</span> :
                                    <span className="badge-pill  badge-xs badge-success">Paid</span>;
                            return (
                                <Accordion
                                    index={index}
                                    header={`${formatDateAndTime(element.submittedDate, 'date_and_time')} => Prescribed By: ${element.submittedBy}`}
                                    status={status}
                                    content={
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Drug Name</th>
                                                    <th>Dose</th>
                                                    <th>Frequency</th>
                                                    <th>Description</th>
                                                    <th>Status</th>
                                                    <th>Qty</th>
                                                    <th>Unit Price</th>
                                                    <th>Total</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    prescriptionList.filter(e => e.drugPrescriptionID === element.drugPrescriptionID).map((item, key) => {
                                                        total += item.status !== 'Cancelled' ? item.drugTotalAmount : 0;
                                                        return (
                                                            <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{item.drugName}</td>
                                                                <td>{item.drugDose}</td>
                                                                <td>{item.doseFrequency}</td>
                                                                <td>{item.prescription}</td>
                                                                <td>{item.status === 'Paid' ? <span
                                                                    className="badge badge-pill badge-success">{item.status}</span> : item.status === 'Cancelled' ?
                                                                    <span
                                                                        className="badge badge-pill badge-danger">{item.status}</span> :
                                                                    <span
                                                                        className="badge badge-pill badge-info">{item.status}</span>}</td>
                                                                <td>{item.drugQuantity}</td>
                                                                <td>{currencyConverter(item.drugAmount)}</td>
                                                                <td>{currencyConverter(item.drugTotalAmount)}</td>
                                                                <td>
                                                                    {
                                                                        access !== 'Doctor' ? <b className="text-center">--</b> :
                                                                        item.status === 'Paid' || item.status === 'Cancelled' ?
                                                                            <b className="text-center">--</b> :
                                                                            <a href="#"
                                                                               className={"btn btn-primary btn-sm "}
                                                                               onClick={() => {
                                                                                   showConfirm("Warning", `Are you sure you want to cancel this prescription request?`, "warning")
                                                                                       .then(async (confirm) => {
                                                                                           if (confirm) {
                                                                                               cancelRequest(item)
                                                                                           }
                                                                                       })
                                                                               }}>
                                                                                Cancel
                                                                            </a>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    <tr>
                                                        <td><h3><b>Total</b></h3></td>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td><h4><b>{currencyConverter(total)}</b></h4></td>
                                                        <td>--</td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                />
                            )
                        }) : <div className="alert alert-info text-center p-3"><h3>No Record Found!</h3></div>
                    }

                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientPrescriptionRequest);

