import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import RequestService from "../components/service-request";
import AddDependant from "../components/dependant";

const PatientDependants = ({patientSerial, patientEmail, patientName, setRebuildDependant, walletAmount, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [dependantList, setDependantList] = useState([])
    const header = ["S/N", "Dependant Name", "Dependant ID", "Phone Number",  "Email Address", "Last Visit Date", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        await axios.get(`${serverLink}patient/dependant/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setDependantList(result.data);
                }else {
                    setDependantList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return dependantList.length > 0 &&  dependantList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Dependant}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.PhoneNumber}</td>
                        <td>{item.EmailAddress}</td>
                        <td>{!item.LastVisit || item.LastVisit === "No record" ? '--' : formatDateAndTime(item.LastVisit, 'date')}</td>
                        <td>
                                <a href="#" className={"btn btn-danger btn-sm "}
                                   onClick={() => {
                                       showConfirm("Warning", `Are you sure you want to delete this dependant?`, "warning")
                                           .then(async (confirm) => {
                                               if (confirm) {
                                                   delete_item(item.patientSerial)
                                               }
                                           })
                                   }}>
                                    Delete
                                </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}patient/dependant/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Dependant</h5>
                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                        <div className="ml-auto">
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    data-toggle="modal"
                                    data-target="#add-dependant-modal"
                                >
                                    <i className="fa fa-plus"></i> Add Dependant
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable tableID="patient-dependant" header={header} body={showTable()}
                               title="Patient Dependant Report"/>
                </div>
                <AddDependant
                    id="add-dependant-modal"
                    title="Add Dependant"
                    close="closeDependant"
                    patientSerial={patientSerial}
                    patientName={patientName}
                    walletAmount={walletAmount}
                    patientEmail={patientEmail}
                    setRebuildDependant={setRebuildDependant}
                />
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientDependants);

