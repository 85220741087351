import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";

const PatientServices = ({patientSerial, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [servicesList, setServicesList] = useState([])
    const header = ["S/N", "Service", "Item", "Qty",  "Unit Price", "Total", "Payment Status", "Status"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/service/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setServicesList(result.data);
                }else {
                    setServicesList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return servicesList.length > 0 &&  servicesList.map((item, index) => {
                let price = parseFloat(item.itemPrice);
                let qty = parseInt(item.itemQty);
                let total = price * qty;
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.serviceName}</td>
                        <td>{item.itemName}</td>
                        <td>{qty}</td>
                        <td>{currencyConverter(price)}</td>
                        <td>{currencyConverter(total)}</td>
                        <td>{item.paymentStatus}</td>
                        <td>{item.status}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Services</h5>
                    <DataTable tableID="patient-services" header={header} body={showTable()} title="Patient Services Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientServices);

