import React, {useEffect, useState} from "react";
import {currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Select from "react-select";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import MakePayment from "../../finance/choose-payment/choose-payment";
import DataTable from "../../common/data-table/data-table";
function RequestService(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsGettingSchedules, setIsGettingSchedules] = useState(true)
    const [serviceList, setServiceList] = useState([])
    const [serviceType, setServiceType] = useState([])
    const [services, setServices] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [requestID, setRequestID] = useState(formatDateAndTime(Date.now(), 'day') + generate_token(8))
    const header = ["S/N", "Item", "Price", "Qty"];


    const appointmentInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        doctorID: "",
        doctorID2: "",
        serviceID: "",
        serviceID2: "",
        consultationTypeID: "",
        consultationTypeID2: "",
        itemName: "",
        walletAmount: props.walletAmount,
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(appointmentInitial)
    const resetFormData = () => {
        setFormData(appointmentInitial)
    }

    useEffect( () => {
        getData();
    }, []);

    useEffect( () => {
        setServices(serviceList.filter(e=>e.service_id === formData.consultationTypeID))
        setIsGettingSchedules(false)
    }, [formData.consultationTypeID]);

    const getData = async () => {
        await axios.get(`${serverLink}patient/appointment/data`)
            .then((result) => {
                let service_types = []; let service_list = [];
                result.data.serviceTypes.map((row) => {
                    service_types.push({value: row.serviceID, label: row.serviceName, service_description: row.serviceDescription})
                });

                result.data.services.map((row) => {
                    service_list.push({value: row.serviceDataID, label: row.serviceItemName, service_id: row.serviceID, amount: row.serviceAmount, description: row.serviceDescription})
                });

                setServiceType(service_types);
                setServiceList(service_list);

                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const  showTable = () => {
        try {
            return services.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.label}</td>
                        <td>{currencyConverter(item.amount)}</td>
                        <td>
                            <input type="number" className={" text-center"} style={{width: '70px'}}
                               onChange={(event) => {
                                   const val = event.target.value;
                                   if (val !== ""){
                                       let serviceObject = {
                                           itemID: item.value,
                                           itemName: item.label,
                                           itemPrice: parseFloat(item.amount),
                                           itemQty: parseInt(val),
                                           itemTotal: parseInt(val) * parseFloat(item.amount),
                                           itemDescription: item.description,
                                           requestID: requestID,
                                           serviceID: formData.consultationTypeID,
                                       };

                                       let filter = selectedServices.filter(e => e.itemID.toString() === item.value.toString());
                                       if (filter.length > 0) {
                                           setSelectedServices(prevServices =>
                                               prevServices.filter(e => e.itemID.toString() !== item.value.toString())
                                           );
                                       }
                                       setSelectedServices(prevServices => [...prevServices, serviceObject]);
                                   }else{
                                       setSelectedServices(prevServices =>
                                           prevServices.filter(e => e.itemID.toString() !== item.value.toString())
                                       );
                                   }

                               }}/></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onChangeServiceType = (e) => {
        setIsGettingSchedules(true)
        const val = e.value;
        setFormData({
            ...formData,
            consultationTypeID: val,
            consultationTypeID2: e,
            serviceID: '',
            serviceID2: '',
        })
    }

    const requestService = async (paymentData) => {
        setIsFormLoading(true)
        const requestData = {
            ...formData,
            cartItems: selectedServices,

        }
        toast.info("please wait...");
        await axios.post(`${serverLink}patient/service/request`, requestData, token).then(async (result) => {
            if (result.data.message === "success") {
                let item_id = requestID;
                const sendData = {...paymentData,  itemID: item_id}
                await axios.post(`${serverLink}payment/post-payment`, sendData, token).then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Service Requested Successfully");
                        setIsFormLoading(false);
                        props.setRebuildServiceRequest(generate_token(5));
                        props.setRebuildPayment(generate_token(5));
                        document.getElementById("closeServicePayment").click();
                        document.getElementById("closeServiceRequest").click();
                        setSelectedServices([])
                        resetFormData();
                    } else {
                        setIsFormLoading(false);
                        toast.error("Something went wrong posting payment. Please try again!");
                    }
                })
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding services. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const handlePayment = () => {
        if (selectedServices.length < 1){
            toast.error('You have to select at least one item before submitting')
            return false
        }
        document.getElementById('pay-now').click();
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Service Type</label>
                                <Select
                                    id="consultationTypeID"
                                    name="consultationTypeID"
                                    value={formData.consultationTypeID2}
                                    onChange={onChangeServiceType}
                                    options={serviceType}
                                    placeholder="Select Service Type"
                                />
                            </div>

                            {
                                IsGettingSchedules ? <></> :
                                        <div className="mb-3 table-responsive">
                                            <DataTable tableID="serviceRequest" header={header} body={showTable()} title="Services"/>
                                        </div>
                            }
                            <div className="col-md-12 mb-3 mt-5" style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}>
                                <h3 style={{color: '#5687b7', fontWeight: 'bold'}}>SELECTED ITEMS</h3>
                            </div>
                            {
                                selectedServices.length > 0 ?
                                    <div className="mb-3 table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Item</th>
                                                <th>Price</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                selectedServices.map((item, index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index +1}</td>
                                                            <td>{item.itemName}</td>
                                                            <td>{currencyConverter(item.itemPrice)}</td>
                                                            <td>{item.itemQty}</td>
                                                            <td>{currencyConverter(item.itemTotal)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                <tr>
                                                    <td><h3><b>Total</b></h3></td>
                                                    <td>--</td>
                                                    <td>--</td>
                                                    <td><h4><b>{selectedServices.reduce((acc, service) => acc + service.itemQty, 0)}</b></h4></td>
                                                    <td><h4><b>{currencyConverter(selectedServices.reduce((acc, service) => acc + service.itemTotal, 0))}</b></h4></td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="alert alert-info text-center col-md-12"><h3>No Item Selected</h3></div>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={handlePayment}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Make Payment"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <button data-toggle="modal" style={{display: 'none'}} id="pay-now" data-target={`#payment-modal`}>Open</button>
        <MakePayment
            id="payment-modal"
            title="Make Payment"
            close="closeServicePayment"
            patientSerial={formData.patientSerial}
            patientName={formData.patientName}
            walletAmount={formData.walletAmount}
            paymentFor="Service"
            itemName={'Service'}
            itemAmount={selectedServices.reduce((acc, service) => acc + service.itemTotal, 0)}
            paymentType="Debit"
            IsFormLoading={IsFormLoading}
            onPostPayment={requestService}
            onError={()=>{console.log("Error")}}
        />
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(RequestService);