import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer';
import {setDashboardDetails, setLoginDetails, setPermissionDetails} from "../../../action/actions";

const SideBar = (props) => {
    const [menuList, setMenuList] = useState([])
    const [subMenuList, setSubMenuList] = useState([])
    const [permissionList, setPermissionList] = useState(props.permissionData)
    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(()=> {
         let sub_menu_name = [];  const permission = props.permissionData;
        if (props.permissionData.length > 0){
            const main_menu = permission.reduce((acc, curr) => {
                const existingMenu = acc.find((item) => item.MenuName === curr.MenuName);
                if (!existingMenu) {
                    acc.push({ MenuName: curr.MenuName, Icon: curr.Icon });
                }
                return acc;
            }, []);
            setMenuList(main_menu)

            //Sub Menu
            let sub_row = [];
            main_menu.map((e,i)=>{
                sub_menu_name = [...new Set(permission.filter(x=>x.MenuName === e).map(item=>item.SubMenuName))];
                sub_row.push({
                    MenuName: e,
                    SubMenuName: sub_menu_name,
                })
            })
            setSubMenuList(prevState => [prevState, ...sub_row])
        }

    }, [""])


    const handleClick = (menu) => {
        setActiveMenu(menu === activeMenu ? null : menu);
    };

    const toggleClick = (menu) => {
        document.getElementById("toggle-main-nav").click();
        if (menu === activeMenu){
            handleClick()
        }
    };

    const SESSION_IDLE_MINUTES = 10;
    const handleOnIdle = (event) => {
        console.log('last active', getLastActiveTime())
        signOut();
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    const signOut = () => {
        props.setOnLoginDetails([]);
        props.setOnPermissionDetails([]);
    };

    return (
        <>
            <aside className="left-sidebar">
                <div className="scroll-sidebar">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            <li className="nav-small-cap">
                                <i className="mdi mdi-dots-horizontal"></i>
                                <span className="hide-menu">MENU</span>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                   to="/" aria-expanded="false">
                                    <i className="fa fa-desktop"></i>
                                    <span className="hide-menu">Dashboard</span>
                                </Link>
                            </li>
                            {props.permissionData.length > 0 &&
                                menuList.map((menu, index) => {
                                    const isMenuActive = menu.MenuName === activeMenu;

                                    return (
                                        <li key={index} className="sidebar-item">
                                            <a
                                                id="toggle-click"
                                                className={`sidebar-link has-arrow waves-effect waves-dark ${isMenuActive ? 'active' : ''}`}
                                                onClick={() => handleClick(menu.MenuName)}
                                                href="#"
                                                aria-expanded={isMenuActive ? 'true' : 'false'}
                                            >
                                                <i className={`fa ${menu.Icon}`}></i>
                                                <span className="hide-menu">{menu.MenuName}</span>
                                            </a>
                                            <ul aria-expanded={isMenuActive ? 'true' : 'false'} className={`collapse first-level ${isMenuActive ? 'in' : ''}`}>
                                                {permissionList
                                                    .filter((e) => e.MenuName === menu.MenuName)
                                                    .map((data, x) => (
                                                        <li key={x} className="sidebar-item">
                                                            <Link to={data.SubMenuLink} className="sidebar-link" onClick={()=>toggleClick(menu.MenuName)}>
                                                                <i className="mdi mdi-adjust"></i>
                                                                <span className="hide-menu"> {data.SubMenuName} </span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </li>
                                    );
                                })}
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                   to="/" aria-expanded="false" onClick={signOut}>
                                    <i className="mdi mdi-directions"></i>
                                    <span className="hide-menu">Log Out</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
