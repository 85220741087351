import React, { useState } from "react";
import JoditEditor from "jodit-react";
import {formatDate} from "../../url";
import ImageUploader from "../components/image-uploader";

export default function ManagePatientForm(props) {
  const editorConfig = {
    readonly: false,
    height: 200,
  };
  return (
    <>
      <div
        id={`${
          typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`}
        className="modal fade bs-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                {props.title ?? ""}
              </h4>
              <button
                type="button"
                className={`${
                  typeof props.close !== "undefined" ? props.close : "close"
                }`}
                id={`${
                  typeof props.close !== "undefined" ? props.close : "close"
                }`}
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mb-3" style={{backgroundColor: '#eeeeee'}}>
                  <h4 className="card-title font-weight-bold pt-2">Person Info</h4>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Title</label>
                  <select className="form-control" name="title" id="title"
                          value={props.formData.title} onChange={props.onEdit}>
                    <option value="">Select Title</option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Mss</option>
                    <option>Ms</option>
                    <option>Dr</option>
                    <option>Capt</option>
                    <option>Prof</option>
                    <option>HRH</option>
                  </select>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">First Name</label>
                  <input
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      value={props.formData.firstName}
                      onChange={props.onEdit}
                      placeholder="First Name"
                  />
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Middle Name</label>
                  <input
                      name="middleName"
                      className="form-control"
                      id="middleName"
                      value={props.formData.middleName}
                      onChange={props.onEdit}
                      placeholder="Middle Name"
                  />
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Surname</label>
                  <input
                      name="surname"
                      className="form-control"
                      id="surname"
                      value={props.formData.surname}
                      onChange={props.onEdit}
                      placeholder="Surname"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Gender</label>
                  <select className="form-control" name="gender" id="gender"
                          value={props.formData.gender} onChange={props.onEdit}>
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Date of Birth</label>
                  <input
                      type="date"
                      name="dateOfBirth"
                      className="form-control"
                      id="dateOfBirth"
                      value={formatDate(props.formData.dateOfBirth)}
                      onChange={props.onEdit}
                      placeholder="Date of Birth"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Blood Group</label>
                  <select className="form-control" name="bloodGroup" id="bloodGroup"
                          value={props.formData.bloodGroup} onChange={props.onEdit}>
                    <option value="">What's your blood group?</option>
                    <option>A+</option>
                    <option>A-</option>
                    <option>AB+</option>
                    <option>AB-</option>
                    <option>B+</option>
                    <option>B-</option>
                    <option>O+</option>
                    <option>O-</option>
                    <option>N/A</option>
                  </select>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Marital Status</label>
                  <select className="form-control" name="maritalStatus" id="maritalStatus"
                          value={props.formData.maritalStatus} onChange={props.onEdit}>
                    <option value="">Select Marital Status</option>
                    <option>Single</option>
                    <option>Married</option>
                    <option>Divorced</option>
                    <option>Widowed</option>
                    <option>Separated</option>
                  </select>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Email Address</label>
                  <input
                      name="emailAddress"
                      className="form-control"
                      id="emailAddress"
                      value={props.formData.emailAddress}
                      onChange={props.onEdit}
                      placeholder="Email Address"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Phone Number</label>
                  <input
                      name="phoneNumber"
                      className="form-control"
                      id="phoneNumber"
                      value={props.formData.phoneNumber}
                      onChange={props.onEdit}
                      placeholder="Phone Number"
                  />
                </div>

                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Alternative PhoneNumber</label>
                  <input
                      name="altPhoneNumber"
                      className="form-control"
                      id="altPhoneNumber"
                      value={props.formData.altPhoneNumber}
                      onChange={props.onEdit}
                      placeholder="Alternative PhoneNumber"
                  />
                </div>
                <div className="col-md-12 mb-3" style={{backgroundColor: '#eeeeee'}}>
                  <h4 className="card-title font-weight-bold pt-2">Next of Kin Details</h4>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Next of Kin Name</label>
                  <input
                      name="occupation"
                      className="form-control"
                      id="nextOfKinName"
                      value={props.formData.nextOfKinName}
                      onChange={props.onEdit}
                      placeholder="Next of Kin Name"
                  />
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Next of Kin Phone Number</label>
                  <input
                      name="nextOfKinPhone"
                      className="form-control"
                      id="nextOfKinPhone"
                      value={props.formData.nextOfKinPhone}
                      onChange={props.onEdit}
                      placeholder="Next of Kin Phone Number"
                  />
                </div>

                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Next of Kin Relationship</label>
                  <select className="form-control" name="nextOfKinRelationship" id="nextOfKinRelationship"
                          value={props.formData.nextOfKinRelationship} onChange={props.onEdit}>
                    <option value="">Select Relationship</option>
                    <option>Father</option>
                    <option>Mother</option>
                    <option>Husband</option>
                    <option>Wife</option>
                    <option>Brother</option>
                    <option>Sister</option>
                    <option>Son</option>
                    <option>Daughter</option>
                  </select>
                </div>
                <div className="col-md-12 mb-3" style={{backgroundColor: '#eeeeee'}}>
                  <h4 className="card-title font-weight-bold pt-2">Demographic Details</h4>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label>Nationality</label>
                  <select
                      name="nationality"
                      id="nationality"
                      className="form-control"
                      value={props.formData.nationality}
                      onChange={props.onEdit}>
                    <option/>
                    {
                      props.countryData.map((country, index) => {
                        return <option key={index} value={country}>{country}</option>
                      })
                    }
                  </select>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label>State Of Origin</label>
                  <select
                      name="state"
                      id="state"
                      className="form-control"
                      value={props.formData.state}
                      onChange={props.onEdit}>
                    <option/>
                    {
                      props.stateList.map((state, index) => {
                        return <option key={index} value={state}>{state}</option>
                      })
                    }
                  </select>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label>LGA</label>
                  <select
                      name="lga"
                      id="lga"
                      className="form-control"
                      value={props.formData.lga}
                      onChange={props.onEdit}>
                    <option/>
                    {
                      props.lgaList.map((lga, index) => {
                        return <option key={index} value={lga}>{lga}</option>
                      })
                    }
                  </select>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label>Zip Code</label>
                  <input name="zipCode"
                         type="text"
                         className="form-control"
                         id="zipCode"
                         value={props.formData.zipCode}
                         onChange={props.onEdit}
                         placeholder="Zip Code"/>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label>Religion</label>
                  {/* <input type="text" name="religion" placeholder="Buddhism | Jewish | Atheist | Ekankar | etc." /> */}
                  <select
                      name="religion"
                      className="form-control"
                      id="religion"
                      value={props.formData.religion}
                      onChange={props.onEdit}

                  >
                    <option/>
                    <option value="">Select Religion</option>
                    <option>Christianity</option>
                    <option>Islam</option>
                    <option>Others</option>
                  </select>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label>Ward</label>
                  <input name="ward"
                         type="text"
                         className="form-control"
                         id="ward"
                         value={props.formData.ward}
                         onChange={props.onEdit}
                         placeholder="Ward"/>
                </div>

                <div className="mb-3 form-group col-md-12">
                  <label>Occupation</label>
                  <input type="text"
                         name="occupation"
                         className="form-control"
                         id="occupation"
                         value={props.formData.occupation}
                         onChange={props.onEdit}
                         placeholder="What do you do for a living? "/>
                </div>


                <div className="mb-3 form-group col-md-6">
                  <label>Residential Address</label>
                  <textarea rows={3}
                            name="residentialAddress"
                            className="form-control"
                            id="residentialAddress"
                            value={props.formData.residentialAddress}
                            onChange={props.onEdit}
                            placeholder="Address of where you live now"/>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label>Work Place Address</label>
                  <textarea rows={3} name="officeAddress"
                            className="form-control"
                            id="officeAddress"
                            defaultValue={props.formData.officeAddress}
                            onChange={props.onEdit}
                            placeholder="Address of where you work."/>
                </div>


                <div className="col-md-12 mb-3" style={{backgroundColor: '#eeeeee'}}>
                  <h4 className="card-title font-weight-bold pt-2">Other Details</h4>
                </div>

                <div className="mb-3 form-group col-md-3">
                  <label className="form-label">Patient Type</label>
                  <select className="form-control" name="patientType" id="patientType"
                          value={props.formData.patientType} onChange={props.onEdit}>
                    <option value="">Select Patient Type</option>
                    <option value="Corporate">Corporate</option>
                    <option value="Insurance">Insurance</option>
                    <option value="Private Insurance and NHIA">Private Insurance and NHIA</option>
                  </select>
                </div>

                <div className="mb-3 form-group col-md-3">
                  <label className="form-label">Status</label>
                  <select className="form-control" name="status" id="status"
                          value={props.formData.status} onChange={props.onEdit}>
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <ImageUploader formData={props.formData} setFormData={props.setFormData} />
                  {/*<input*/}
                  {/*    type="file"*/}
                  {/*    className="form-control form-control-solid"*/}
                  {/*    name="ImagePath"*/}
                  {/*    id="ImagePath"*/}
                  {/*    onChange={props.onImageChange}*/}
                  {/*    accept="image/*"*/}
                  {/*/>*/}
                </div>



              </div>
            </div>
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-default waves-effect"
                  data-dismiss="modal"
              >
                Close
              </button>
              {props.IsFormLoading ? (
                  <button type="button" className="btn btn-primary ms-auto">
                  <span>
                    <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                    />{" "}
                    Please wait...
                  </span>
                  </button>
              ) : (
                  <button
                      type="button"
                      onClick={props.onSubmit}
                      className="btn btn-danger waves-effect waves-light"
                  >
                    {props.btn ?? "submit"}
                  </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
