import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {calculateAge, currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import {toast} from "react-toastify";
import MakePayment from "../../finance/choose-payment/choose-payment";
import {PatientInfoCardLab} from "../../patient/utils/patient-info-card-lab";
import TakeSample from "./utils/take-sample";
import EnterTestResult from "./utils/enter-test-result";
import PatientImagingRequest from "../../doctor/utils/patient-imaging-request";
import PatientLabRequest from "../../doctor/utils/patient-lab-request";

function LabTestDashboard(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState(props.patientDetails)
    const [rebuildLab, setRebuildLab] = useState(null);
    const [rebuildDashboard, setRebuildDashboard] = useState(null);

    const Initial = {
        patientSerial: patientDetails.patientSerial,
        patientName: patientDetails.patientName,
        doctorID: `${props.loginData[0]?.userID}`,
        labTestRequestID: patientDetails.labTestRequestID,
        description: '',
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)
    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const onConfirmResult = async (e) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            testStatus: 'Result Confirmed',
        }

        await axios.post(`${serverLink}laboratory/test/confirm-result`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Test Result Confirmed Successfully");
                setIsFormLoading(false);
                setRebuildDashboard(generate_token(5));
                setRebuildLab(generate_token(5));
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong confirming result. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    const onRejectResult = async (e) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            testStatus: 'Result Rejected',
        }

        await axios.post(`${serverLink}laboratory/test/reject-result`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Test Result Rejected Successfully");
                setIsFormLoading(false);
                setRebuildDashboard(generate_token(5));
                setRebuildLab(generate_token(5));
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong rejecting result. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    useEffect( () => {
        getData();
    }, [rebuildDashboard]);

    const getData = async () => {
        setIsLoading(true)
        let record = {};
        await axios.get(`${serverLink}patient/consultation/${patientDetails.patientSerial}`, token)
            .then(async (result) => {
                const patient = result.data;
                await axios
                    .get(`${serverLink}laboratory/test/request/details/${patientDetails.labTestRequestID}`, token)
                    .then((res) => {
                        if (res.data.request.length > 0) {

                            const item = {
                                ...patientDetails,
                                walletAmount:patient[0].walletAmount,
                                passport:patient[0].passport,
                                firstName:patient[0].firstName,
                                middleName:patient[0].middleName,
                                surname:patient[0].surname,
                                title:patient[0].title,
                                emailAddress:patient[0].emailAddress,
                                phoneNumber:patient[0].phoneNumber,
                                altPhoneNumber:patient[0].altPhoneNumber,
                                dateOfBirth:patient[0].dateOfBirth,
                                bloodGroup:patient[0].bloodGroup,
                                gender:patient[0].gender,
                                status:patient[0].status,
                                maritalStatus:patient[0].maritalStatus,
                                nextOfKinName:patient[0].nextOfKinName,
                                nextOfKinPhone:patient[0].nextOfKinPhone,
                                nextOfKinRelationship:patient[0].nextOfKinRelationship,
                                residentialAddress:patient[0].residentialAddress,
                                lastVisit:patient[0].lastVisit,
                                ...res.data.request[0],
                                test: res.data.test,
                                specimen: res.data.specimen,
                                items: res.data.items,
                                result: res.data.result,
                            }
                            setPatientDetails(item)
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.log("NETWORK ERROR");
                    });
            })
            .catch((err) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    }

    const initiatePayment = async (paymentData) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...paymentData,
            itemID: patientDetails.labTestRequestID,
        }
        await axios.post(`${serverLink}payment/post-payment`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Payment Successfully");
                setIsFormLoading(false);
                setRebuildDashboard(generate_token(5))
                setRebuildLab(generate_token(5));
                document.getElementById("closePayment").click();

            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong posting payment. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    const clearPayment = () => {
        if (parseFloat(patientDetails.testAmount) === 0){
            toast.error('Amount cannot be 0')
            return false
        }
        document.getElementById('pay-now').click();
    }

    const showStatus = (status) => {
        switch (status) {
            case 'requested':
                return <span className="badge badge-pill badge-info">{status}</span>;
            case 'Specimen Taken':
                return <span className="badge badge-pill badge-primary">{status}</span>;
            case 'Result Entered':
                return <span className="badge badge-pill badge-success">{status}</span>;
            case 'Result Confirmed':
                return <span className="badge badge-pill badge-success">{status}</span>;
            case 'Result Rejected':
                return <span className="badge badge-pill badge-danger">{status}</span>;
            case 'Cancelled':
                return <span className="badge badge-pill badge-danger">{status}</span>;
            default:
                return <span className="badge badge-pill badge-secondary">{status}</span>;
        }
    };

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Lab Test Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-imaging-tab" data-toggle="pill"
                                           href="#imaging" role="tab" aria-controls="pills-imaging"
                                           aria-selected="false">Lab Test Request History</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <PatientInfoCardLab patientDetails={patientDetails}/>
                                                    {
                                                        patientDetails.requestStatus === 'Cancelled' ?
                                                            <div className="col-md-12 mt-5">
                                                                <div className="card">
                                                                    <div className="card-body text-center">
                                                                        <h2>
                                                                            <b>
                                                                                <span className="badge badge-pill badge-danger">Request Cancelled</span>
                                                                            </b>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row">
                                                                <MenuCard title="Make Payment" icon="fa-dollar-sign"
                                                                          size="col-md-6" onClick={() => {
                                                                    if (patientDetails.paymentStatus === 'Paid') {
                                                                        toast.error("Payment was made already")
                                                                        return false
                                                                    } else {
                                                                        clearPayment();
                                                                    }
                                                                }}
                                                                          color="orange" modal="birth-modal"/>
                                                                {
                                                                    patientDetails.paymentStatus === 'unpaid' ?
                                                                        <MenuCard title="Take Sample"
                                                                                  icon="fa fa-vials"
                                                                                  size="col-md-6" onClick={() => {
                                                                            toast.error("Clear payment first!")
                                                                            return false
                                                                        }}
                                                                                  color="primary"/>
                                                                        :
                                                                        patientDetails.specimenTakenDate === null ?
                                                                        <MenuCard title="Take Sample"
                                                                                  icon="fa fa-vials"
                                                                                  size="col-md-6" color="primary"
                                                                                  modal="sample-modal"/>
                                                                            :
                                                                            <MenuCard title="Enter Result"
                                                                                      icon="mdi mdi-cloud-upload"
                                                                                      size="col-md-6" color="success"
                                                                                      modal="result-modal"/>
                                                                }

                                                            </div>
                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h4><b>Lab Test Request Details</b></h4>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>Test Name: </th>
                                                                        <td>{patientDetails.testName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Specimen: </th>
                                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Specimen Taken By/Date: </th>
                                                                        <td style={{paddingBottom: '0px', paddingTop: '12px'}}>
                                                                            {
                                                                               !patientDetails.specimenTakenDate || patientDetails.specimenTakenDate === null ?
                                                                                    <span className="text-danger">Specimen not taken yet</span> :
                                                                                    <>
                                                                                        {patientDetails.specimenTakeBy} <br/> {formatDateAndTime(patientDetails.specimenTakenDate, 'date_and_time')}
                                                                                    </>
                                                                            }
                                                                            </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Bottle: </th>
                                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenBottle}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Volume: </th>
                                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenBottleVolume}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Turn Around: </th>
                                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenTurnAroundTime}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th> Patient Preparation: </th>
                                                                        <td>{patientDetails.specimen.filter(e=>e.labTestID === patientDetails.testID)[0]?.specimenPatientPreparation}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Amount: </th>
                                                                        <td>{currencyConverter(patientDetails.testAmount)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{padding: '10px'}}>Payment Status: </th>
                                                                        <td style={{padding: '10px'}}>{patientDetails.paymentStatus === 'Paid' ? <span className="badge badge-pill badge-success">{patientDetails.paymentStatus}</span> : <span className="badge badge-pill badge-danger">{patientDetails.paymentStatus}</span>}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Requested Date: </th>
                                                                        <td>{formatDateAndTime(patientDetails.submittedOn, 'date_and_time')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{paddingTop: '8px', paddingBottom: '0px'}}>Test Status: </th>
                                                                        <td style={{paddingTop: '8px', paddingBottom: '0px'}}>{showStatus(patientDetails.testStatus)}</td>
                                                                    </tr>

                                                                    {/*{*/}
                                                                    {/*    !patientDetails.result ?*/}
                                                                    {/*        <>*/}
                                                                    {/*            <tr>*/}
                                                                    {/*                <th  style={{padding: '35px'}}>&nbsp; &nbsp;</th>*/}
                                                                    {/*                <td>&nbsp; &nbsp;</td>*/}
                                                                    {/*            </tr>*/}
                                                                    {/*        </>*/}
                                                                    {/*        :*/}
                                                                    {/*        <tr style={{borderTop: '1px solid #cccccc'}}>*/}
                                                                    {/*            <td colSpan={2}>*/}
                                                                    {/*                <div className="col-md-12 pt-3">*/}
                                                                    {/*                    <div className="row">*/}
                                                                    {/*                        <div className="col-md-4">*/}
                                                                    {/*                            <span className="font-bold p-t-30 db">Result</span><br/>*/}
                                                                    {/*                            <a href={`${serverLink}public/uploads/imaging_uploads/${patientDetails.result}`} className="btn btn-sm btn-info">View Result</a>*/}
                                                                    {/*                        </div>*/}
                                                                    {/*                        <div className="col-md-4">*/}
                                                                    {/*                            <span className="font-bold p-t-30 db">Result Entered By</span>*/}
                                                                    {/*                            <h6>{patientDetails.resultEnteredByName}</h6>*/}
                                                                    {/*                        </div>*/}
                                                                    {/*                        <div className="col-md-4">*/}
                                                                    {/*                            <span className="font-bold p-t-30 db">Entered Date</span>*/}
                                                                    {/*                            <h6>{formatDateAndTime(patientDetails.resultEnteredDate, 'date_and_time')}</h6>*/}
                                                                    {/*                        </div>*/}
                                                                    {/*                    </div>*/}
                                                                    {/*                </div>*/}
                                                                    {/*            </td>*/}
                                                                    {/*        </tr>*/}
                                                                    {/*}*/}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <hr style={{margin: '0px', padding: '0px'}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    patientDetails.resultEnteredDate === null ?
                                                        <div className="col-md-12">
                                                            <div className="card">
                                                                <div className="card-header"><h4><b>Lab Test Result</b></h4></div>
                                                                <div className="card-body text-center text-danger">
                                                                    <h4><b>Test Result Not Entered Yet.</b></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-md-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <h4><b>Lab Test Result</b></h4>
                                                                    <div className="table-responsive">
                                                                        <table className="table">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>S/N</th>
                                                                                <th>Item</th>
                                                                                <th>SI Unit</th>
                                                                                <th>Ref. Interval</th>
                                                                                <th>Result</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                patientDetails.result.length > 0 &&  patientDetails.result.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index +1}</td>
                                                                                            <td>{item.item_name}</td>
                                                                                            <td>{item.si_unit}</td>
                                                                                            <td>{item.minimum_interval} - {item.maximum_interval}</td>
                                                                                            <td>{item.result}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            }
                                                                            {
                                                                                patientDetails.testAttachment !== null &&    <tr>
                                                                                    <td>--</td>
                                                                                    <td>--</td>
                                                                                    <td>--</td>
                                                                                    <td colSpan={2}>
                                                                                        <div className="col-md-12 pt-3">
                                                                                            <div className="row " style={{backgroundColor: '#eeeeee', height: '80px', padding: '10px'}}>
                                                                                                <div className="col-md-4">
                                                                                                    <span className="font-bold p-t-30 db">Result</span><br/>
                                                                                                    <a href={`${serverLink}public/uploads/lab_uploads/${patientDetails.testAttachment}`} className="btn btn-sm btn-info">View Result</a>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <span className="font-bold p-t-30 db">Result Entered By</span>
                                                                                                    <h6>{patientDetails.resultEnteredByName}</h6>
                                                                                                </div>
                                                                                                <div className="col-md-4">
                                                                                                    <span className="font-bold p-t-30 db">Entered Date</span>
                                                                                                    <h6>{formatDateAndTime(patientDetails.resultEnteredDate, 'date_and_time')}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            }


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div className="card-footer">
                                                                    {
                                                                        patientDetails.resultConfirmedDate === null ?
                                                                            <>
                                                                                <h2><b>Confirm/Reject Test Result</b></h2>

                                                                                <div className="mb-3 form-group col-md-12">
                                                                                    <label className="form-label">Remark</label>
                                                                                    <textarea
                                                                                        rows={3}
                                                                                        cols={3}
                                                                                        name=""
                                                                                        className="form-control"
                                                                                        id="description"
                                                                                        value={formData.description}
                                                                                        onChange={onEdit}
                                                                                    ></textarea>
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    {
                                                                                        IsFormLoading ?
                                                                                            <button type="button" className="btn btn-primary ms-auto">
                                                                                                <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                                                                            </button>
                                                                                            :
                                                                                            <>
                                                                                                <button type="button" onClick={onRejectResult}
                                                                                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "Reject"}</button>
                                                                                                <button type="button" onClick={onConfirmResult}
                                                                                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Confirm"}</button>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                {
                                                                                    patientDetails.testStatus === 'Result Confirmed' ?
                                                                                        <h2 className="text-success"><b>Result Confirmed</b></h2> : <h2 className="text-danger"><b>Result Rejected</b></h2>
                                                                                }
                                                                            </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="imaging" role="tabpanel"
                                         aria-labelledby="pills-imaging-tab">
                                        <div className="card-body">
                                            <PatientLabRequest patientSerial={patientDetails.patientSerial}  serviceID={patientDetails.requestID} serviceName={patientDetails.requestType}  handleRemount={rebuildLab}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button data-toggle="modal" style={{display: 'none'}} id="pay-now" data-target={`#payment-modal`}>Open
                </button>
                <MakePayment
                    id="payment-modal"
                    title="Make Payment"
                    close="closePayment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    paymentFor="Laboratory"
                    itemName={patientDetails.testName}
                    itemAmount={parseFloat(patientDetails.testAmount)}
                    paymentType="Debit"
                    IsFormLoading={IsFormLoading}
                    onPostPayment={initiatePayment}
                    onError={() => {
                        console.log("Error")
                    }}
                />
                <TakeSample
                    id="sample-modal"
                    title="Take Specimen"
                    close="closeSample"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    patientDetails={patientDetails}
                    setRebuildDashboard={setRebuildDashboard}
                    setRebuildLab={setRebuildLab}
                />

                <EnterTestResult
                    id={patientDetails.testStatus === 'Result Confirmed' ? "" : "result-modal" }
                    title="Enter Test Result"
                    close="closeResult"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    patientDetails={patientDetails}
                    setRebuildDashboard={setRebuildDashboard}
                    setRebuildLab={setRebuildLab}
                />

            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(LabTestDashboard);

