import {formatAMPM, formatDate, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";

const PatientVitals = ({patientSerial, handleRemount, serviceID, serviceName, loginData, setVitalFormData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [vitalList, setVitalList] = useState([])
    const header = ["S/N", "Blood Pressure", "Height", "Weight", "Temperature", "Respiration", "Pulse", "BMI", "BSA", "SPO2", "Remark", "Taken On", "Time Taken", "Taken By", "Edit"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/vitals/list/${serviceName}/${serviceID}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setVitalList(result.data);
                }else {
                    setVitalList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const  showTable = () => {
        try {
            return vitalList.length > 0 &&  vitalList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.bloodPressure}{item.bloodPressure_unit}</td>
                        <td>{item.height}{item.height_unit} </td>
                        <td>{item.weight}{item.weight_unit} </td>
                        <td>{item.temperature}{item.temperature_unit} </td>
                        <td>{item.respiration}</td>
                        <td>{item.pulse}</td>
                        <td>{item.bmi}{item.bmi_unit} </td>
                        <td>{item.bsa}</td>
                        <td>{item.spo2}%</td>
                        <td>{item.remark}</td>
                        <td>{formatDateAndTime(item.dateTaken, 'date')}</td>
                        <td>{formatAMPM(item.timeTaken)}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            {loginData[0]?.userID !== item.submittedBy ? <>--</> :
                                <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal"
                                   data-target="#vital-modal"
                                   onClick={() => {
                                       setVitalFormData({
                                           vitalID: item.vitalID,
                                           appointmentID: item.appointmentID,
                                           pulse: item.pulse,
                                           respiration: item.respiration,
                                           bloodPressure: item.bloodPressure,
                                           bloodPressure_unit: item.bloodPressure_unit,
                                           temperature: item.temperature,
                                           temperature_unit: item.temperature_unit,
                                           weight: item.weight,
                                           weight_unit: item.weight_unit,
                                           height: item.height,
                                           height_unit: item.height_unit,
                                           bmi: item.bmi,
                                           bmi_unit: item.bmi_unit,
                                           bsa: item.bsa,
                                           spo2: item.spo2,
                                           dateTaken: formatDate(item.dateTaken),
                                           timeTaken: item.timeTaken,
                                           remark: item.remark,
                                       })
                                   }}>
                                    Edit
                                </a>
                            }
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Vitals</h5>
                    <DataTable tableID="patient-vitals" header={header} body={showTable()}
                               title="Patient Vitals Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientVitals);

