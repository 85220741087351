import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatDateAndTime, serverLink} from "../../url";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";


function PermissionSettings(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showTBL, setShowTBL] = useState(false)
    const [groupList, setGroupList] = useState([])
    const [subSubMenuList, setSubSubMenuList] = useState([])
    const [permissionList, setPermissionList] = useState([])
    const [permissions, setPermissions] = useState([])
    const header = ["S/N", "Menu Name","Sub Menu Name", "Permitted By", "Date Permitted",  "Action"];
    const [formData, setFormData] = useState({
        GroupID: "",
        SubMenuID: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })

    useEffect( () => {
        getData();
    }, [""]);


    useEffect( () => {
        if (formData.GroupID.toString() !== "" && subSubMenuList.length > 0){
            getPermissionData(permissions, subSubMenuList, formData.GroupID)
        }

    }, [formData.GroupID.toString()]);

    const getData = async (group_id = '') => {
        await axios.get(`${serverLink}staff/report/permission/data`)
            .then( async (result) => {
                setGroupList(result.data.group);
                setSubSubMenuList(result.data.menu);
                setPermissions(result.data.permission);
                setIsLoading(false)
                if (group_id !== '') {
                    await getPermissionData(result.data.permission, result.data.menu, group_id);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const getPermissionData =  async (permissionData, subSubMenu, group) => {
        let row = [];
        if (group.toString() !== ""){
            if (subSubMenu.length > 0){
                subSubMenu.map((item, index) => {
                    let check_permission =  permissionData.filter(e=>e.GroupID.toString() === group.toString() && e.SubMenuID.toString() === item.EntryID.toString());
                    if (check_permission.length > 0){
                        row.push({
                            EntryID: check_permission[0].EntryID,
                            GroupID: check_permission[0].GroupID,
                            SubMenuID: item.EntryID,
                            MenuName: item.MenuName,
                            SubMenuName: item.SubMenuName,
                            InsertedBy: check_permission[0].InsertedBy,
                            InsertedDate: check_permission[0].InsertedDate,
                        })
                    }else{
                        row.push({
                            EntryID: "",
                            GroupID: group,
                            SubMenuID: item.EntryID,
                            MenuName: item.MenuName,
                            SubMenuName: item.SubMenuName,
                            InsertedBy: "",
                            InsertedDate: "",
                        })
                    }
                })
                setPermissionList(row)
                setShowTBL(true)
            }else{
                setPermissionList([])
                setShowTBL(true)
            }
        }else{
            setShowTBL(false)
        }
    }

    const  showTable = () => {
        try {
            return permissionList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.MenuName}</td>
                        <td>{item.SubMenuName}</td>
                        <td>{item.InsertedBy}</td>
                        <td>{item.InsertedDate === "" ? "" : formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td>
                            {
                                item.EntryID.toString() === '' ?
                                    <button type="button" className={"btn btn-primary btn-sm  text-center"}
                                            onClick={() => {
                                                let sendData = {
                                                    ...formData,
                                                    EntryID: item.EntryID,
                                                    SubMenuID: item.SubMenuID,
                                                    GroupID: item.GroupID,
                                                }
                                                onSubmit(sendData);
                                            }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check"
                                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M5 12l5 5l10 -10"/>
                                        </svg>
                                    </button>
                                    :
                                    <button type="button" className={"btn btn-danger btn-sm text-center"}
                                            onClick={() => {
                                                let sendData = {
                                                    ...formData,
                                                    EntryID: item.EntryID,
                                                    SubMenuID: item.SubMenuID,
                                                    GroupID: item.GroupID,
                                                }
                                                onSubmit(sendData);
                                            }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-trash" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="4" y1="7" x2="20" y2="7"/>
                                            <line x1="10" y1="11" x2="10" y2="17"/>
                                            <line x1="14" y1="11" x2="14" y2="17"/>
                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                                        </svg>
                                    </button>
                            }

                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onSubmit = async (sendData) => {

        if (sendData.GroupID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select group", "error");
            return false;
        }

        setIsFormLoading(true);
        await axios
            .post(`${serverLink}staff/report/settings/group/permission/add`, sendData)
            .then( async (result) => {
                if (result.data.message === "success") {
                    toast.success("Permission added successfully");
                    setIsFormLoading(false);
                    await getData(formData.GroupID);
                }else if (result.data.message === "removed") {
                    toast.success("Permission removed successfully");
                    setIsFormLoading(false);
                    await getData(formData.GroupID);
                }
                else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });


    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    return(IsLoading ? <Loader/> :
        <>
            <PageTitle title={["Home", "Permission"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Permission</h4>
                                <h6 className="card-subtitle">Permits user group to access page.</h6>
                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Group</h5></div>
                                <div className="form-group mb-3">
                                    <select className="form-control" name="GroupID" id="GroupID" value={formData.GroupID} onChange={onEdit}>
                                        <option value="">Select Group</option>
                                        {
                                            groupList.length > 0 && groupList.map((item, index)=> {
                                                return (
                                                    <option key={index} value={item.EntryID}>{item.GroupName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                {
                                    showTBL ? <DataTable header={header} body={showTable()} title="Student Report By Programme" /> :
                                        <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                            <div className="d-flex">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon alert-icon" width="24"
                                                         height="24" viewBox="0 0 24 24"
                                                         strokeWidth="2" stroke="currentColor"
                                                         fill="none" strokeLinecap="round"
                                                         strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                              fill="none"/>
                                                        <circle cx="12" cy="12" r="9"/>
                                                        <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                        <polyline points="11 12 12 12 12 16 13 16"/>
                                                    </svg>
                                                </div>
                                                <div> Please select group to show group group permissions </div>
                                            </div>
                                            <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PermissionSettings);

