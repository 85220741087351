import React, {useRef, useState} from "react";
import {currencyConverter} from "../../url";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import "./style.css";



const cardStyle = {
    width: '9cm',
    height: '5cm',
    padding: '10px', // Just for demonstration purposes
    backgroundColor: '#ffffff',
    border: '1px solid #000000',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)'
};

const pageStyle = {
    width: '9cm',
    height: '5cm',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px' // Adjust as needed
};
function PatientCard(props) {
    const { patientSerial, patientName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const componentRef = useRef();
    const [printPage, setPrintPage] = useState(false);



    const allergyInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        allergyName: "",
        allergyDescription: "",
        reactionImage: "",
        ImagePath: "",
        lastAttackDate: "",
        allergyMedication: "",
        durationBeforeSubsiding: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }

    const onPrintPage = () => {
        setPrintPage(true);
        setTimeout(() => {
            handlePrint();
            setPrintPage(false);
        }, 1000);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{ height: '1200px' }} >
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div style={{ marginTop: '60px' }}  ref={componentRef}>
                            {/* Front side Start , border: '1px solid #000'    style={{ width: '3.366in', height: '2.11in',*/}
                            <div className="break-page mb-3 col-md-11 d-flex justify-content-center">
                                <div className="id-card-landscape">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '6.366in', height: '5.11in', backgroundImage: `url(${require('../../../images/newFront.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Front side ends*/}
                            <br />
                            {/* Back side start*/}
                            <div className="break-page mb-3 col-md-11 d-flex justify-content-center">
                                <div className="id-card-landscape">
                                    <div style={{ width: '6.366in', height: '3.70in', border: '15px solid #e61b1d'}}>

                                        <div style={{ position: 'relative', height: '100%' }}>
                                            <div style={{ position: 'absolute', bottom: '38%',   left: '50%', transform: 'translateX(-50%)', textAlign: 'center'}}>
                                                <QRCode
                                                    size={120}
                                                    value={patientSerial ?? ""}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                <br />

                                                <b style={{ fontSize: '20px', color: '#000' }}>Patient ID: {patientSerial ?? ""}</b>
                                            </div>
                                            <div style={{ fontSize: '15px', position: 'absolute', bottom: 0, left: 0, width: '100%',  padding: '10px', backgroundColor: '#e61b1d', color: 'white', textAlign: 'center' }}>
                                                <b style={{ padding: '10px'}}>Address: No. 12 Ngozi Avenue off Omelazu Street by Faulks Road Aba.</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Back side ends*/}
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={onPrintPage}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Print Card"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientCard);