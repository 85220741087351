import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

const PatientPainAssessment = ({patientSerial, serviceID, serviceName, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [painList, setPainList] = useState([])
    const header = ["S/N", "Rating", "Comment", "Taken By", "Taken On"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/appointment/pain-assessment/${serviceName.toLowerCase()}/list/${patientSerial}/${serviceID}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setPainList(result.data.pain);
                }else {
                    setPainList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return painList.length > 0 &&  painList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.painRatingName}</td>
                        <td>{item.painComment}</td>
                        <td>{item.nurseName}</td>
                        <td>{formatDateAndTime(item.submittedOn, 'date_and_time')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h3 className="card-title">Pain Assessment</h3>
                    <DataTable tableID="patient-pain-assessment" header={header} body={showTable()} title="Patient Pain Assessment Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientPainAssessment);

