import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import Accordion from  '../../../components/common/accordion/accordion';
import {toast} from "react-toastify";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {projectEmail, projectName, projectPatientURL} from "../../resources/constants";

const PatientImagingRequest = ({patientSerial, serviceID, serviceName, handleRemount, loginData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [imagingRequestList, setImagingRequestList] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        const url = !serviceID ? `${serverLink}imaging/list/all/${patientSerial}` : `${serverLink}imaging/list/${patientSerial}/${serviceName}/${serviceID}`
        await axios.get(url, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setImagingRequestList(result.data.imaging);
                }else {
                    setImagingRequestList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const cancelRequest = async (item) => {
        toast.info("please wait...");
        const sendData = {
            imagingRequestID: item.imagingRequestID,
            patientName: item.patientName,
            patientEmail: item.patientEmail,
            doctorName: item.doctorName,
            imagingName: item.imagingName,
            labName: item.labName,
            hospitalName: projectName,
            hospitalPatientURL: projectPatientURL,
            hospitalEmail: projectEmail,
            status: 'Cancelled',
            submittedBy: loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}imaging/cancel`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Imaging request has been cancelled");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const showStatus = (status) => {
        switch (status) {
            case 'Pending':
                return <span className="badge badge-pill badge-info">{status}</span>;
            case 'Result Entered':
                return <span className="badge badge-pill badge-primary">{status}</span>;
            case 'Cancelled':
                return <span className="badge badge-pill badge-danger">{status}</span>;
            default:
                return <span className="badge badge-pill badge-secondary">{status}</span>;
        }
    };



    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h3 className="card-title">Imaging Request</h3>
                    {
                        imagingRequestList.length > 0 ?  imagingRequestList.map((item, index) => {
                            return (
                                <Accordion
                                    index={index}
                                    header={<span>{`Image: ${item.imagingName} => ${formatDateAndTime(item.submittedOn, 'date_and_time')} =>  Status: `}{showStatus(item.status)}</span>}
                                    content={
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>Imaging Name</th>
                                                    <th>Test Status</th>
                                                    <th>Payment Status</th>
                                                    <th>Requested By</th>
                                                    <th>Requested Date</th>
                                                    <th>Test Result</th>
                                                    <th>Result Added By</th>
                                                    <th>Result Added Date</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{item.imagingName}</td>
                                                        <td>{showStatus(item.status)}</td>
                                                        <td>{item.paymentStatus}</td>
                                                        <td>{item.doctorName}</td>
                                                        <td>{formatDateAndTime(item.submittedOn, 'date')}</td>
                                                        <td>{!item.result ? '--' :  <a href={`${serverLink}public/uploads/imaging_uploads/${item.result}`} className="btn btn-sm btn-info">View Result</a>}</td>
                                                        <td>{item.resultEnteredByName === 'No Name' ? '--' : item.resultEnteredByName}</td>
                                                        <td>{formatDateAndTime(item.resultEnteredDate, 'date')}</td>
                                                        <td>
                                                            { item.paymentStatus !== 'Paid' && (item.status !== 'Cancelled' && item.status !== 'Result Entered') ?
                                                                <a href="#" className={"btn btn-danger btn-sm "}
                                                                   onClick={() => {
                                                                       showConfirm("Warning", `Are you sure you want to cancel this imaging request?`, "warning")
                                                                           .then( async (confirm) => {
                                                                               if (confirm) {
                                                                                   cancelRequest(item)
                                                                               }
                                                                           })
                                                                   }}>
                                                                    Cancel Request
                                                                </a> : <>--</>
                                                            }

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                />
                            )
                        }) :  <div className="alert alert-info text-center p-3"><h3>No Record Found!</h3></div>
                    }

                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientImagingRequest);

