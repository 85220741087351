import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
function PrescriptionEditForm(props) {
    const { clickedItems, selectedServices, setSelectedServices } = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const InitialValue = {
        drugQuantity: 0,
        drugDose: "",
        doseFrequency: "",
        prescription: ""
    }
    const [formData, setFormData] = useState(InitialValue)

    const resetFormData = () => {
        setFormData(InitialValue)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const prescribe = async () => {
        if (formData.drugQuantity === 0){
            toast.error('Please enter quantity')
            return false
        }
        if (formData.drugDose.toString().trim() === ''){
            toast.error('Please select dosage')
            return false
        }
        if (formData.doseFrequency.toString().trim() === ''){
            toast.error('Please select frequency')
            return false
        }

        if (formData.drugQuantity > clickedItems.itemQty){
            toast.error('Quantity prescribed is greater than stock quantity')
            return false
        }else {
            let serviceObject = {
                drugID: clickedItems.itemID,
                drugName: clickedItems.itemName,
                drugAmount: parseFloat(clickedItems.itemPrice),
                drugQuantity: parseInt(formData.drugQuantity),
                drugTotalAmount: parseInt(formData.drugQuantity) * parseFloat(clickedItems.itemPrice),
                drugDose: formData.drugDose,
                doseFrequency: formData.doseFrequency,
                prescription: formData.prescription,
                status: 'Pending',
            };

            let filter = selectedServices.filter(e => e.drugID.toString() === clickedItems.itemID.toString());
            if (filter.length > 0) {
                setSelectedServices(prevServices =>
                    prevServices.filter(e => e.drugID.toString() !== clickedItems.itemID.toString())
                );
            }
            setSelectedServices(prevServices => [...prevServices, serviceObject]);
            document.getElementById("closePrescriptionRequestFormModal").click();
        }
        resetFormData()
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Item Name</label>
                                <input
                                    className="form-control"
                                    value={clickedItems.drugName}
                                    disabled
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Item Price</label>
                                <input
                                    className="form-control"
                                    value={currencyConverter(clickedItems.drugAmount)}
                                    disabled
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Stock Quantity</label>
                                <input
                                    className="form-control"
                                    value={clickedItems.drugQuantity}
                                    disabled
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Prescription Quantity</label>
                                <input
                                    type="number"
                                    id="drugQuantity"
                                    name="drugQuantity"
                                    className="form-control"
                                    value={formData.drugQuantity}
                                    onChange={onEdit}
                                    min={0}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="exercise">Dose </label>
                                <select
                                    name="drugDose"
                                    id="drugDose"
                                    className="custom-select"
                                    value={formData.drugDose}
                                    onChange={onEdit}>
                                    <option value="">Select Option</option>
                                    <option value="1/2">1/2</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="doseFrequency">Frequency </label>
                                <select
                                    name="doseFrequency"
                                    id="doseFrequency"
                                    className="custom-select"
                                    value={formData.doseFrequency}
                                    onChange={onEdit}>
                                    <option value="">Select Option</option>
                                    <option value="Every 4 Hours">Every 4 Hours</option>
                                    <option value="Every 6 Hours">Every 6 Hours</option>
                                    <option value="Every 8 Hours">Every 8 Hours</option>
                                    <option value="Every 12 Hours">Every 12 Hours</option>
                                    <option value="Once">Once</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Prescription Description</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="prescription"
                                    className="form-control"
                                    id="prescription"
                                    value={formData.prescription}
                                    onChange={onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {/*<button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close*/}
                        {/*</button>*/}
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={prescribe}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Prescribe"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PrescriptionEditForm);