import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
    AdmissionDetailsReducer,
    DashboardDataReducer, GeneralDetailsReducer,
    LoginDetailsReducer, PermissionDetailsReducer, RoomDetailsReducer,

} from "./reducers";
import { shortCode } from "../components/url";

const rootReducer = combineReducers({
    LoginDetails: LoginDetailsReducer,
    DashboardData: DashboardDataReducer,
    PermissionDetails: PermissionDetailsReducer,
    GeneralDetails: GeneralDetailsReducer,
    AdmissionDetails: AdmissionDetailsReducer,
    RoomDetails: RoomDetailsReducer,
});

const persistConfig = {
    key: shortCode,
    storage,
};

export default persistReducer(persistConfig, rootReducer);