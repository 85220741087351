import React, {useEffect, useState} from "react";
import {currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import DataTable from "../../common/data-table/data-table";
import {projectAddress, projectEmail, projectName, projectPatientURL} from "../../resources/constants";
function LabTestRequest(props) {
    const {serviceID, serviceName} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [labTestItems, setLabTestItems] = useState([])
    const [requestID, setRequestID] = useState(formatDateAndTime(Date.now(), 'day') + generate_token(8))
    const header = ["S/N", "Test Name", "Specimen", "Amount", "Request"];

    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        patientEmail: props.patientEmail,
        requestType: serviceName,
        requestID: serviceID,
        requestSerial: requestID,
        testAmount: 0,
        testID: "",
        labSpecimenID: "",
        hospitalName: projectName,
        hospitalAddress: projectAddress,
        hospitalPatientURL: projectPatientURL,
        hospitalEmail: projectEmail,
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        doctorID: `${props.loginData[0]?.userID}`,
        doctorEmail: `${props.loginData[0]?.emailAddress}`,
        doctorName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)
    const resetFormData = () => {
        setFormData(Initial)
    }

    useEffect( () => {
        getData();
    }, []);


    const getData = async () => {
        await axios.get(`${serverLink}laboratory/test/list/all`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setLabTestItems(result.data.data);
                }else {
                    setLabTestItems([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const  showTable = () => {
        try {
            return labTestItems.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.testName}</td>
                        <td>{item.specimenName}</td>
                        <td>{currencyConverter(item.testAmount)}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "}
                               onClick={() => {
                                   showConfirm("Warning", `Are you sure you want to request this lab test?`, "warning")
                                       .then( async (confirm) => {
                                           if (confirm) {
                                             await  requestLabTest(item)
                                           }
                                       })
                               }}>
                                <i className="fa fa-plus"></i>
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const requestLabTest = async (item) => {
        setIsFormLoading(true)
        const requestData = {
            ...formData,
            testAmount: item.testAmount,
            testID: item.labTestID,
            labSpecimenID: item.testSpecimenID,
        }
        toast.info("please wait...");
        await axios.post(`${serverLink}laboratory/test/request`, requestData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Lab Test Requested Successfully");
                setIsFormLoading(false);
                props.setRebuildLab(generate_token(5));
                document.getElementById("closeLab").click();
                getData();
                resetFormData();
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding request. Please try again!");
            }
        }) .catch((error) => {
            console.log(error)
            setIsFormLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }




    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 table-responsive">
                                <DataTable tableID="lab-request" header={header} body={showTable()} title="Test Items"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(LabTestRequest);