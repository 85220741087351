import React, { useState } from 'react';
import './accordion.css'; // Import CSS file for styling (see styling below)

function Accordion({ header, content, status, index = 1 }) {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);

    };

    return (
        <div className="accordion mb-2" key={index}>
                <div className="accordion-item">
                    <div className={`accordion-header ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
                        <h4>{header} {status ?? ''} <span className={`arrow ${activeIndex === index ? 'open' : ''}`}>&#9660;</span></h4>

                    </div>
                    {activeIndex === index && (
                        <div className="accordion-content">
                            {content}
                        </div>
                    )}
                </div>
        </div>
    );
}

export default Accordion;
