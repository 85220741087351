import { formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import Accordion from  '../../../components/common/accordion/accordion';

const PatientDoctorNote = ({appointmentID, handleRemount, serviceName, loginData, setDoctorFormData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [noteList, setNoteList] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        let url = serviceName === "Consultation" ? `${serverLink}patient/consultation/doctor-note/${appointmentID}` : `${serverLink}patient/consultation/doctor-note/admission/${appointmentID}`
        await axios.get(url, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setNoteList(result.data);
                }else {
                    setNoteList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h3 className="card-title">Doctor's Note</h3>
                    {
                        noteList.length > 0 ?  noteList.map((item, index) => {
                            return (
                                <Accordion
                                    index={index}
                                    header={`${formatDateAndTime(item.submittedOn, 'date_and_time')} => Doctor: ${item.doctorName}`}
                                    content={
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Subjective</th>
                                                    <th>Objective</th>
                                                    <th>Assessment</th>
                                                    <th>Other 1</th>
                                                    <th>Other 2</th>
                                                    <th>Other 3</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{index +1}</td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note1}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note2}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note3}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note4}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note5}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note6}}></td>
                                                    <td>
                                                        {loginData[0]?.userID !== item.doctorID ? <>--</> :
                                                            <a href="#" className={"btn btn-primary btn-sm "}
                                                               data-toggle="modal" data-target="#doctor-note-modal"
                                                               onClick={() => {
                                                                   setDoctorFormData({
                                                                       appointmentNoteID: item.appointmentNoteID,
                                                                       appointmentID: item.appointmentID,
                                                                       admissionID: item.admissionID,
                                                                       patientSerial: item.patientSerial,
                                                                       doctorID: item.doctorID,
                                                                       note1: item.note1,
                                                                       note2: item.note2,
                                                                       note3: item.note3,
                                                                       note4: item.note4,
                                                                       note5: item.note5,
                                                                       note6: item.note6
                                                                   })
                                                               }}>
                                                                Edit
                                                            </a>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                />
                            )
                        }) : <div className="alert alert-info text-center p-3"><h3>No Record Found!</h3></div>
                    }

                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientDoctorNote);

