import React from "react";
import {Link} from "react-router-dom";
import {FaNairaSign} from "react-icons/fa6";

export default function MenuIcon(props) {
    return (
        <div className={props.size}>
            <Link to={props.url}>
                <div className="card text-white" style={{backgroundColor: props.color}}>
                    <div className="card-body">
                        <div className="d-flex no-block align-items-center">
                            {props.icon}
                            <div className="m-l-15 m-t-10">
                                <h4 className="font-medium m-b-0"><b>{props.title}</b></h4>
                                <small className="link" style={{color: '#cccccc'}}><i>{props.url}</i></small>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
)
}