import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import ImageUpload from "../../common/image-upload/image-upload";
function ImagingResultUpload(props) {
    const { patientSerial, patientName, walletAmount, setRebuildDashboard, imagingRequestID, refreshDashboard} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [ImagePath, setImagePath] = useState('')
    const [ImageDisplay, setImageDisplay] = useState('')


    const InitialValue = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        result: "",
        ImagePath: "",
        ImageDisplay: "",
        imagingRequestID: imagingRequestID,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(InitialValue)
    const resetFormData = () => {
        setFormData(InitialValue)
    }
    const upload = async () => {
        if (ImagePath === ''){
            toast.error("Please select result file")
            return false
        }
        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.patch(`${serverLink}imaging/result/upload`, formData, token).then(async (result) => {
            if (result.data.message === "success") {
                if (ImagePath !== "") {
                    const formData2 = new FormData();
                    formData2.append('photo', ImagePath);
                    formData2.append('entry_id', imagingRequestID)
                    axios.patch(`${serverLink}imaging/upload-result`, formData2)
                        .then(async (res) => {
                            toast.success("Result Uploaded Successfully");
                            setIsFormLoading(false);
                            setRebuildDashboard(generate_token(5));
                            document.getElementById("closeResult").click();
                            setImagePath('')
                            setImageDisplay('')
                            refreshDashboard()
                            resetFormData()
                        })
                        .catch(err => {
                            setIsFormLoading(false);
                            showAlert(
                                "ERROR",
                                "Something went wrong uploading the image. Please try again!",
                                "error"
                            );
                        });
                }
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding result. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        }).catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>

                            <div className="col-md-12 mb-3">
                                <ImageUpload
                                    maxSize={1024}
                                    setImagePath={setImagePath}
                                    setImageDisplay={setImageDisplay}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={upload}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Upload Result"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(ImagingResultUpload);