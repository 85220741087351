import React from "react";
import {PropagateLoader} from "react-spinners";
const PageLoader = () => {
    return (
        <div className="preloader d-flex justify-content-center align-items-center" >
            <PropagateLoader color={'#74DE87FF'} />
        </div>

        // <div className="preloader">
        //     <div className="lds-ripple">
        //         <div className="lds-pos"></div>
        //         <div className="lds-pos"></div>
        //     </div>
        // </div>
    )
}

export default PageLoader;