import React, {useEffect, useState} from "react";
import {currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Select from "react-select";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import MakePayment from "../../finance/choose-payment/choose-payment";
import DataTable from "../../common/data-table/data-table";
import PrescriptionRequestForm from "./prescription-request-form";
function PrescriptionRequest(props) {
    const {serviceID, serviceName} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsGettingSchedules, setIsGettingSchedules] = useState(true)
    const [prescriptionType, setPrescriptionType] = useState([])
    const [serviceType, setServiceType] = useState([])
    const [prescriptionItems, setPrescriptionItems] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [clickedItems, setClickedItems] = useState({})
    const [requestID, setRequestID] = useState(formatDateAndTime(Date.now(), 'day') + generate_token(8))
    const header = ["S/N", "Item", "Manufacture", "Stock Qty", "Price", "Add"];


    const appointmentInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        drugID: "",
        drugID2: "",
        drugCategoryID: "",
        drugCategoryID2: "",
        itemName: "",
        walletAmount: props.walletAmount,
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(appointmentInitial)
    const resetFormData = () => {
        setFormData(appointmentInitial)
    }

    useEffect( () => {
        getData();
    }, []);

    useEffect( () => {
        setPrescriptionItems(prescriptionType.filter(e=>e.category_id === formData.drugCategoryID))
        setIsGettingSchedules(false)
    }, [formData.drugCategoryID]);

    const getData = async () => {
        await axios.get(`${serverLink}pharmacy/prescription/data`)
            .then((result) => {
                let drug_types = []; let drug_list = [];
                result.data.drugTypes.map((row) => {
                    drug_types.push({value: row.drugCategoryID, label: row.categoryName})
                });

                result.data.drugs.map((row) => {
                    drug_list.push({value: row.drugID, label: row.drugName, category_id: row.drugCategoryID, amount: row.drugPrice, manufacturer: row.manufacturer, quantity: row.drugQuantity})
                });

                setServiceType(drug_types);
                setPrescriptionType(drug_list);

                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const  showTable = () => {
        try {
            return prescriptionItems.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.label}</td>
                        <td>{item.manufacturer}</td>
                        <td>{item.quantity}</td>
                        <td>{currencyConverter(item.amount)}</td>
                        <td>
                            {
                                parseInt(item.quantity) < 1 ? <b className="text-danger text-center">Out of Stock</b> :
                                    <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#PrescriptionRequestFormModal"
                                       onClick={() => {
                                           setClickedItems({
                                               itemID: item.value,
                                               itemName: item.label,
                                               itemPrice: parseFloat(item.amount),
                                               itemQty: parseInt(item.quantity),
                                           })
                                       }}>
                                        <i className="fa fa-plus"></i>
                                    </a>
                            }
                           </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onChangeServiceType = (e) => {
        setIsGettingSchedules(true)
        const val = e.value;
        setFormData({
            ...formData,
            drugCategoryID: val,
            drugCategoryID2: e,
            drugID: '',
            drugID2: '',
        })
    }

    const getItemID = (service_name, service_id) => {
        if (service_name === 'Consultation'){
            return {appointmentID: service_id, admissionID: null}
        }else if (service_name === 'Admission'){
            return {admissionID: service_id, appointmentID: null}
        }else{
            return {}
        }
    }


    const requestService = async () => {
        if (selectedServices.length < 1){
            toast.error('You have to select at least one item before submitting')
            return false
        }

        setIsFormLoading(true)
        const requestData = {
            ...formData,
            cartItems: selectedServices,
            ...getItemID(serviceName, serviceID),
            prescriptionAmount: selectedServices.reduce((acc, service) => acc + service.drugTotalAmount, 0)
        }
        toast.info("please wait...");
        await axios.post(`${serverLink}pharmacy/prescription/add`, requestData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Prescription Requested Successfully");
                setIsFormLoading(false);
                props.setRebuildPrescription(generate_token(5));
                document.getElementById("closePrescription").click();
                setSelectedServices([])
                getData();
                resetFormData();
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding prescription. Please try again!");
            }
        }) .catch((error) => {
            console.log(error)
            setIsFormLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const onDelete = (e, item) => {
        let filter = selectedServices.filter(e => e.drugID !== item.drugID);
        setSelectedServices(filter)
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Prescription Type</label>
                                <Select
                                    id="drugCategoryID"
                                    name="drugCategoryID"
                                    value={formData.drugCategoryID2}
                                    onChange={onChangeServiceType}
                                    options={serviceType}
                                    placeholder="Select Prescription Type"
                                />
                            </div>

                            {
                                IsGettingSchedules ? <></> :
                                    <div className="mb-3 table-responsive">
                                        <DataTable tableID="serviceRequest" header={header} body={showTable()} title="prescriptionItems"/>
                                    </div>
                            }
                            <div className="col-md-12 mb-3 mt-5" style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}>
                                <h3 style={{color: '#5687b7', fontWeight: 'bold'}}>SELECTED ITEMS</h3>
                            </div>
                            {
                                selectedServices.length > 0 ?
                                    <div className="mb-3 table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Item</th>
                                                <th>Dosage</th>
                                                <th>Frequency</th>
                                                <th>Price</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                                <th>Active</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                selectedServices.map((item, index)=>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{index +1}</td>
                                                            <td>{item.drugName}</td>
                                                            <td>{item.drugDose}</td>
                                                            <td>{item.doseFrequency}</td>
                                                            <td>{currencyConverter(item.drugAmount)}</td>
                                                            <td>{item.drugQuantity}</td>
                                                            <td>{currencyConverter(item.drugTotalAmount)}</td>
                                                            <td><button type="button" className='btn btn-danger btn-sm float-end'
                                                                        onClick={(e) => onDelete(e, item)}><span className="fa fa-trash-alt"></span></button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                <tr>
                                                    <td><h3><b>Total</b></h3></td>
                                                    <td>--</td>
                                                    <td>--</td>
                                                    <td>--</td>
                                                    <td>--</td>
                                                    <td>--</td>
                                                    <td><h4><b>{currencyConverter(selectedServices.reduce((acc, service) => acc + service.drugTotalAmount, 0))}</b></h4></td>
                                                    <td>--</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="alert alert-info text-center col-md-12"><h3>No Item Selected</h3></div>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={requestService}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <PrescriptionRequestForm
            id="PrescriptionRequestFormModal"
            title="Prescription Form"
            close="closePrescriptionRequestFormModal"
            clickedItems={clickedItems}
            setSelectedServices={setSelectedServices}
            selectedServices={selectedServices}
        />
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PrescriptionRequest);