import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatDateAndTime, serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import SliderForm from "./slider-form";

function WebsiteSlider(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [sliderList, setSliderList] = useState([])
    const header = ["S/N", "Title", "Caption", "Images",  "Mobile Images", "BtnName", "ImagePosition", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        EntryID: "",
        Title: "",
        ImagePath: "",
        mobileImage: "",
        ImageData: "",
        ImageData2: "",
        Caption: "",
        BtnName: "",
        BtnLink: "",
        ImagePosition: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}slider/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setSliderList(result.data);
                }else {
                    setSliderList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}slider/delete/${id}`, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return sliderList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.title}</td>
                        <td>{item.caption}</td>
                        <td> <img src={`${serverLink}public/uploads/sliders_uploads/${item.image}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td> <img src={`${serverLink}public/uploads/sliders_uploads/${item.mobileImage}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td>{item.btnName}</td>
                        <td>{item.imagePosition}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.sliderID,
                                       Title: item.title,
                                       Caption: item.caption,
                                       BtnName: item.btnName,
                                       BtnLink: item.btnLink,
                                       ImagePosition: item.imagePosition,
                                       ImagePath: item.image,
                                       MobileImage: item.mobileImage,
                                       InsertedBy: item.submittedBy,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td>
                            <a href="#" className={"btn btn-danger btn-sm "}
                               onClick={()=>showConfirm("Warning", `Are you sure you want to delete this slider?`, "warning")
                                   .then( async (confirm) => {
                                       if (confirm) {
                                           delete_item(item.sliderID)
                                       }
                                   })}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }


    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
        }
    };
    const onMobileImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                mobileImage: event.target.files[0],
                ImageData2: event.target.files[0],
            });
        }
    };

    const onSubmit = async () => {
        if (formData.Title.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter slider title", "error");
            return false;
        }

        if (formData.ImagePosition.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select slider image position", "error");
            return false;
        }

        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}slider/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImageData !== "" || formData.ImageData2 !== "") {
                            const formData2 = new FormData();
                            formData2.append('mobile', formData.ImageData2 !== "")
                            formData2.append('photo', formData.ImageData2);
                            formData2.append('web', formData.ImageData !== "")
                            formData2.append('photo', formData.ImageData);
                            formData2.append('entry_id', result.data.entry_id)

                            axios.patch(`${serverLink}slider/updateSlidersPhoto`, formData2)
                                .then(result => {
                                    toast.success("Slider Added Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("close").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        Title: "",
                                        ImagePath: "",
                                        mobileImage: "",
                                        ImageData: "",
                                        ImageData2: "",
                                        Caption: "",
                                        BtnName: "",
                                        BtnLink: "",
                                        ImagePosition: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });

                        }else{
                            toast.success("Slider Added Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("close").click();
                            setFormData({
                                ...formData,
                                EntryID: "",
                                Title: "",
                                ImagePath: "",
                                mobileImage: "",
                                ImageData: "",
                                ImageData2: "",
                                Caption: "",
                                BtnName: "",
                                BtnLink: "",
                                ImagePosition: "",
                            });
                        }

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}slider/update`, sendData, token)
                .then(async (result) => {
                    if (result.data.message === "success") {
                        if (formData.ImageData !== "" || formData.ImageData2 !== "") {
                            const formData2 = new FormData();
                            formData2.append('mobile', formData.ImageData2 !== "")
                            formData2.append('photo', formData.ImageData2);
                            formData2.append('web', formData.ImageData !== "")
                            formData2.append('photo', formData.ImageData);
                            formData2.append('entry_id', formData.EntryID)

                            axios.patch(`${serverLink}slider/updateSlidersPhoto`, formData2)
                                .then(result => {
                                    toast.success("Slider Updated Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("close").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        Title: "",
                                        ImagePath: "",
                                        mobileImage: "",
                                        ImageData: "",
                                        ImageData2: "",
                                        Caption: "",
                                        BtnName: "",
                                        BtnLink: "",
                                        ImagePosition: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("Slider Updated Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("close").click();
                            setFormData({
                                ...formData,
                                EntryID: "",
                                Title: "",
                                ImagePath: "",
                                ImageData: "",
                                Caption: "",
                                BtnName: "",
                                BtnLink: "",
                                ImagePosition: "",
                            });
                        }

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Slider"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage Slider</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        EntryID: "",
                                                        Title: "",
                                                        ImagePath: "",
                                                        mobileImage: "",
                                                        ImageData: "",
                                                        ImageData2: "",
                                                        Description: "",
                                                        ViewCount: "",
                                                        Status: "",
                                                    });
                                                }}>
                                                    <i className="fa fa-plus"></i> Add Slider
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="Slider" header={header} body={showTable()} title="Website Slider Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SliderForm
                    title="Manage Slider"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    onImageChange={onImageChange}
                    onMobileImageChange={onMobileImageChange}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(WebsiteSlider);

