import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {setGeneralDetails} from "../../../action/actions";
import {FaBedPulse, FaNairaSign} from "react-icons/fa6";
import {Link} from "react-router-dom";

function AdmitPatients(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);
    const [formData, setFormData] = useState({
        patientID: "",
        title: "",
        firstName: "",
        middleName: "",
        surname: "",
        passport: "",
        ImagePath: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "",
        dateOfBirth: "",
        bloodGroup: "",
        maritalStatus: "",
        nextOfKinName: "",
        nextOfKinPhone: "",
        nextOfKinRelationship: "",
        residentialAddress: "",
        nationality: "",
        state: "",
        lga: "",
        patientSerial: "",
        occupation: "",
        altPhoneNumber: "",
        password: "",
        ward: "",
        zipCode: "",
        officeAddress: "",
        religion: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    });

    const header = [
        "S/N",
        "Patient ID",
        "Name",
        "Gender",
        "Phone Number",
        "Email Address",
        "State",
        "LGA",
        "Admit",
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, [""]);

    const getData = async () => {
        await axios
            .get(`${serverLink}patient/list/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.firstName} {item.middleName} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>{item.state}</td>
                        <td>{item.lga}</td>
                        <td className="d-flex justify-content-center">
                            <Link to={`/admission/patient/${btoa(item.patientSerial)}`}
                               className={"btn btn-primary btn-sm"}
                            >
                                <FaBedPulse size={25} />
                            </Link>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Admit Patient"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Admit Patient</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">
                                    </div>
                                </div>
                                <DataTable
                                    tableID="Patient"
                                    header={header}
                                    body={showTable()}
                                    title="Patient Report"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdmitPatients);
